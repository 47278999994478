.topmovebutton {
  position: fixed;
  top: 80%;
  z-index: 1;
  background: white;
  width: 50px;
  height: 50px;
  right: 5%;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  font-weight: bold;
  box-shadow: 0px 10px 15px rgba(26, 26, 26, 0.2);
  color: rgba(219, 49, 49, 1);
}
.topmovebutton:hover {
  cursor: pointer;
}
.toparrow {
  margin-top: 3px;
}
.container {
  background: transparent linear-gradient(244deg, #1e2d54 0%, #553120 100%) 0%
    0% no-repeat padding-box;
  width: 100%;
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 7;
  padding-top: 2.125rem;
  padding-bottom: 2.125rem;
  background: transparent linear-gradient(300deg, #1e2d54 0%, #553120 160%) 0%
    0% no-repeat padding-box;
}
.title {
  font-family: "Roboto";
  font-weight: 700;
  color: #fcfbfb;
  font-size: 1.5625rem;
  margin-left: 9.22vw;
  display: flex;
  align-items: center;
}

.button {
  font-family: "Roboto";
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  width: 12.25rem;
  height: 2.9375rem;
  background-color: #da392b;
  border-radius: 2.5625rem;
  color: white;
  box-shadow: 0px 3px 6px #da392b78;
  margin-right: 9.22vw;
}

.topImg {
  width: 39.5vw;
  height: 31.7vw;
  position: absolute;
  top: 20.5625rem;
  right: 11.625rem;
  z-index: 0;
}
.toptext {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 10.44vw;
  margin-top: 189.9825px;
  line-height: 4.84vw;
}
.toptext > h1 {
  font-size: 3.07vw;
  color: #ffffff;
  z-index: 1;
}
.second {
  background: #2969f4;
  padding-left: 2rem;
  margin-left: -2rem;
  padding-right: 2rem;
  border-radius: 50%;
}
.third::after {
  content: "creating a game?";
  background: #f4710e;
  padding-left: 0rem;
  margin-left: 1rem;
  padding-right: 0rem;
  padding-bottom: 1rem;
}
.fourth::after {
  content: "support you!";
  border-bottom: 9px solid #de0e0e;
}
.bottomcontainer {
  display: flex;
  margin-left: 10.44vw;
  margin-top: 9.875rem;
  padding-bottom: 7.25rem;
}
.bottomcontainer > h1 {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 4vw;
  font-family: "Roboto";
  margin-right: 0.78vw;
}
.secondbutton {
  font-family: "Roboto";
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  width: 13.28vw;
  height: 3.38vw;
  background-color: #da392b;
  border-radius: 2.5625rem;
  color: white;
  box-shadow: 0px 3px 6px #da392b78;
  font-size: 1.17vw;
  margin-top: 9.369px;
}
.middlecontainer {
  height: 68.33vw;
  background-image: url("../../image/svgicon/img_zigzag.svg");
  background-repeat: no-repeat;
  background-position: right;
  background-size: 100% 100%;
  background-position-x: 25vw;
  background-color: black;
  display: grid;
  grid-template-columns: repeat(3, 25.83vw);
  place-items: center center;
  padding-bottom: 9.64vw;
  column-gap: 2.03vw;
  padding-left: 9.22vw;
}
.card1,
.card2,
.card3 {
  width: 25.83vw;
  height: 43.54vw;
  background: #f8f8f8;
  margin-top: 10.73vw;
  display: flex;
  flex-direction: column;
  border-radius: 1.5625rem;
  align-items: center;
  place-self: center end;
  padding-bottom: 2rem;
  word-break: keep-all;
}

.card1 > h1 {
  font-family: "Roboto";
  font-weight: 700;
  margin-top: 2.6vw;
  margin-bottom: 3.49vw;
  font-size: 1.72vw;
  width: 20vw;
  line-height: 2.34vw;
}
.card2 > h1 {
  font-family: "Roboto";
  font-weight: 700;
  font-size: 1.72vw;
  width: 20vw;
  margin-top: 3.8vw;
  margin-bottom: 4.64vw;
}
.card3 > h1 {
  font-family: "Roboto";
  font-weight: 700;
  margin-top: 2.6vw;
  margin-bottom: 3.49vw;
  font-size: 1.72vw;
  width: 22vw;
  line-height: 2.34vw;
}
.card1 > p {
  color: #5a5a5a;
  font-size: 1.2vw;
  font-family: "Roboto";
  width: 20vw;
  line-height: 1.88vw;
}
.card2 > p {
  color: #5a5a5a;
  font-size: 1.2vw;
  font-family: "Roboto";
  width: 20vw;
  line-height: 1.88vw;
}
.card3 > p {
  color: #5a5a5a;
  font-size: 1.2vw;
  font-family: "Roboto";
  width: 20vw;
  line-height: 1.88vw;
}

.img {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: #3bb2ab;
  color: white;
  font-family: "Roboto";
  font-weight: 700;
  width: 100%;
  border-top-left-radius: 1.5625rem;
  border-top-right-radius: 1.5625rem;
}
.footercontainer {
  background-image: url("../../image/banner2.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 458.04px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.footercontainer > h1 {
  font-size: 2.92vw;
  font-family: "Roboto";
  font-weight: 700;
  margin-bottom: 3.375rem;
  color: white;
  word-break: keep-all;
}
.footerbutton {
  font-family: "Roboto";
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  width: 18.99vw;
  height: 4.84vw;
  background-color: #f7931e;
  border-radius: 3.125rem;
  color: white;
  font-size: 1.73vw;
}

@media all and (min-width: 1921px) {
  .title {
    margin-left: 9.22vw;
  }
  .button {
    margin-right: 9.22vw;
  }
}
/*17in*/
@media all and (max-width: 1440px) {
  .container {
    width: 100%;
    background: transparent linear-gradient(241deg, #1e2d54 0%, #553120 100%) 0%
      0% no-repeat padding-box;
  }

  .title {
    margin-left: 12.2917vw;
  }
  .button {
    margin-right: 12.2917vw;
  }
  .topImg {
    width: 41vw;
    height: 32.8vw;

    top: 17.0625rem;
    right: 9.0625rem;
  }
  .toptext {
    line-height: 5.42vw;
    margin-left: 12.29vw;
  }
  .toptext > h1 {
    font-size: 3.68vw;
    color: #ffffff;
  }
  .bottomcontainer {
    margin-left: 12.29vw;
  }
  .bottomcontainer > h1 {
    font-size: 3.6vw;
    margin-top: 5px;
  }
  .secondbutton {
    width: 18.89vw;
    height: 4.51vw;
    background-color: #da392b;
    border-radius: 3.47vw;
    font-size: 1.67vw;
    margin-top: 9.369px;
  }
  .middlecontainer {
    height: 58.89vw;
    min-height: 840px;
    width: 100%;
    background-size: 100% 100%;
    background-position-x: 40vw;
    background-color: black;
    display: grid;
    grid-template-columns: repeat(3, 24.31vw);
    grid-template-rows: repeat(1, 848px);
    column-gap: 2.36vw;
    place-items: center center;
    place-content: center;
    padding-bottom: 0vw;
    padding-left: 0vw;
    padding-right: 0vw;
  }

  .card1,
  .card2,
  .card3 {
    width: 23.68vw;
    height: 39.93vw;
    margin-top: 0;
  }
  .card1 > h1 {
    font-family: "Roboto";
    font-weight: 700;
    margin-top: 2.33vw;
    margin-bottom: 2.15vw;
    font-size: 1.53vw;
    width: 20vw;
    line-height: 2.34vw;
  }
  .card2 > h1 {
    font-family: "Roboto";
    font-weight: 700;
    margin-top: 3.47vw;
    margin-bottom: 3.5vw;
    font-size: 1.53vw;
    width: 20vw;
    line-height: 2.34vw;
  }
  .card3 > h1 {
    font-family: "Roboto";
    font-weight: 700;
    margin-top: 2.33vw;
    margin-bottom: 2.15vw;
    font-size: 1.53vw;
    width: 20vw;
    line-height: 2.34vw;
  }
  .card1 > p {
    color: #5a5a5a;
    font-size: 1.32vw;
    font-family: "Roboto";
    width: 20vw;
    line-height: 2.01vw;
  }
  .card2 > p {
    color: #5a5a5a;
    font-size: 1.32vw;
    font-family: "Roboto";
    width: 20vw;
    line-height: 2.01vw;
  }
  .card3 > p {
    color: #5a5a5a;
    font-size: 1.32vw;
    font-family: "Roboto";
    width: 20vw;
    line-height: 2.01vw;
  }
  .footercontainer {
    height: 360px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
/*15in*/
@media all and (max-width: 1280px) {
  .container {
    background: transparent linear-gradient(235deg, #1e2d54 0%, #553120 100%) 0%
      0% no-repeat padding-box;
  }
  .header {
    background: transparent linear-gradient(330deg, #1e2d54 0%, #553120 170%) 0%
      0% no-repeat padding-box;
  }
  .title {
    margin-left: 13.83vw;
  }

  .button {
    margin-right: 13.83vw;
  }
  .topImg {
    width: 39.9vw;
    height: 32vw;

    top: 15.6875rem;
    right: 9.8125rem;
  }
  .toptext {
    line-height: 5.42vw;
    margin-left: 16.72vw;
    margin-top: 14.84vw;
  }
  .toptext > h1 {
    font-size: 4.14vw;
    color: #ffffff;
    line-height: 6.09vw;
  }
  .third::after {
    content: "creating a game?";
    background: #f4710e;
    padding-left: 0rem;
    padding-right: 0rem;
    padding-bottom: 1rem;
    margin-left: 1rem;
  }
  .bottomcontainer {
    display: flex;
    margin-left: 16.72vw;
    margin-top: 9.69vw;
    padding-bottom: 7.25rem;
  }
  .bottomcontainer > h1 {
    font-size: 5vw;
    font-family: "Roboto";
    margin-right: 1.56vw;
  }
  .secondbutton {
    width: 21.25vw;
    height: 5.08vw;
    border-radius: 3.91vw;
    font-size: 1.88vw;
    margin-top: 9.369px;
  }
  .middlecontainer {
    height: 58.89vw;
    min-height: 749px;
    grid-template-columns: repeat(3, 22.66vw);
    grid-template-rows: repeat(1, 749px);
    column-gap: 2.34vw;
    padding-bottom: 0vw;
  }
  .card1,
  .card2,
  .card3 {
    width: 22.66vw;
    height: 38.2vw;
  }
  .img {
    height: 16.72vw;
  }
  .card1 > h1 {
    margin-top: 2.34vw;
    margin-bottom: 1.95vw;
    font-size: 1.41vw;
    width: 10.5vw;
    line-height: 1.72vw;
  }
  .card2 > h1 {
    margin-top: 2.34vw;
    margin-bottom: 3.6vw;
    font-size: 1.41vw;
    width: 20vw;
    line-height: 1.72vw;
  }
  .card3 > h1 {
    margin-top: 2.34vw;
    margin-bottom: 1.95vw;
    font-size: 1.41vw;
    width: 20vw;
    line-height: 1.72vw;
  }
  .card1 > p {
    font-size: 1.32vw;

    width: 20.08vw;
    line-height: 2.11vw;
  }
  .card2 > p {
    font-size: 1.32vw;

    width: 20.08vw;
    line-height: 2.11vw;
  }
  .card3 > p {
    font-size: 1.32vw;

    width: 20.08vw;
    line-height: 2.11vw;
  }
  .footercontainer > h1 {
    font-size: 2.73vw;
    margin-bottom: 4.45vw;
    color: white;
  }
  .footerbutton {
    width: 23.13vw;
    height: 5.55vw;
    border-radius: 3.67vw;
    color: white;
    font-size: 2.03vw;
  }
}
/*13in*/
@media all and (max-width: 1024px) {
  .container {
    background: transparent linear-gradient(236deg, #1e2d54 0%, #553120 100%) 0%
      0% no-repeat padding-box;
  }
  .header {
    background: transparent linear-gradient(330deg, #1e2d54 0%, #553120 230%) 0%
      0% no-repeat padding-box;
  }
  .title {
    margin-left: 11.72vw;
    font-size: 1.5625rem;
  }

  .button {
    width: 12rem;
    height: 2.9375rem;
    border-radius: 2.5625rem;
    font-size: 1.125rem;
    margin-right: 11.72vw;
  }
  .topImg {
    width: 39.1vw;
    height: 31.3vw;

    top: 15.4375rem;
    right: 6.5rem;
  }
  .toptext {
    line-height: 4.3vw;
    margin-left: 14.55vw;
    margin-top: 12.7vw;
  }
  .toptext > h1 {
    font-size: 4.14vw;
    color: #ffffff;
    line-height: 6.35vw;
  }
  .third::after {
    content: "creating a game?";
    background: #f4710e;
    padding-left: 0rem;
    padding-right: 0rem;
    padding-bottom: 1rem;
    margin-left: 1rem;
  }
  .bottomcontainer {
    display: flex;
    margin-left: 14.55vw;
    margin-top: 7.32vw;
    padding-bottom: 3.4375rem;
  }
  .bottomcontainer > h1 {
    font-size: 5vw;
    font-family: "Roboto";
    margin-right: 1.56vw;
  }
  .secondbutton {
    width: 22.56vw;
    height: 5.37vw;
    border-radius: 4.88vw;
    font-size: 1.95vw;
    margin-top: 9.369px;
  }
  .middlecontainer {
    height: 209.96vw;
    min-height: 749px;
    grid-template-columns: repeat(1, 63.87vw);
    grid-template-rows: repeat(3, 64.84vw);
    background-image: url("../../image/1024hackground.png");
    background-repeat: no-repeat;
    background-position: right;
    background-size: 80% 100%;
    background-position-x: 25vw;
    background-color: black;
    row-gap: 4.375rem;
    padding-top: 11.23vw;
    padding-bottom: 11.23vw;
  }
  .card1,
  .card2,
  .card3 {
    width: 100%;
    height: 64.84vw;
    padding-bottom: 0;
  }
  .img {
    width: 63.87vw;
    height: 43.26vw;
  }
  .card1 > h1 {
    margin-top: 3.61vw;
    margin-bottom: 1.66vw;
    font-size: 2.34vw;
    width: 50.68vw;
    line-height: 2.15vw;
  }
  .card2 > h1 {
    margin-top: 3.61vw;
    margin-bottom: 1.66vw;
    font-size: 2.34vw;
    width: 50.68vw;
    line-height: 2.15vw;
  }
  .card3 > h1 {
    margin-top: 3.61vw;
    margin-bottom: 1.66vw;
    font-size: 2.34vw;
    width: 50.68vw;
    line-height: 2.15vw;
  }
  .card1 > p {
    font-size: 1.95vw;

    width: 50.78vw;
    line-height: 2.83vw;
  }
  .card2 > p {
    font-size: 1.95vw;

    width: 50.78vw;
    line-height: 2.83vw;
  }
  .card3 > p {
    font-size: 1.95vw;

    width: 50.78vw;
    line-height: 2.83vw;
  }
  .footercontainer {
    height: 18rem;
  }
  .footercontainer > h1 {
    font-size: 2.64vw;
    margin-bottom: 4.45vw;
    color: white;
  }
  .footerbutton {
    width: 23.14vw;
    height: 5.57vw;
    border-radius: 4.59vw;
    color: white;
    font-size: 1.95vw;
  }
}
/*tablet*/
@media all and (max-width: 768px) {
  .topmovebutton {
    display: none;
  }
  .header {
    position: static;
    background: none;
    padding-top: 1.8125rem;
  }

  .title {
    margin-left: 13.5vw;
  }
  .button {
    margin-right: 13.5vw;
    visibility: hidden;
  }
  .topImg {
    width: 51.6vw;
    height: 41.3vw;

    top: 10.625rem;
    right: 10rem;
    left: 27.6vw;
  }
  .toptext {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-left: 0vw;
    margin-top: 36vw;

    height: 30vw;
  }
  .toptext > h1 {
    line-height: 7.9vw;
    font-size: 5.3vw;
  }
  .bottomcontainer {
    margin-left: 0vw;
    margin-top: 7vw;
    padding-bottom: 10vw;
    position: relative;
    top: 0vw;
    left: 0vw;
    display: flex;
    justify-content: center;
  }
  .bottomcontainer > h1 {
    display: none;
  }
  .secondbutton {
    width: 41vw;
    height: 9.8vw;
    font-size: 3.5vw;
  }

  .middlecontainer {
    height: 240vw;
    padding-bottom: 13.7vw;
    padding-top: 12.1vw;
    background-size: 115% 100%;
    background-position-x: 40vw;
    grid-template-columns: repeat(1, 72.9vw);
    grid-template-rows: repeat(3, 74.1vw);
    place-items: center;
    row-gap: 8.9vw;
  }

  .card1,
  .card2,
  .card3 {
    width: 100%;
    height: 100%;
    place-self: center center;
  }
  .card1 > h1 {
    margin-top: 4.2vw;
    margin-bottom: 2vw;
    font-size: 3.1vw;
  }
  .card1 > p,
  .card2 > p,
  .card3 > p {
    font-size: 2.6vw;
    width: 58.1vw;
    line-height: 3.8vw;
  }
  .card2 > h1 {
    margin-top: 4.2vw;
    margin-bottom: 2vw;
    font-size: 3.1vw;
  }
  .card3 > h1 {
    margin-top: 4.2vw;
    margin-bottom: 2vw;
    font-size: 3.1vw;
    line-height: 3.4vw;
    width: 100%;
  }
  .img {
    width: 72.9vw;
    height: 49.5vw;
  }
  .footercontainer {
    height: 46.9vw;
  }
  .footercontainer > h1 {
    font-size: 5.5vw;
    width: 72.9vw;
    margin-bottom: 5vw;
  }
  .footerbutton {
    width: 43vw;
    height: 10.3vw;
    border-radius: 6.1vw;
    font-size: 3.5vw;
  }
}
/*mobile*/
@media all and (max-width: 480px) {
  .topmovebutton {
    display: none;
  }
  .container {
    width: 100vw;
  }
  .header {
    padding-top: 6vw;
  }
  .title {
    padding-top: 0;
    margin-left: 8.3vw;
    font-size: 1.5625rem;
  }
  .button {
    margin-right: 13.5vw;
  }

  .topImg {
    width: 71vw;
    height: 56.7vw;
    top: 11.25rem;
    right: 0rem;
    left: 18.8vw;
  }

  .toptext {
    display: flex;

    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-left: 0vw;
    margin-top: 0px;
    margin-top: 52vw;
    height: 40vw;
  }
  .toptext > h1 {
    line-height: 10.2vw;
    font-size: 6.7vw;
  }
  .third::after {
    padding-bottom: 2vw;
  }
  .bottomcontainer {
    margin-left: 0vw;
    margin-top: 0;
    padding-top: 13vw;
    padding-bottom: 13vw;
    position: relative;
    top: 0vw;
    left: 0vw;
    display: flex;
    justify-content: center;
  }
  .bottomcontainer > h1 {
    display: none;
  }
  .secondbutton {
    width: 54vw;
    height: 12.7vw;
    font-size: 4.6vw;
    border-radius: 10.4vw;
  }

  .middlecontainer {
    height: 452.7vw;
    padding-bottom: 13.7vw;
    padding-top: 12.1vw;
    background-image: url("../../image/partnerpage/480px\ parback.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position-x: 23.5vw;
    grid-template-columns: repeat(1, 83.3vw);
    grid-template-rows: repeat(3, 125.2vw);
    place-items: center;
    padding-left: 0vw;
    padding-right: 0;
    row-gap: 12.5vw;
    justify-content: center;
    align-content: center;
  }

  .card1,
  .card2,
  .card3 {
    width: 100%;
    height: 100%;
    place-self: center center;
  }
  .card1 > h1 {
    margin-top: 6.5vw;
    margin-bottom: 2.5vw;
    font-size: 5vw;
    width: 76vw;
  }
  .card2 > h1 {
    margin-top: 6.5vw;
    margin-bottom: 2.5vw;
    font-size: 5vw;
    width: 67.7vw;
  }
  .card3 > h1 {
    margin-top: 6.5vw;
    margin-bottom: 2.5vw;
    font-size: 5vw;
    line-height: 5.4vw;
    width: 100%;
  }
  .card1 > p,
  .card2 > p,
  .card3 > p {
    font-size: 4.2vw;
    width: 75vw;
    line-height: 6vw;
  }

  .img {
    width: 83.3vw;
    height: 79.2vw;
  }
  .footercontainer {
    height: 74.6vw;
  }
  .footercontainer > h1 {
    font-size: 7.3vw;
    width: 83.3vw;
    margin-bottom: 5vw;
    line-height: 10.6vw;
  }
  .footerbutton {
    width: 42.9vw;
    height: 10.4vw;
    border-radius: 9.8vw;
    font-size: 3.3vw;
  }
}
