.container {
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    display: flex;
    width: 100%;
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
  h1 {
    color: #262626;
    text-align: center;
    font-size: 100px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
  }
  p {
    color: #262626;
    text-align: center;
    font-size: 18px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    border-radius: 10px;
    background: #e63d43;
    width: 191px;
    height: 44px;
    font-size: 18px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: #fff;
    margin-top: 40px;
    &:hover {
      cursor: pointer;
      background-color: #c80313;
    }
  }
}
