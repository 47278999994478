@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@500;700&display=swap");
.container {
  height: 485px;
  background: url("../../../../image/footerbackgroud.png") no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.title {
  color: #ffffff;
  font-family: "Roboto";
  font-weight: 700;
  font-size: 3.02vw;
  margin-bottom: 77.6px;
  display: flex;
  justify-content: center;
}

.buttoncontainer {
  display: flex;
  justify-content: center;

  width: 100%;
}
.publishbutton {
  display: flex;
  width: 22.375rem;
  height: 6.875rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 18px #da392b6e;
  border-radius: 40px;
  text-decoration: none;
  text-align: start;
  align-items: center;
  font-size: 1.625rem;
  font-family: "Roboto";
  font-weight: 700;
  color: #141414;
  margin-right: 4.22vw;
}
.partnershipbutton {
  display: flex;
  width: 22.375rem;
  height: 6.875rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 18px #da392b6e;
  border-radius: 40px;
  text-decoration: none;
  text-align: start;
  align-items: center;
  font-size: 1.625rem;
  font-family: "Roboto";
  font-weight: 700;
  color: #141414;
}
.publishbutton > p,
.partnershipbutton > p {
  margin-left: 2.1875rem;
}
@keyframes motion {
  0% {
    margin-top: 0px;
  }
  100% {
    margin-top: 20px;
  }
}
.handShake {
  position: relative;
  width: 11.6875rem;
  height: 7.6875rem;
  left: 1rem;
  top: -1rem;
}
.publishbutton:hover > .handShake {
  animation: motion 0.3s linear 0s 4 alternate;
}

@keyframes motionLeft {
  0% {
    left: -1.5rem;
  }
  100% {
    left: -1rem;
  }
}
@keyframes motionRight {
  0% {
    left: -1rem;
  }
  100% {
    left: -1.5rem;
  }
}
.powImg {
  display: none;
}
.fistBumpLeft {
  position: relative;
  height: 4.8125rem;
  top: -40px;
  left: -1.5rem;
  transform: matrix(0.97, 0.24, -0.24, 0.97, 0, 0);
}
.fistBumpRight {
  position: relative;
  height: 4.8125rem;
  left: -1rem;
  transform: matrix(0.97, 0.35, -0.24, 0.97, 0, 0);
}
.partnershipbutton:hover > .fistBumpLeft {
  animation: motionLeft 0.3s linear 0s 2 alternate;
}
.partnershipbutton:hover > .fistBumpRight {
  animation: motionRight 0.3s linear 0s 2 alternate;
}
/*17in*/
@media screen and (max-width: 1440px) {
  .container {
    height: 384px;
  }
  .title {
    font-size: 2.6875rem;
  }

  .publishbutton {
    width: 21rem;
    height: 6.4375rem;
    font-size: 1.5rem;
    margin-right: 2rem;
  }
  .partnershipbutton {
    width: 21rem;
    height: 6.4375rem;
    font-size: 1.5rem;
  }
  .handShake {
    width: 11rem;
    height: 7.25rem;
    left: 10px;
    top: -25px;
  }

  @keyframes motionLeft {
    0% {
      left: -1.25rem;
    }
    100% {
      left: -0.625rem;
    }
  }
  @keyframes motionRight {
    0% {
      left: -0.625rem;
    }
    100% {
      left: -1.25rem;
    }
  }
  .fistBumpLeft {
    height: 4rem;
    top: -35px;
    left: -1.25rem;
  }
  .fistBumpRight {
    height: 4rem;
    left: -0.625rem;
  }
}
/*15in*/
@media screen and (max-width: 1280px) {
  .container {
    height: 384px;
  }

  .title {
    font-size: 2.6875rem;
  }

  .publishbutton {
    width: 18.625rem;
    height: 6.4375rem;
    font-size: 1.4375rem;
    margin-right: 4.31vw;
    border-radius: 2.5rem;
  }
  .partnershipbutton {
    width: 18.625rem;
    height: 6.4375rem;
    font-size: 1.4375rem;
    border-radius: 2.5rem;
  }
  .handShake {
    width: 9.8125rem;
    height: 6.4375rem;
    left: 10px;
    top: -20px;
  }

  @keyframes motionLeft {
    0% {
      left: -20px;
    }
    100% {
      left: -15px;
    }
  }
  @keyframes motionRight {
    0% {
      left: -15px;
    }
    100% {
      left: -20px;
    }
  }
  .fistBumpLeft {
    width: 6.25rem;
    height: 4rem;
    top: -35px;
    left: -20px;
  }
  .fistBumpRight {
    width: 6.25rem;
    height: 4rem;
    left: -15px;
    top: -5px;
  }
}
/*13in*/

@media all and (max-width: 1024px) {
  .container {
    height: 36vw;
  }

  .title {
    font-size: 4.1vw;
    margin-bottom: 5.18vw;
  }

  .publishbutton {
    width: 24.71vw;
    height: 7.13vw;
    font-size: 1.86vw;
    margin-right: 4.31vw;
    border-radius: 2.54vw;
  }
  .publishbutton > p {
    margin-left: 2.25vw;
  }
  .handShake {
    width: 12.21vw;
    height: 8.11vw;
    left: 5px;
    top: -15px;
  }
  .partnershipbutton {
    width: 24.71vw;
    height: 7.13vw;
    font-size: 1.86vw;
    border-radius: 2.54vw;
  }
  .partnershipbutton > p {
    margin-left: 2.25vw;
  }
  @keyframes motionLeft {
    0% {
      left: -10px;
    }
    100% {
      left: 0px;
    }
  }
  @keyframes motionRight {
    0% {
      left: 0px;
    }
    100% {
      left: -10px;
    }
  }
  .fistBumpLeft {
    width: 7.91vw;

    top: -20px;
    left: -10px;
  }
  .fistBumpRight {
    width: 7.91vw;

    left: 0px;
    top: 5px;
  }
}
@media all and (max-width: 768px) {
  .container {
    height: 44vw;
  }

  .title {
    font-size: 4.17vw;
    margin-bottom: 5.6vw;
    width: 70vw;
    align-self: center;
    flex-direction: row;
  }

  .publishbutton {
    width: 35.42vw;
    height: 9.64vw;
    font-size: 2.6vw;
    margin-right: 2.08vw;
    border-radius: 3.39vw;
  }
  .publishbutton > p {
    margin-left: 4.04vw;
  }
  .handShake {
    width: 16.28vw;
    height: 10.81vw;
    left: 12px;
    top: -10px;
  }
  .partnershipbutton {
    width: 35.42vw;
    height: 9.64vw;
    font-size: 2.6vw;
    border-radius: 3.39vw;
  }
  .partnershipbutton > p {
    margin-left: 4.04vw;
  }
  @keyframes motionLeft {
    0% {
      left: -10px;
    }
    100% {
      left: 0px;
    }
  }
  @keyframes motionRight {
    0% {
      left: 0px;
    }
    100% {
      left: -10px;
    }
  }
  .fistBumpLeft {
    width: 10.42vw;
    height: 9vw;
    top: -25px;
    left: -10px;
  }
  .fistBumpRight {
    width: 10.42vw;
    height: 9vw;
    left: 0px;
    top: 0px;
  }
}
@media all and (max-width: 480px) {
  .container {
    height: 91vw;
    background: #d0bdba;
  }
  .fistBumpLeft,
  .fistBumpRight {
    display: none;
  }
  .title {
    font-size: 7.5vw;
    width: 82.9vw;
  }
  .buttoncontainer {
    flex-direction: column;
    align-items: center;
  }
  .publishbutton,
  .partnershipbutton {
    width: 56.7vw;
    height: 15.4vw;
    font-size: 4.2vw;
    margin-right: 0vw;
    border-radius: 5.4vw;
  }
  .publishbutton {
    margin-bottom: 6.3vw;
  }
  .handShake {
    width: 26vw;
    height: 17.3vw;
    left: 4vw;
    top: -2.7vw;
  }
  .powImg {
    position: relative;
    display: inline;
    width: 35vw;
    transform: matrix(0.97, 0.24, -0.24, 0.97, 0, 0);
    left: -2.1vw;
    top: -2.1vw;
  }
}
