$blue_background: #3cadff;
$white_background: #ffffff;
$orange_background: #ff6725;

$mobile: 480px;
$tablet: 768px;
$desktop: 1440px;
$calc_size: 0vw;
@mixin desktop {
  @media (min-width: $tablet+1) and (max-width: $desktop) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $mobile+1) and (max-width: $tablet) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: $mobile) {
    @content;
  }
}

@mixin font($font_name, $size, $weight, $color) {
  font-family: $font_name, sans-serif;
  font-size: $size;
  font-weight: $weight;
  color: $color;
  @content;
}
@mixin join_now {
  width: 424px;
  height: 90px;
  background: $white_background;
  border: 3px solid #111111;
  box-shadow: 0px 11px 0px #000000;
  border-radius: 24px;
  margin-top: 58px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  @include desktop {
    width: 29.44vw;
    height: 6.25vw;
    background: $white_background;
    border: 0.21vw solid #111111;
    box-shadow: 0px 0.76vw 0px #000000;
    border-radius: 1.67vw;
  }
  @include tablet {
    width: 55.21vw;
    height: 11.72vw;
    background: $white_background;
    border: 0.39vw solid #111111;
    box-shadow: 0px 1.43vw 0px #000000;
    border-radius: 3.13vw;
  }
  @include mobile {
    width: 74.79vw;
    height: 16.25vw;
    background: $white_background;
    border: 0.63vw solid #111111;
    box-shadow: 0px 2.29vw 0px #000000;
    border-radius: 5vw;
  }
}
