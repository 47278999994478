@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");

footer {
  width: 100%;
  height: 25.625rem;
  background: rgba(20, 20, 20, 1);
  display: flex;
}

.container {
  width: 100%;
  display: grid;
  grid-template-columns: 0.5fr 0.5fr 0.4fr 1fr;
  margin-top: 4.875rem;
}
.container li h1 {
  font-family: "Roboto";
  color: rgba(255, 255, 255, 1);
  /* font-weight: 700; */
}
.menu {
  display: flex;
  flex-direction: column;
  justify-self: flex-start;

  padding-left: 9vw;
}
.menu h1 {
  font-size: 1.15vw;
  display: flex;
  margin-bottom: 1.75rem;
}
.menu a {
  text-decoration: none;
  color: rgba(255, 255, 255, 1);
  opacity: 0.65;
  display: flex;
  margin: 0.6875rem 0;
  font-size: 0.89vw;
  font-family: "Roboto";
  font-weight: 500;
}

.menu a:hover {
  color: rgba(218, 57, 43, 1);
  opacity: 1;
}
.submit {
  display: flex;
  flex-direction: column;
  /* width: 100%; */
}
.submit h1 {
  width: 100%;
  font-size: 1.15vw;
  display: flex;
  text-align: start;
  margin-bottom: 1.75rem;
}
.submit a {
  text-decoration: none;
  color: rgba(255, 255, 255, 1);
  opacity: 0.65;
  display: flex;
  margin: 0.6875rem 0;
  font-size: 0.89vw;
  font-family: "Roboto";
  font-weight: 500;
}
.submit a:hover {
  color: rgba(218, 57, 43, 1);
  opacity: 1;
}
.mondayoff {
  display: flex;
  flex-direction: row;
  position: relative;
  color: rgba(255, 255, 255, 1);
  left: 0px;
  opacity: 0.75;
  align-self: flex-end;
  width: 100%;
  justify-content: center;
  margin-bottom: 1%;
}
.mondayoff h1 {
  font-family: "Roboto";
  font-size: 0.875rem;
  margin-bottom: 1.5rem;
  font-weight: 400;
}
.mondayoff h1::after {
  content: "|";
  margin: 0 0.8125rem 0 0.8125rem;
}
.contents {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.contents a {
  font-family: "Roboto";
  font-size: 0.875rem;
  font-weight: 400;
  text-decoration: none;
  color: rgba(255, 255, 255, 1);
}
.contents a:hover {
  cursor: pointer;
}
.iconcontainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 9vw;
}
.socialicon {
  display: grid;
  grid-template-columns: repeat(3, 3.75rem);

  justify-content: flex-end;
  column-gap: 0.9375rem;
}

.socialicon a img {
  width: 3.75rem;
  height: 3.75rem;
}
.storeIcon {
  display: grid;
  grid-template-columns: repeat(2, 8.4375rem);
  justify-content: flex-end;
  column-gap: 0.9375rem;
}
.storeIcon > a > img {
  width: 8.4375rem;
  height: 2.5rem;
  margin: 1.875rem 0;
}
.companyinfo {
  display: flex;

  width: 100%;
  color: rgba(255, 255, 255, 1);
  flex-direction: column;

  font-size: 0.875rem;
  opacity: 0.75;
  position: relative;
  top: 6.5625rem;
}
.mondayemail {
  display: flex;
  justify-content: flex-end;
}
.mondayemail > img {
  margin-right: 0.2625rem;
  width: 0.6875rem;
  height: 0.4375rem;
  margin-top: 0.125rem;
  align-self: center;
}
.mondayaddress {
  display: flex;
  justify-content: flex-end;
}
.mondayaddress > img {
  margin-right: 0.2625rem;
  width: 0.5rem;
  height: 0.75rem;
  align-self: center;
}
.address {
  display: flex;
}
@media all and (min-width: 1921px) {
  .companyIcon {
    height: 6vw;
    max-height: 100px;
  }
}
/*17in*/
@media all and (max-width: 1440px) {
  .container {
    margin-top: 4.875rem;
    /* margin-left: 12.29vw; */
  }
  .menu {
    /* width: 4.5%;
    margin-left: 0; */
    /* padding-right: 3.47vw; */
  }
  .menu h1 {
    margin-bottom: 1.75rem;
    font-size: 1.53vw;
  }
  .menu a {
    font-size: 1.18vw;
    margin-bottom: 0.6875rem;
  }
  .submit {
    margin-right: 0vw;
  }
  .submit h1 {
    margin-bottom: 1.75rem;
    font-size: 1.53vw;
  }
  .submit a {
    font-size: 1.18vw;
    margin-bottom: 0.6875rem;
  }
  .mondayoff {
    /* width: 30%; */
    font-size: 1vw;
    /* left: 5vw; */
  }
  .mondayoff h1::after {
    content: "|";
    margin: 0 0.8125rem 0 0.8125rem;
  }
  .iconcontainers {
    margin-right: 12.29vw;
    width: 50%;
  }
  .socialicon {
    grid-template-columns: repeat(3, 3.25rem);

    column-gap: 0.875rem;
  }
  .socialicon a img {
    width: 3.25rem;
    height: 3.25rem;
  }
  .storeIcon {
    grid-template-columns: repeat(2, 7.375rem);

    column-gap: 0.875rem;
  }
  .storeIcon > a > img {
    width: 7.375rem;
    height: 2.1875rem;
    margin: 1.875rem 0;
  }
  .companyinfo {
    display: flex;

    width: 100%;
    color: rgba(255, 255, 255, 1);
    flex-direction: column;

    font-size: 1vw;
    opacity: 0.75;
    position: relative;
    top: 7.7rem;
  }
  .mondayemail {
    display: flex;
    justify-content: flex-end;
  }
  .mondayemail > img {
    margin-right: 0.2625rem;
    width: 0.6875rem;
    height: 0.4375rem;
    margin-top: 0.125rem;
    align-self: center;
  }
  .mondayaddress {
    display: flex;
    justify-content: flex-end;
  }
  .mondayaddress > img {
    margin-right: 0.2625rem;
    width: 0.5rem;
    height: 0.75rem;
    align-self: center;
  }
}
/*15in*/
@media all and (max-width: 1280px) {
  footer {
    height: 25.625rem;
  }
  .container {
    margin-top: 4.875rem;
    /* margin-left: 14vw; */
  }
  .menu {
    /* width: 4.5%; */
    margin-left: 0;
    /* padding-right: 7.97vw; */
  }
  .menu h1 {
    margin-bottom: 1.75rem;
    font-size: 1.72vw;
  }
  .menu a {
    font-size: 1.33vw;
    margin-bottom: 0.75rem;
  }
  .submit {
    /* width: 25%; */
    /* margin-right: 0vw; */
  }
  .submit h1 {
    width: 100%;
    margin-bottom: 1.75rem;
    font-size: 1.72vw;
  }
  .submit a {
    font-size: 1.33vw;
    margin-bottom: 0.75rem;
  }
  .mondayoff {
    /* width: 30%; */
    left: 0px;
  }
  .mondayoff h1 {
    font-size: 1.09vw;
    margin-bottom: 0.75rem;
    font-weight: 400;
    text-align: center;
  }

  .contents a {
    font-size: 1.09vw;
  }

  .storeIcon {
    margin-bottom: 0rem;
  }

  .companyinfo {
    /* top: 7rem; */
  }

  .mondayaddress > img {
    position: static;
    align-self: flex-start;

    margin-right: 0.25rem;
    margin-top: 0.05rem;
  }

  .address {
    flex-direction: column;
  }
  .address > p {
    text-align: end;
  }
}
/*13in*/
@media all and (max-width: 1024px) {
  footer {
    height: 25.625rem;
  }
  .container {
    margin-top: 5.125rem;
    grid-template-columns: 0.5fr 0.5fr 0.5fr 1fr;
    /* margin-left: 11.52vw; */
  }
  .menu {
    /* width: 5.5%; */
    margin-left: 0;
    /* padding-right: 8.11vw; */
  }
  .menu h1 {
    margin-bottom: 1.6875rem;
    font-size: 1.95vw;
  }
  .menu a {
    font-size: 1.66vw;
    margin-bottom: 0.6875rem;
  }
  .submit {
    /* width: 24%; */
    margin-right: 0vw;
  }
  .submit h1 {
    margin-bottom: 1.6875rem;
    font-size: 1.95vw;
  }
  .submit a {
    font-size: 1.66vw;
    margin-bottom: 0.6875rem;
  }
  .mondayoff {
    /* width: 30%; */
    left: 0;
    /* justify-content: flex-start; */
  }
  .mondayoff h1 {
    font-size: 1vw;
    margin-bottom: 0.75rem;
    font-weight: 400;
    text-align: center;
  }

  .contents a {
    font-size: 1vw;
  }
  .mondayoff h1::after {
    content: "|";
    margin: 0.75rem;
  }
  .iconcontainers {
    /* width: 28%; */
  }
  .socialicon {
    width: 100%;
    column-gap: 0.5625rem;
  }
  .socialicon a img {
    width: 3.5rem;
    height: 3.5rem;
  }
  .storeIcon {
    margin-bottom: 0rem;
    width: 100%;
    column-gap: 0.5625rem;
  }
  .storeIcon > a > img {
    width: 7.75rem;
    height: 2.25rem;
  }
  .companyinfo {
    /* top: 7rem; */
  }
  .mondayemail > p {
    font-size: 1vw;
  }
  .mondayaddress > img {
    position: static;
    align-self: flex-start;
    left: 0;
    margin-right: 0.25rem;
    top: 0.1rem;
  }
}
/*tablet*/
@media all and (max-width: 768px) {
  footer {
    height: 42.5rem;
    position: relative;
  }
  .container {
    display: inline;
    margin-left: 0;
    margin-top: 0;
    flex-direction: column;
  }
  .menu {
    position: absolute;
    top: 4.2rem;
    left: 14vw;
    padding-right: 0;
    padding-left: 0;
  }
  .menu > h1 {
    display: none;
  }
  .menu > a {
    font-size: 1.375rem;
    margin-top: 0;
    margin-bottom: 1.25rem;
  }
  .submit {
    position: absolute;
    top: 12.5rem;
    left: 14vw;
  }
  .submit > h1 {
    display: none;
  }
  .submit > a {
    font-size: 1.375rem;
    margin-top: 0;
    margin-bottom: 1.25rem;
  }
  .mondayoff {
    position: absolute;
    top: 36.9375rem;
    left: 0rem;
    justify-content: center;
    width: 100%;
  }
  .mondayoff h1 {
    font-size: 1.1875rem;
    margin-bottom: 0.75rem;
    font-weight: 400;
    text-align: center;
  }
  .contents {
    justify-content: center;
  }
  .contents > a {
    font-size: 1.1875rem;
  }
  .companyIcon {
    position: absolute;
    left: 13.54vw;
    top: 8.375rem;
  }
  .companyIcon > img {
    width: 8.6875rem;
    height: 2.25rem;
  }
  .iconcontainer {
    padding-right: 0;
  }
  .socialicon {
    z-index: 1;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 5rem);
    column-gap: 1rem;
    justify-content: center;
    position: absolute;
    top: 19.8125rem;

    left: 0vw;
  }

  .socialicon > a > img {
    width: 5rem;
    height: 5rem;
  }
  .storeIcon {
    position: absolute;
    margin-bottom: 0;
    grid-template-columns: repeat(2, 10.9375rem);
    grid-template-rows: repeat(1, 3.3125rem);
    column-gap: 1.25rem;
    top: 27.625rem;
    height: 0;
    right: 0vw;
    width: 100%;
    justify-content: center;
  }
  .storeIcon > a {
    height: 0;
  }
  .storeIcon > a > img {
    margin-bottom: 0;
    height: 3.3125rem;
    width: 10.9375rem;
    margin-top: 0;
  }
  .companyinfo {
    position: absolute;
    top: 32.6875rem;
    width: 100%;
    align-items: center;
  }
  .mondayemail > p {
    font-size: 1rem;
  }
  .address {
    flex-direction: row;
    font-size: 1rem;
  }
  .address > p {
    text-align: center;
  }
}
/*mobile*/
@media all and (max-width: 480px) {
  footer {
    height: 650px;
    position: relative;
  }
  .container {
    margin-left: 0;
    margin-top: 0;
    flex-direction: column;
  }
  .menu {
    position: absolute;
    top: 2.5rem;
    left: 8vw;
    padding-right: 0;
    width: 80%;
    align-items: flex-start;
  }
  .menu > h1 {
    display: none;
  }
  .menu > a {
    position: relative;
    font-size: 1.375rem;
    margin-top: 0;
    margin-bottom: 1.25rem;
  }
  .submit {
    position: absolute;
    top: 11rem;
    left: 8vw;
    width: 7.125rem;
    margin-right: 0;
    width: 80%;
    justify-content: flex-start;
  }
  .submit > h1 {
    display: none;
  }
  .submit > a {
    font-size: 1.375rem;
    margin-top: 0;
    margin-bottom: 1.25rem;
  }
  .mondayoff {
    position: absolute;
    top: 34.5625rem;
    left: 0rem;

    width: 100%;
  }
  .mondayoff h1 {
    font-size: 4vw;
    margin-bottom: 0.75rem;
    font-weight: 400;
    text-align: center;
  }
  .contents {
    justify-content: center;
  }
  .contents > a {
    font-size: 4vw;
  }

  .socialicon {
    grid-template-columns: repeat(3, 17vw);
    grid-template-rows: repeat(1, 17vw);
    column-gap: 3vw;
    top: 17.4375rem;
  }

  .socialicon > a > img {
    width: 17vw;
    height: 17vw;
  }
  .storeIcon {
    grid-template-columns: repeat(2, 36vw);
    grid-template-rows: repeat(1, 11vw);
    top: 24rem;
  }
  .storeIcon > a > img {
    margin-top: 0rem;
    margin-bottom: 0;
    width: 36vw;
    height: 11vw;
  }
  .companyinfo {
    position: absolute;
    top: 30.3125rem;
    width: 100%;
    align-items: center;
  }
  .mondayemail > p {
    font-size: 3vw;
  }
  .address {
    flex-direction: row;
    font-size: 3vw;
  }
  .address > p {
    text-align: center;
  }
}
