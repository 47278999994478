@font-face {
  font-family: "Pretendard-700";
  src: url("./static/fonts/Pretendard-Bold.woff") format("woff");
}
@font-face {
  font-family: "Pretendard-600";
  src: url("./static/fonts/Pretendard-SemiBold.woff") format("woff");
}
@font-face {
  font-family: "Pretendard-400";
  src: url("./static/fonts/Pretendard-Regular.woff") format("woff");
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
}
body::-webkit-scrollbar {
  display: none;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
