@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
.videosection {
  width: 100%;
  height: 48vw;
  background: #da392b;
}
.text {
  font-family: "Roboto";
  position: absolute;
  color: #ffffff;
  text-align: start;
  font-weight: 700;
  left: 9.22vw;
  top: 18.8vw;
  text-shadow: 0rem 0.625rem 0.375rem #00000029;
  z-index: 1;
}
.title > p {
  font-size: 4.38vw;
}
.context {
  font-family: "Roboto";
  position: absolute;
  left: 9.22vw;
  top: 30.47vw;
  color: #e5e5e5;
  font-size: 1.15vw;
  text-align: start;
  font-weight: 500;
  text-shadow: 0px 3px 6px #00000029;
  line-height: 1.5vw;
  z-index: 1;
}
.context > p {
  width: 30vw;
  height: 13.4vh;
}

.background {
  width: 57vw;
  background-size: cover;
  border-radius: 3vw;
  position: absolute;
  top: 10vw;
  display: flex;
  justify-content: flex-end;
  right: 9.22vw;

  box-shadow: 0px 50px 24px #a0180c;
}

@media all and (min-width: 1921px) {
  .text {
    left: 9.22vw;
  }
  .context {
    left: 9.22vw;
  }
  .img {
    right: 9.22vw;
  }
}
/*17in*/

@media all and (max-width: 1440px) {
  .videosection {
    width: 100%;
    height: 50vw;
    background: #da392b;
  }
  .text {
    top: 17vw;
    left: 12.29vw;
  }

  .background {
    top: 13vw;
    width: 47vw;
    right: 12.29vw;
    box-shadow: 0px 50px 24px #a0180c;
  }

  .title > p {
    font-size: 5.21vw;
  }
  .context {
    left: 12.29vw;
    top: 30vw;
    z-index: 1;
  }
  .context > p {
    width: 34.24vw;
    height: 3vw;
    font-size: 1.32vw;
  }
}

/*15in*/
@media all and (max-width: 1280px) {
  .videosection {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: center;
    height: 58vw;
    padding-top: 7vw;
  }
  .text {
    position: static;
  }
  .title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 1.95vw;
  }
  .title > p {
    font-size: 4.49vw;
  }
  .context {
    position: static;
    display: flex;
    justify-content: center;
    margin-bottom: 8.01vw;
  }
  .context > p {
    text-align: center;
    font-size: 1.66vw;
    width: 68.36vw;
    line-height: 2vw;
    font-weight: 400;
    color: #e5e5e5;
    text-shadow: 0px 5px 6px #00000029;
  }
  .background {
    position: static;
    justify-content: center;
    margin-top: 0vw;
    margin-bottom: 2.93vw;
    box-shadow: 0px 20px 24px #a0180c;
    width: 63.87vw;
  }
}

/*13in*/
@media all and (max-width: 1024px) {
  .videosection {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: center;
    height: 60vw;
    padding-top: 7vw;
  }
  .text {
    position: static;
  }
  .title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 1.95vw;
  }
  .title > p {
    font-size: 4.49vw;
  }
  .context {
    position: static;
    display: flex;
    justify-content: center;
    margin-bottom: 8.01vw;
  }
  .context > p {
    text-align: center;
    font-size: 1.66vw;
    width: 68.36vw;
    line-height: 2vw;
    font-weight: 400;
    color: #e5e5e5;
    text-shadow: 0px 5px 6px #00000029;
  }
  .background {
    position: static;
    justify-content: center;
    margin-top: 0vw;
    margin-bottom: 2.93vw;
    box-shadow: 0px 20px 24px #a0180c;
    width: 63.87vw;
  }
}
/*tablet*/
@media all and (max-width: 768px) {
  .videosection {
    width: 100%;
    height: 75vw;
    background: #da392b;
    justify-content: flex-end;
    padding-top: 11vw;
  }
  .text {
    display: flex;
    justify-content: center;
  }
  .title {
    width: 44.92vw;
  }
  .title > p {
    font-size: 5.99vw;
  }
  .background {
    margin-top: 0vw;
    margin-bottom: 4.69vw;
    width: 73.05vw;
    border-radius: 5.21vw;
  }
  .context > p {
    font-size: 2.21vw;
    width: 72.79vw;
  }
}
/*mobile*/
@media all and (max-width: 480px) {
  .videosection {
    height: 90vw;
    align-items: flex-start;
    padding-bottom: 0vw;
  }
  .background {
    margin-top: 0vw;
    margin-bottom: 7vw;

    width: 92vw;
    box-shadow: none;
    margin-left: 4vw;
  }

  .text {
    margin-top: 0px;
    text-align: start;
    z-index: 1;
    justify-content: center;
    width: 100vw;
    /* margin-left: 8.3vw; */
  }
  .title {
    width: 51.7vw;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .title > p {
    font-size: 7vw;
  }

  .context {
    position: static;
    display: flex;
    justify-content: center;
    width: 100vw;
    margin-top: 3vw;
  }
  .context > p {
    font-size: 4vw;
    width: 100vw;
    text-align: center;
    line-height: 5.8vw;
  }
}
