@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@500;700&display=swap");
@keyframes motion {
  0% {
    margin-top: 0;
  }
  100% {
    margin-top: 10px;
  }
}
@keyframes motionLeft {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: 10px;
  }
}
@keyframes motionRight {
  0% {
    margin-left: 0px;
  }
  100% {
    margin-left: -10px;
  }
}
.mobilecardcontainer {
  display: none;
}
.submittitle {
  display: none;
}
.container {
  background: #f4f1ef;
  width: 100%;
}

.zigzag {
  width: 100%;
  /* visibility: hidden; */
}
.zigzag img {
  width: 100%;
  margin-top: -5px;
}

.cardcontainer {
  display: grid;
  grid-template-columns: repeat(2, 26.1vw);
  grid-template-rows: 16.7vw;
  column-gap: 4.7vw;
  justify-content: center;
}
.publishing_container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  flex-wrap: wrap;
  z-index: 1;
  justify-content: flex-end;
  top: -30%;
}
.partnership_container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  z-index: 1;
  justify-content: flex-end;
  top: -30%;
}

.publishing_container .card,
.partnership_container .card {
  position: relative;
  width: 26.1vw;
  height: 21.4vw;
  box-shadow: 0px 6px 18px #da392b6e;
  border-radius: 2.1vw;
  background: #ffffff;
  display: flex;
  justify-content: flex-start;
}
.handShakeImg {
  position: absolute;
  width: 13.6vw;
  height: 9vw;
  right: 1.6vw;
  top: -1.7vw;
  z-index: 1;
}
.publishing_container:hover .handShakeImg {
  animation: motion 0.3s linear 0s 4 alternate;
}

.publishing_container .card .content h1,
.partnership_container .card .content h1 {
  position: absolute;
  top: 5.3vw;
  left: 2.6vw;
  font-size: 2vw;
  color: black;
  pointer-events: none;
  font-family: "Roboto";
  font-weight: 700;
}
.publishing_container .card .content p,
.partnership_container .card .content p {
  position: absolute;
  top: 8.6vw;
  left: 2.6vw;
  width: 21.8vw;
  text-align: start;
  font-size: 1vw;
  color: #656565;
  pointer-events: none;
  font-family: "Roboto";
  font-weight: 500;
  line-height: 1.3vw;
}
.publishing_container .card .content a,
.partnership_container .card .content a {
  position: absolute;
  display: flex;
  top: 16.3vw;
  right: 2vw;
  font-size: 1vw;
  place-content: center;
  place-items: center;
  background: #da392b;
  color: white;
  border-radius: 1.4vw;
  text-decoration: none;
  font-family: "Roboto";
  font-weight: 700;
  width: 11vw;
  height: 2.7vw;
}
.publishing_container .card .content a:hover,
.partnership_container .card .content a:hover {
  border: #da392b 2px solid;
  background-color: white;
  color: #da392b;
}
/**/

.fistBumpLeft {
  position: absolute;
  height: 5.4vw;
  left: 9.9vw;
  top: -1%;
  z-index: 1;
  transform: matrix(0.97, 0.24, -0.24, 0.97, 0, 0);
}
.fistBumpRight {
  position: absolute;
  height: 5.4vw;
  left: 18.8vw;
  top: 2.3vw;
  z-index: 1;
  transform: matrix(0.97, 0.35, -0.24, 0.97, 0, 0);
}
.partnership_container:hover .fistBumpLeft {
  animation: motionLeft 0.3s linear 0s 2 alternate;
}
.partnership_container:hover .fistBumpRight {
  animation: motionRight 0.3s linear 0s 2 alternate;
}

/*17in*/
@media all and (max-width: 1440px) {
  .cardcontainer {
    column-gap: 7vw;
    grid-template-columns: repeat(2, 29.9vw);
    grid-template-rows: 17.6vw;
  }
  .publishing_container .card,
  .partnership_container .card {
    width: 29.9vw;
    height: 22.9vw;
  }
  .handShakeImg {
    width: 15.6vw;
    height: 10.2vw;
    top: -1.9vw;
    right: -1vw;
  }
  .publishing_container .card .content h1,
  .partnership_container .card .content h1 {
    top: 6vw;
    left: 2.9vw;
    font-size: 2.1vw;
  }
  .publishing_container .card .content p,
  .partnership_container .card .content p {
    left: 2.9vw;
    top: 9.9vw;
    width: 80%;
    font-size: 1.1vw;
    line-height: 1.5vw;
  }
  .publishing_container .card .content a,
  .partnership_container .card .content a {
    top: 18.5vw;
    right: 2.3vw;
    font-size: 1.1vw;
    width: 12.6vw;
    height: 3vw;
    border-radius: 1.8vw;
  }

  .fistBumpLeft {
    width: 10vw;
    height: 6.1vw;
    left: 11.1vw;
    top: -4%;
  }
  .fistBumpRight {
    width: 10vw;
    height: 6.1vw;
    left: 21.5vw;
    top: 2.3vw;
  }
}

/*15in*/
@media all and (max-width: 1280px) {
  .cardcontainer {
    grid-template-columns: repeat(2, 28.5vw);
    grid-template-rows: 15.8vw;
    column-gap: 2.3vw;
  }
  .publishing_container .card,
  .partnership_container .card {
    width: 28.5vw;
    height: 20.4vw;
    border-radius: 3.1vw;
  }
  .handShakeImg {
    width: 13.6vw;
    height: 9vw;
    top: -1.5vw;
    right: 0;
  }

  .publishing_container .card .content h1,
  .partnership_container .card .content h1 {
    top: 5.4vw;
    left: 3.3vw;
    font-size: 2.1vw;
  }
  .publishing_container .card .content p,
  .partnership_container .card .content p {
    top: 8.8vw;
    left: 3.3vw;
    width: 80%;
    font-size: 1.1vw;
    line-height: 1.3vw;
  }
  .publishing_container .card .content a,
  .partnership_container .card .content a {
    top: 15.6vw;
    right: 2.3vw;
    font-size: 1vw;
    width: 12vw;
    height: 2.8vw;
    border-radius: 2vw;
  }

  .fistBumpLeft {
    width: 8.9vw;
    left: 11.7vw;
    top: -5%;
  }
  .fistBumpRight {
    width: 8.9vw;
    left: 21.1vw;
    top: 2vw;
    transform: matrix(0.97, 0.45, -0.24, 0.97, 0, 0);
  }
}
/*13in*/
@media all and (max-width: 1024px) {
  .cardcontainer {
    grid-template-columns: repeat(2, 31.2vw);
    grid-template-rows: 19.1vw;
    column-gap: 1.6vw;
  }
  .publishing_container .card,
  .partnership_container .card {
    width: 31.2vw;
    height: 24.9vw;
    border-radius: 3.9vw;
  }
  .handShakeImg {
    width: 17vw;
    height: 11.1vw;
    top: -2.9vw;
  }

  .publishing_container .card .content h1,
  .partnership_container .card .content h1 {
    top: 4.8vw;
    left: 3.5vw;
    font-size: 2.6vw;
  }
  .publishing_container .card .content p,
  .partnership_container .card .content p {
    top: 9.4vw;
    left: 3.5vw;
    width: 25vw;
    font-size: 1.4vw;
    line-height: 1.9vw;
    word-break: keep-all;
  }
  .publishing_container .card .content a,
  .partnership_container .card .content a {
    width: 14.9vw;
    height: 3.5vw;
    top: 18.9vw;
    right: 8.1vw;
    border-radius: 2.5vw;
    font-size: 1.3vw;
  }

  /**/

  .fistBumpLeft {
    width: 11.1vw;
    height: 6.8vw;
    left: 11.7vw;
    top: -10%;
    transform: matrix(0.97, 0.25, -0.24, 0.97, 0, 0);
  }
  .fistBumpRight {
    width: 11.1vw;
    height: 6.8vw;
    left: 23.4vw;
    top: 1vw;
    transform: matrix(0.97, 0.3, -0.24, 0.97, 0, 0);
  }
}
@media all and (max-width: 768px) {
  .container {
    height: 35vw;
  }
  .cardcontainer {
    grid-template-columns: repeat(2, 35.42vw);
    grid-template-rows: 32.1vw;
    column-gap: 2.1vw;
  }
  .publishing_container .card,
  .partnership_container .card {
    width: 35.42vw;
    height: 41.93vw;
    border-radius: 5.2vw;
  }
  .handShakeImg {
    width: 22.4vw;
    height: 14.71vw;
    top: -7vw;
    right: 6.5vw;
  }
  .publishing_container .card .content h1 {
    top: 10.16vw;
    left: 10.3vw;
    font-size: 3.13vw;
  }
  .partnership_container .card .content h1 {
    top: 10.16vw;
    left: 9.5vw;
    font-size: 3.13vw;
  }
  .publishing_container .card .content p {
    font-size: 1.82vw;
    width: 29.69vw;
    top: 16.93vw;
    left: 2.86vw;
    text-align: center;
  }
  .partnership_container .card .content p {
    font-size: 1.82vw;
    width: 29.69vw;
    top: 16.93vw;
    left: 2.5vw;
    text-align: center;
  }
  .publishing_container .card .content a,
  .partnership_container .card .content a {
    right: 4.3vw;
    width: 26.69vw;
    height: 6.25vw;
    font-size: 2.21vw;
    top: 31.9vw;
    border-radius: 3.4vw;
  }

  .fistBumpLeft {
    width: 13.93vw;
    height: 9vw;
    left: 3.5vw;
    top: -5vw;
  }
  .fistBumpRight {
    width: 13.93vw;
    height: 9vw;
    left: 19vw;
    top: 0vw;
    transform: matrix(0.97, 0.45, -0.24, 0.97, 0, 0);
  }
}
/*mobile*/
@media all and (max-width: 480px) {
  .zigzag img {
    top: 5px;
  }
  .container {
    height: 80vw;
    padding-bottom: 18.8vw;
  }
  .cardcontainer {
    display: none;
  }
  .submittitle {
    display: inline;
  }
  .submittitle > h1 {
    margin-top: 15.6vw;
    font-size: 7.3vw;
    margin-bottom: 1.9375rem;
  }
  .mobilecardcontainer {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    row-gap: 8vw;
  }
  .mobilecardcontainer > a {
    text-decoration: none;
  }
  .publishing_container {
    position: relative;
    top: 0;
    justify-content: center;
  }
  .partnership_container {
    position: relative;
    top: 0;
    justify-content: center;
  }
  .publishing_container .card,
  .partnership_container .card {
    width: 83vw;
    height: 24vw;
    font-size: 5vw;
    margin-right: 0vw;
    border-radius: 8vw;
  }
  .publishing_container .card .content,
  .partnership_container .card .content {
    padding: 0;
    display: flex;
    align-items: center;
  }
  .publishing_container .card .content h1,
  .partnership_container .card .content h1 {
    position: static;
    text-align: start;
    width: 23.8vw;
    font-size: 5vw;
    margin-bottom: 0vw;
    margin-left: 6vw;
  }

  .handShakeImg {
    position: absolute;
    width: 26vw;
    height: 19vw;
    left: 47vw;
    top: 2vw;
  }
  .powImg {
    position: absolute;
    width: 36vw;
    height: 14vw;
    transform: matrix(0.97, 0.24, -0.24, 0.97, 0, 0);
    left: 42vw;
    top: 4vw;
  }
}
