@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bree+Serif&display=swap");

.container {
  display: flex;
  width: 100%;
  padding-top: 10.68vw;
  padding-bottom: 14.37vw;
  background-color: #f4f1ef;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.title {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 23px;
  font-family: "Roboto";
  font-weight: 700;
}
.title h1 {
  color: #8d0000;
  font-size: 1.35vw;
  font-weight: bold;
}

.contextfirst {
  display: flex;
  width: 48.96vw;
  justify-content: center;
}
.contextfirst p {
  color: #292929;
  font-size: 1.1vw;
  margin-bottom: 6.875rem;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.93vw;
}
.contextsecond {
  display: flex;
  width: 48.96vw;
  justify-content: center;
}
.contextsecond p {
  color: #292929;
  font-size: 1.1vw;
  margin-bottom: 5.1875rem;
  font-family: "Roboto";
  font-weight: 400;
  line-height: 1.93vw;
}
.image {
  height: 32.81vw;
  width: 53.85vw;
  border-radius: 1.3vw;
}

/*17in*/
@media all and (max-width: 1440px) {
  .container {
    padding-top: 10.28vw;
    padding-bottom: 18.38vw;
  }
  .title h1 {
    font-size: 1.39vw;
  }
  .contextfirst p {
    font-size: 1.2vw;
    width: 51.39vw;
    line-height: 2.01vw;
  }
  .contextsecond p {
    font-size: 1.2vw;
    width: 51.39vw;
    line-height: 2.01vw;
  }
  .image {
    height: 32.71vw;
    width: 53.75vw;
    border-radius: 25px;
  }
}
/*15in*/
@media all and (max-width: 1280px) {
  .container {
    padding-top: 12.42vw;
    padding-bottom: 15.08vw;
  }
  .title h1 {
    font-size: 1.56vw;
  }
  .contextfirst p {
    font-size: 1.3vw;
    width: 57.81vw;
    line-height: 2.27vw;
  }
  .contextsecond p {
    font-size: 1.3vw;
    width: 57.81vw;
    line-height: 2.27vw;
  }
  .image {
    height: 31.25vw;
    width: 50vw;
    border-radius: 25px;
  }
}
/*13in*/
@media all and (max-width: 1024px) {
  .container {
    padding-top: 11.43vw;
    padding-bottom: 10.55vw;
  }
  .title {
    margin-bottom: 2.64vw;
  }
  .title h1 {
    font-size: 1.95vw;
  }
  .contextfirst p {
    font-size: 1.7vw;
    line-height: 2.83vw;
    margin-bottom: 6.84vw;
    width: 63.87vw;
  }
  .contextsecond p {
    font-size: 1.7vw;
    line-height: 2.83vw;
    margin-bottom: 8.98vw;
    width: 63.87vw;
  }
  .image {
    height: 38.96vw;
    width: 63.87vw;
    border-radius: 25px;
  }
}
/*tablet*/
@media all and (max-width: 768px) {
  .container {
    padding-top: 15.36vw;
    padding-bottom: 24.22vw;
  }
  .title h1 {
    font-size: 2.6vw;
    margin-bottom: 2.6vw;
  }
  .contextfirst {
    width: 73.05vw;
  }
  .contextfirst p {
    font-size: 2.1vw;
    line-height: 3.91vw;
    margin-bottom: 11.33vw;
    width: 73.05vw;
  }
  .contextsecond {
    width: 73.05vw;
  }
  .contextsecond p {
    font-size: 2.1vw;
    line-height: 3.91vw;
    margin-bottom: 8.72vw;
    width: 73.05vw;
  }
  .image {
    height: 48.18vw;
    width: 72.92vw;
    border-radius: 25px;
  }
}
/*mobile*/
@media all and (max-width: 480px) {
  .container {
    padding-top: 0vw;
    padding-bottom: 21vw;
  }
  .title {
    margin: 0;
  }
  .title h1 {
    font-size: 4.2vw;
    margin-bottom: 4.2vw;
  }
  .contextfirst {
    width: 83.1vw;
  }
  .contextfirst p {
    font-size: 3.5vw;
    line-height: 5vw;
    margin-bottom: 9.8vw;
    width: 100%;
  }
  .contextsecond {
    width: 83.1vw;
  }
  .contextsecond p {
    font-size: 3.5vw;
    line-height: 5vw;
    margin-bottom: 11.9vw;
    width: 100%;
  }
  .image {
    height: 55vw;
    width: 83.3vw;
    border-radius: 25px;
  }
}
