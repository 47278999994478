.container {
  background: #f4f1ef;
  height: 2050px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.formcontainer {
  width: 68.75rem;
  height: 1650px;
  background: white;
  margin-top: 14.125rem;
  margin-bottom: 14.125rem;
  border-radius: 5.75rem;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 3px 27px #00000029;
}
.title {
  margin-top: 6.1875rem;
  margin-bottom: 4.0625rem;
}
.title > h1 {
  font-family: "Roboto";
  font-weight: 700;
  font-size: 3rem;
  margin-bottom: 0.9375rem;
}
.title > h2 {
  font-family: "Roboto";
  font-weight: 700;
  font-size: 1.6875rem;
  display: flex;
  justify-content: center;
  color: #767676;
}
.title > h2 > div {
  width: 10px;
  height: 10px;
  transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
  background: #767676 0% 0% no-repeat padding-box;
  align-self: center;
  margin: 0 17px;
}
.subtitle {
  margin-left: 5.4375rem;
  margin-bottom: 2rem;
}
.subtitle > h1 {
  font-family: "Roboto";
  font-weight: 700;
  font-size: 1.6875rem;
  text-align: start;
}
.firstalertmessage,
.lastalertmessage,
.eamilalertmessage {
  color: #e31414;
  font-family: "Roboto";
  font-weight: 700;
  font-size: 1.125rem;
}
.inputcontainer {
  margin: 0 5.4375rem;
}
.namecontainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2.125rem;
}
.firstname {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.firstname > h1 {
  font-family: "Roboto";
  font-weight: 700;
  font-size: 1.1875rem;
  color: #767676;
  margin-bottom: 1rem;
}
.firstnameinput {
}
.firstname > input {
  width: 26.9375rem;
  height: 3.6875rem;
  border-radius: 0.625rem;
  border: solid 1px #707070;
  font-size: 1.125rem;
  color: #484848;
  font-family: "Roboto";
  font-weight: 700;
  margin-bottom: 0.3125rem;

  padding-left: 15px;
}
.firstname > input:focus {
  border: white;
  outline: none;
  box-shadow: 0 0 0 3px #f7931e;
}
.firstname > h1::after {
  content: "*";
  color: #e31414;
}

.lastname {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.lastname > h1 {
  font-family: "Roboto";
  font-weight: 700;
  font-size: 1.1875rem;
  color: #767676;
  margin-bottom: 1rem;
}
.lastname > input {
  width: 26.9375rem;
  height: 3.6875rem;
  border-radius: 0.625rem;
  border: solid 1px #707070;
  font-size: 1.125rem;
  color: #484848;
  font-family: "Roboto";
  font-weight: 700;
  padding-left: 15px;
  margin-bottom: 0.3125rem;
}
.lastnameinput {
}
.lastname > input:focus {
  border: white;
  outline: none;
  box-shadow: 0 0 0 3px #f7931e;
}
.lastname > h1::after {
  content: "*";
  color: #e31414;
}
.emailandskype {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2.125rem;
}
.email {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.email > h1 {
  font-family: "Roboto";
  font-weight: 700;
  font-size: 1.1875rem;
  color: #767676;
  margin-bottom: 1rem;
}
.emailinput {
}
.email > h1::after {
  content: "*";
  color: #e31414;
}
.email > input {
  width: 26.9375rem;
  height: 3.6875rem;
  border-radius: 0.625rem;
  border: solid 1px #707070;
  font-size: 1.125rem;
  color: #484848;
  font-family: "Roboto";
  font-weight: 700;
  margin-bottom: 0.3125rem;
  padding-left: 15px;
}
.email > input:focus {
  border: white;
  outline: none;
  box-shadow: 0 0 0 3px #f7931e;
}

.skypeid {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.skypeid > h1 {
  font-family: "Roboto";
  font-weight: 700;
  font-size: 1.1875rem;
  color: #767676;
  margin-bottom: 1rem;
}

.skypeid > input {
  width: 26.9375rem;
  height: 3.6875rem;
  border-radius: 0.625rem;
  border: solid 1px #707070;
  font-size: 1.125rem;
  color: #484848;
  font-family: "Roboto";
  font-weight: 700;
  margin-bottom: 0.3125rem;
  padding-left: 15px;
}
.skypeid > input:focus {
  border: white;
  outline: none;
  box-shadow: 0 0 0 3px #f7931e;
}

.tellus {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 4.28125rem;
}
.tellus > h1 {
  font-family: "Roboto";
  font-weight: 700;
  font-size: 1.1875rem;
  color: #767676;
  margin-bottom: 1rem;
}
.tellus > textarea {
  width: 56rem;
  height: 12.4375rem;
  border-radius: 0.625rem;
  border: solid 1px #707070;
  font-size: 1.125rem;
  color: #484848;
  font-family: "Roboto";
  font-weight: 700;
  padding: 15px;
}
.tellus > textarea:focus {
  border: white;
  outline: none;
  box-shadow: 0 0 0 3px #f7931e;
}
.teamcontainer > h1 {
  font-family: "Roboto";
  font-weight: 700;
  font-size: 1.6875rem;
  text-align: start;
  margin-bottom: 2rem;
  border-top-style: dashed;
  border-top-color: #909090;
  border-top-width: 1px;
  padding-top: 4.28125rem;
}
.studionameandcompany {
  display: flex;
  justify-content: space-between;

  margin-bottom: 2.125rem;
}
.studioname {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.studioname > h1 {
  font-family: "Roboto";
  font-weight: 700;
  font-size: 1.1875rem;
  color: #767676;
  margin-bottom: 1rem;
}
.studioname > input {
  width: 26.9375rem;
  height: 3.6875rem;
  border-radius: 0.625rem;
  border: solid 1px #707070;
  font-size: 1.125rem;
  color: #484848;
  font-family: "Roboto";
  font-weight: 700;
  margin-bottom: 1rem;
  padding-left: 10px;
}
.studioname > input:focus {
  border: white;
  outline: none;
  box-shadow: 0 0 0 3px #f7931e;
}
.companyname {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.companyname > h1 {
  font-family: "Roboto";
  font-weight: 700;
  font-size: 1.1875rem;
  color: #767676;
  margin-bottom: 1rem;
}
.companyname > input {
  width: 26.9375rem;
  height: 3.6875rem;
  border-radius: 0.625rem;
  border: solid 1px #707070;
  font-size: 1.125rem;
  color: #484848;
  font-family: "Roboto";
  font-weight: 700;
  margin-bottom: 1rem;
  padding-left: 10px;
}
.companyname > input:focus {
  border: white;
  outline: none;
  box-shadow: 0 0 0 3px #f7931e;
}
.portfolioandurl {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5.1875rem;
}
.portfoliolink {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.portfoliolink > h1 {
  font-family: "Roboto";
  font-weight: 700;
  font-size: 1.1875rem;
  color: #767676;
  margin-bottom: 1rem;
}
.portfoliolink > input {
  width: 26.9375rem;
  height: 3.6875rem;
  border-radius: 0.625rem;
  border: solid 1px #707070;
  font-size: 1.125rem;
  color: #484848;
  font-family: "Roboto";
  font-weight: 700;
  margin-bottom: 1rem;
  padding-left: 10px;
}
.portfoliolink > input:focus {
  border: white;
  outline: none;
  box-shadow: 0 0 0 3px #f7931e;
}

.gameurl {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.gameurl > h1 {
  font-family: "Roboto";
  font-weight: 700;
  font-size: 1.1875rem;
  color: #767676;
  margin-bottom: 1rem;
}
.gameurl > input {
  width: 26.9375rem;
  height: 3.6875rem;
  border-radius: 0.625rem;
  border: solid 1px #707070;
  font-size: 1.125rem;
  color: #484848;
  font-family: "Roboto";
  font-weight: 700;
  padding-left: 10px;
}
.gameurl > input:focus {
  border: white;
  outline: none;
  box-shadow: 0 0 0 3px #f7931e;
}

.submitbutton {
  font-family: "Roboto";
  font-weight: 700;
  width: 18.5rem;
  height: 4.875rem;
  font-size: 1.6875rem;
  background: #da392b;
  box-shadow: 0px 3px 6px #da392b79;
  border-radius: 66px;
  outline: none;
  color: white;
  border: none;
  align-self: center;
  margin-bottom: 4.125rem;
}
.submitbutton:hover {
  cursor: pointer;
}
/*17in*/
@media screen and (max-width: 1440px) {
  .container {
    height: 1870px;
  }
  .formcontainer {
    width: 56.25rem;
    height: 83rem;
    margin-top: 13.75rem;
    margin-bottom: 20rem;
  }
  .title {
    margin-top: 5.0625rem;
    margin-bottom: 3.3125rem;
  }
  .title > h1 {
    font-size: 2.4375rem;
    margin-bottom: 0.875rem;
  }
  .title > h2 {
    font-size: 1.375rem;
  }

  .subtitle {
    margin-left: 4.4375rem;
    margin-bottom: 1.5625rem;
  }
  .subtitle > h1 {
    font-family: "Roboto";
    font-weight: 700;
    font-size: 1.3125rem;
    text-align: start;
  }
  .inputcontainer {
    margin: 0 4.4375rem;
  }
  .namecontainer {
    margin-bottom: 1.75rem;
  }

  .firstname > h1 {
    font-size: 0.9375rem;
    margin-bottom: 0.8125rem;
  }

  .firstname > input {
    width: 22.125rem;
    height: 3rem;
    font-size: 0.875rem;
  }

  .firstalertmessage,
  .lastalertmessage,
  .eamilalertmessage {
    color: #e31414;
    font-family: "Roboto";
    font-weight: 700;
    font-size: 0.9375rem;
  }

  .lastname > h1 {
    font-size: 0.9375rem;
    margin-bottom: 0.8125rem;
  }
  .lastname > input {
    width: 22.125rem;
    height: 3rem;
    font-size: 0.875rem;
  }

  .email > h1 {
    font-size: 0.9375rem;
    margin-bottom: 0.8125rem;
  }

  .email > input {
    width: 22.125rem;
    height: 3rem;
    font-size: 0.875rem;
  }

  .skypeid > h1 {
    font-size: 0.9375rem;
    margin-bottom: 0.8125rem;
  }

  .skypeid > input {
    width: 22.125rem;
    height: 3rem;
    font-size: 0.875rem;
  }
  .tellus {
    margin-bottom: 3.46875rem;
  }
  .tellus > h1 {
    font-size: 0.9375rem;
    margin-bottom: 0.8125rem;
  }
  .tellus > textarea {
    width: 45.5rem;
    height: 10.1875rem;

    font-size: 0.875rem;
  }

  .teamcontainer > h1 {
    font-size: 1.375rem;
    margin-bottom: 1.4375rem;
    padding-top: 3.46875rem;
  }
  .studionameandcompany {
    margin-bottom: 1.75rem;
  }

  .studioname > h1 {
    font-size: 0.9375rem;
    margin-bottom: 0.8125rem;
  }
  .studioname > input {
    width: 22.125rem;
    height: 3rem;
    font-size: 0.875rem;
  }

  .companyname > h1 {
    font-size: 0.9375rem;
    margin-bottom: 0.8125rem;
  }
  .companyname > input {
    width: 22.125rem;
    height: 3rem;
    font-size: 0.875rem;
  }

  .portfolioandurl {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4rem;
  }
  .portfoliolink {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .portfoliolink > h1 {
    font-size: 0.9375rem;
    margin-bottom: 0.8125rem;
  }
  .portfoliolink > input {
    width: 22.125rem;
    height: 3rem;
    font-size: 0.875rem;
  }

  .gameurl > h1 {
    font-size: 0.9375rem;
    margin-bottom: 0.8125rem;
  }
  .gameurl > input {
    width: 22.125rem;
    height: 3rem;
    font-size: 0.875rem;
  }

  .submitbutton {
    width: 12.75rem;
    height: 3.375rem;
    font-size: 1.125rem;

    margin-bottom: 0rem;
  }
}
/*15in*/
@media screen and (max-width: 1280px) {
  .container {
    height: 1650px;
  }
  .formcontainer {
    width: 50rem;
    height: 79rem;

    margin-top: 10rem;
    margin-bottom: 10.5625rem;
    padding-bottom: 3.3125rem;
  }
  .title {
    margin-top: 5.0625rem;
    margin-bottom: 3.75rem;
  }
  .title > h1 {
    font-size: 2.125rem;
    margin-bottom: 1.25rem;
  }
  .title > h2 {
    font-size: 1.1875rem;
  }
  .title > h2 > div {
    width: 0.4375rem;
    height: 0.4375rem;
  }
  .subtitle {
    margin-left: 5rem;
    margin-bottom: 1.5rem;
  }
  .subtitle > h1 {
    font-family: "Roboto";
    font-weight: 700;
    font-size: 1.3125rem;
    text-align: start;
  }
  .inputcontainer {
    margin: 0 5rem;
  }
  .namecontainer {
    margin-bottom: 1.875rem;
  }

  .firstname > h1 {
    font-size: 0.9375rem;
    margin-bottom: 0.625rem;
  }

  .firstname > input {
    width: 18.125rem;
    height: 3rem;
    font-size: 0.875rem;
    padding-left: 15px;
  }

  .alertmessage {
    font-size: 0.8rem;
  }

  .lastname > h1 {
    font-size: 0.9375rem;
    margin-bottom: 0.625rem;
  }
  .lastname > input {
    width: 18.125rem;
    height: 3rem;
    font-size: 0.875rem;
    padding-left: 15px;
  }
  .emailandskype {
    margin-bottom: 1.875rem;
  }
  .email > h1 {
    font-size: 0.9375rem;
    margin-bottom: 0.625rem;
  }

  .email > input {
    width: 18.125rem;
    height: 3rem;
    font-size: 0.875rem;
    padding-left: 15px;
  }

  .skypeid > h1 {
    font-size: 0.9375rem;
    margin-bottom: 0.8125rem;
  }

  .skypeid > input {
    width: 18.125rem;
    height: 3rem;
    font-size: 0.875rem;
    padding-left: 15px;
  }
  .tellus {
    margin-bottom: 3.5625rem;
  }
  .tellus > h1 {
    font-size: 0.9375rem;
    margin-bottom: 0.625rem;
  }
  .tellus > textarea {
    width: 38rem;
    height: 10.5625rem;
    font-size: 0.875rem;
    padding-left: 15px;
    padding-top: 15px;
  }

  .teamcontainer > h1 {
    font-size: 1.3125rem;
    margin-bottom: 1.5rem;
    padding-top: 3.375rem;
  }
  .studionameandcompany {
    margin-bottom: 1.875rem;
  }

  .studioname > h1 {
    font-size: 0.9375rem;
    margin-bottom: 0.625rem;
  }
  .studioname > input {
    width: 18.125rem;
    height: 3rem;
    font-size: 0.875rem;
    padding-left: 15px;
  }

  .companyname > h1 {
    font-size: 0.9375rem;
    margin-bottom: 0.625rem;
  }
  .companyname > input {
    width: 18.125rem;
    height: 3rem;
    font-size: 0.875rem;
    padding-left: 15px;
  }

  .portfolioandurl {
    margin-bottom: 4.625rem;
  }

  .portfoliolink > h1 {
    font-size: 0.9375rem;
    margin-bottom: 0.625rem;
  }
  .portfoliolink > input {
    width: 18.125rem;
    height: 3rem;
    font-size: 0.875rem;
    padding-left: 15px;
  }

  .gameurl > h1 {
    font-size: 0.9375rem;
    margin-bottom: 0.625rem;
  }
  .gameurl > input {
    width: 18.125rem;
    height: 3rem;
    font-size: 0.875rem;
    padding-left: 15px;
  }

  .submitbutton {
    font-family: "SpoqaHanSans";
    font-weight: bold;
    width: 11.375rem;
    height: 3.375rem;
    font-size: 0.9375rem;
    border-radius: 4.125rem;
  }
}
/*13in*/
@media screen and (max-width: 1024px) {
  .container {
    height: 104rem;
  }
  .formcontainer {
    width: 49.25rem;
    height: 85rem;

    margin-top: 9.0625rem;
    margin-bottom: 9.375rem;
    padding-bottom: 0rem;
  }
  .title {
    margin-top: 3.75rem;
    margin-bottom: 4.25rem;
  }
  .title > h1 {
    font-size: 2.125rem;
    margin-bottom: 2.125rem;
  }
  .title > h2 {
    font-size: 1.1875rem;
  }
  .title > h2 > div {
    width: 0.4375rem;
    height: 0.4375rem;
  }
  .subtitle {
    margin-left: 4.1875rem;
    margin-bottom: 1.5625rem;
  }
  .subtitle > h1 {
    font-size: 1.375rem;
  }
  .inputcontainer {
    margin: 0 4.1875rem;
  }
  .namecontainer {
    margin-bottom: 2rem;
  }

  .firstname > h1 {
    font-size: 0.9375rem;
    margin-bottom: 0.75rem;
  }

  .firstname > input {
    width: 18.5rem;
    height: 3.1875rem;
    font-size: 0.875rem;
  }

  .firstalertmessage,
  .lastalertmessage,
  .eamilalertmessage {
    font-size: 0.8rem;
  }

  .lastname > h1 {
    font-size: 0.9375rem;
    margin-bottom: 0.75rem;
  }
  .lastname > input {
    width: 18.5rem;
    height: 3.1875rem;
    font-size: 0.875rem;
  }
  .emailandskype {
    margin-bottom: 2rem;
  }
  .email > h1 {
    font-size: 0.9375rem;
    margin-bottom: 0.75rem;
  }

  .email > input {
    width: 18.5rem;
    height: 3.1875rem;
    font-size: 0.875rem;
  }

  .skypeid > h1 {
    font-size: 0.9375rem;
    margin-bottom: 0.75rem;
  }

  .skypeid > input {
    width: 18.5rem;
    height: 3.1875rem;
    font-size: 0.875rem;
  }
  .tellus {
    margin-bottom: 3.6875rem;
  }
  .tellus > h1 {
    font-size: 0.9375rem;
    margin-bottom: 0.75rem;
  }
  .tellus > textarea {
    width: 39rem;
    height: 10.9375rem;
    font-size: 0.875rem;
    padding-left: 15px;
    padding-top: 15px;
  }

  .teamcontainer > h1 {
    font-size: 1.375rem;
    margin-bottom: 1.6875rem;
    padding-top: 3.6875rem;
  }
  .studionameandcompany {
    margin-bottom: 2rem;
  }

  .studioname > h1 {
    font-size: 0.9375rem;
    margin-bottom: 0.75rem;
  }
  .studioname > input {
    width: 18.5rem;
    height: 3.1875rem;
    font-size: 0.875rem;
  }

  .companyname > h1 {
    font-size: 0.9375rem;
    margin-bottom: 0.75rem;
  }
  .companyname > input {
    width: 18.5rem;
    height: 3.1875rem;
    font-size: 0.875rem;
  }

  .portfolioandurl {
    margin-bottom: 3.3125rem;
  }

  .portfoliolink > h1 {
    font-size: 0.9375rem;
    margin-bottom: 0.75rem;
  }
  .portfoliolink > input {
    width: 18.5rem;
    height: 3.1875rem;
    font-size: 0.875rem;
  }

  .gameurl > h1 {
    font-size: 0.9375rem;
    margin-bottom: 0.75rem;
  }
  .gameurl > input {
    width: 18.5rem;
    height: 3.1875rem;
    font-size: 0.875rem;
  }

  .submitbutton {
    width: 11.625rem;
    height: 3.5rem;
    font-size: 0.9375rem;
    border-radius: 4.0625rem;
  }
}
/*tablet*/
@media all and (max-width: 768px) {
  .container {
    height: 265vw;
  }
  .formcontainer {
    width: 87vw;
    height: 220vw;

    margin-bottom: 17.1vw;
    margin-top: 17.1vw;
    padding-bottom: 9.5vw;
    border-radius: 9.5vw;
  }
  .title {
    margin-top: 8.3vw;
    margin-bottom: 7.3vw;
  }
  .title > h1 {
    font-size: 4.4vw;
    margin-bottom: 1.4vw;
  }
  .title > h2 {
    font-size: 2.5vw;
  }
  .title > h2 > div {
    width: 1vw;
    height: 1vw;
  }
  .subtitle {
    margin-left: 7vw;
    margin-bottom: 3.3vw;
  }
  .subtitle > h1 {
    font-size: 2.9vw;
  }
  .inputcontainer {
    height: 78%;
    margin: 0 7vw;
    margin-bottom: 8.6vw;
  }
  .namecontainer {
    margin-bottom: 2.9vw;
    flex-direction: column;
  }

  .firstname > h1 {
    font-size: 2.1vw;
    margin-bottom: 1.6vw;
  }

  .firstname > input {
    width: 71.5vw;
    height: 6.6vw;
    font-size: 1.8vw;
  }

  .firstalertmessage,
  .lastalertmessage,
  .eamilalertmessage {
    font-size: 1.5vw;
  }

  .lastname > h1 {
    font-size: 2.1vw;
    margin-bottom: 1.6vw;
  }
  .lastname > input {
    width: 71.5vw;
    height: 6.6vw;
    font-size: 1.8vw;
  }
  .emailandskype {
    margin-bottom: 2.9vw;
    flex-direction: column;
  }
  .email > h1 {
    font-size: 2.1vw;
    margin-bottom: 1.6vw;
  }

  .email > input {
    width: 71.5vw;
    height: 6.6vw;
    font-size: 1.8vw;
  }

  .skypeid > h1 {
    font-size: 2.1vw;
    margin-bottom: 1.6vw;
  }

  .skypeid > input {
    width: 71.5vw;
    height: 6.6vw;
    font-size: 1.8vw;
  }
  .tellus {
    margin-bottom: 7.3vw;
  }
  .tellus > h1 {
    font-size: 2.1vw;
    margin-bottom: 1.6vw;
  }
  .tellus > textarea {
    width: 69vw;
    height: 28vw;
    font-size: 1.8vw;
  }

  .teamcontainer > h1 {
    font-size: 2.9vw;
    margin-bottom: 3.3vw;
    padding-top: 7.3vw;
  }
  .studionameandcompany {
    margin-bottom: 2.6vw;
    flex-direction: column;
  }

  .studioname > h1 {
    font-size: 2.1vw;
    margin-bottom: 1.6vw;
  }
  .studioname > input {
    width: 71.5vw;
    height: 6.6vw;
    font-size: 1.8vw;
  }

  .companyname > h1 {
    font-size: 2.1vw;
    margin-bottom: 1.6vw;
  }
  .companyname > input {
    width: 71.5vw;
    height: 6.6vw;
    font-size: 1.8vw;
    margin-bottom: 0;
  }

  .portfolioandurl {
    margin-bottom: 8.6vw;
    flex-direction: column;
  }

  .portfoliolink > h1 {
    font-size: 2.1vw;
    margin-bottom: 1.6vw;
  }
  .portfoliolink > input {
    width: 71.5vw;
    height: 6.6vw;
    font-size: 1.8vw;
  }

  .gameurl > h1 {
    font-size: 2.1vw;
    margin-bottom: 1.6vw;
  }
  .gameurl > input {
    width: 71.5vw;
    height: 6.6vw;
    font-size: 1.8vw;
  }

  .submitbutton {
    width: 28.4vw;
    height: 7.6vw;
    font-size: 2.3vw;
  }
}
/*mobile*/
@media all and (max-width: 480px) {
  .container {
    height: 401.5vw;
    align-items: center;
  }
  .formcontainer {
    width: 91.7vw;
    height: 350vw;

    margin-bottom: 0vw;
    margin-top: 0vw;
    padding-bottom: 13.3vw;
    border-radius: 3.3vw;
  }
  .title {
    margin-top: 13.3vw;
    margin-bottom: 11.3vw;
  }
  .title > h1 {
    font-size: 5.8vw;
    margin-bottom: 2.7vw;
  }
  .title > h2 {
    font-size: 4vw;
  }
  .title > h2 > div {
    width: 1.6vw;
    height: 1.6vw;
    margin-left: 5.4vw;
    margin-right: 5.4vw;
  }
  .subtitle {
    margin-left: 9.2vw;
    margin-bottom: 4.2vw;
  }
  .subtitle > h1 {
    font-size: 4.6vw;
  }
  .inputcontainer {
    height: auto;
    margin-left: 9.2vw;
    margin-bottom: 0vw;
  }
  .namecontainer {
    margin-bottom: 5.8vw;
    flex-direction: column;
  }

  .firstname > h1 {
    font-size: 3.3vw;
    margin-bottom: 1.7vw;
  }

  .firstname > input {
    width: 71vw;
    height: 10.8vw;
    font-size: 3.3vw;
  }

  .firstalertmessage,
  .lastalertmessage,
  .eamilalertmessage {
    font-size: 1.5vw;
  }

  .lastname > h1 {
    margin-top: 5.8vw;
    font-size: 3.3vw;
    margin-bottom: 1.7vw;
  }
  .lastname > input {
    width: 71vw;
    height: 10.8vw;
    font-size: 3.3vw;
  }
  .emailandskype {
    margin-bottom: 5.8vw;
    flex-direction: column;
  }
  .email > h1 {
    font-size: 3.3vw;
    margin-bottom: 1.7vw;
  }

  .email > input {
    width: 71vw;
    height: 10.8vw;
    font-size: 3.3vw;
  }

  .skypeid > h1 {
    margin-top: 5.8vw;
    font-size: 3.3vw;
    margin-bottom: 1.7vw;
  }

  .skypeid > input {
    width: 71vw;
    height: 10.8vw;
    font-size: 3.3vw;
  }
  .tellus {
    margin-bottom: 7.3vw;
  }
  .tellus > h1 {
    font-size: 3.3vw;
    margin-bottom: 1.7vw;
  }
  .tellus > textarea {
    width: 67.5vw;
    height: 44.8vw;
    font-size: 3.3vw;
  }

  .teamcontainer > h1 {
    font-size: 4.6vw;
    margin-bottom: 4.2vw;
    padding-top: 5.8vw;
  }
  .studionameandcompany {
    margin-bottom: 5.8vw;
    flex-direction: column;
  }

  .studioname > h1 {
    font-size: 3.3vw;
    margin-bottom: 1.7vw;
  }
  .studioname > input {
    width: 71vw;
    height: 10.8vw;
    font-size: 3.3vw;
    margin-bottom: 0;
  }

  .companyname > h1 {
    margin-top: 5.8vw;
    font-size: 3.3vw;
    margin-bottom: 1.7vw;
  }
  .companyname > input {
    width: 71vw;
    height: 10.8vw;
    font-size: 3.3vw;
  }

  .portfolioandurl {
    margin-bottom: 8.6vw;
    flex-direction: column;
  }

  .portfoliolink > h1 {
    font-size: 3.3vw;
    margin-bottom: 1.7vw;
  }
  .portfoliolink > input {
    width: 71vw;
    height: 10.8vw;
    font-size: 3.3vw;
    margin-bottom: 0;
  }

  .gameurl > h1 {
    margin-top: 5.8vw;
    font-size: 3.3vw;
    margin-bottom: 1.7vw;
  }
  .gameurl > input {
    width: 71vw;
    height: 10.8vw;
    font-size: 3.3vw;
  }

  .submitbutton {
    width: 45.4vw;
    height: 12.1vw;
    font-size: 3.8vw;
  }
}
