.button {
  position: fixed;
  top: 80%;
  z-index: 10;
  background: white;
  width: 2.6vw;
  min-width: 50px;
  height: 50px;
  min-height: 2.6vw;

  right: 5%;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  font-weight: bold;
  box-shadow: 0px 10px 15px rgba(26, 26, 26, 0.2);
  color: rgba(219, 49, 49, 1);
}
.toparrow {
  margin-top: 3px;
}
.button:hover {
  cursor: pointer;
}
.containter {
  height: 100%;
  background-color: #f4f1ef;
  font-family: "Roboto";
  flex: 1;
  padding-bottom: 10vw;
}

.background {
  position: relative;
  height: 35.68vw;
  margin-left: 9.22vw;
  margin-right: 9.22vw;
}
.toptext {
  /* width: 100%; */
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  border-bottom: 0.125rem solid white;
  z-index: 1;
  position: relative;
}
.toptext > h1 {
  color: #5a3863;
  font-size: 3.13vw;
  margin-bottom: 1.67vw;
  font-weight: 700;
  z-index: 1;
  letter-spacing: -0.08vw;
}

.firstliterature,
.secondliterature {
  color: #191919;
  font-size: 1.2vw;
  font-weight: 400;
  z-index: 1;
  width: 40vw;
  text-align: start;
  line-height: 2rem;
}

.circle1 {
  position: absolute;
  width: 16.04vw;
  height: 16.04vw;
  background-color: #d8e4ff;
  border-radius: 100rem;
  top: 22.86vw;
  left: -17vw;
  z-index: 0;
}
.circle2 {
  position: absolute;
  width: 23.54vw;
  height: 23.54vw;
  background-color: #e5edff;
  border-radius: 100rem;
  top: 2.24vw;
  left: 17.24vw;
}
.circle3 {
  position: absolute;
  width: 41.88vw;
  height: 41.88vw;
  background-color: #d8e4ff;
  border-radius: 100rem;
  z-index: 0;
  top: 6.93vw;
  right: 0;
}
.topImg {
  position: absolute;
  width: 38.07vw;
  height: 27.86vw;
  top: 3.65vw;
  right: 0;
}
.contentscontainer {
  display: flex;
  margin-left: 9.22vw;
  margin-top: 5.83vw;
}

.dropdown {
  display: none;
}
.listcontainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.sidebar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 5vw;
}
.sidebar > span {
  font-size: 1.5625rem;
  color: #333335;
  font-weight: 700;
  margin-bottom: 2.5rem;
}
.list {
  font-size: 1.375rem;
  color: #333335;
  font-weight: 500;
  margin-bottom: 1.25rem;
  z-index: 1;
  list-style: none;
}
.list:hover {
  color: #f02e2e;
  cursor: pointer;
}
.selectbox {
  display: none;
}
.cardcontainer {
  display: grid;
  grid-template-columns: repeat(3, 22vw);

  column-gap: 2vw;
  row-gap: 2vw;
}
.cardcontainer a {
  text-decoration: none;
  z-index: 1;
}
.card {
  width: 22vw;
  height: 28vw;
  background-color: white;
  border-radius: 0.5625rem;
  box-shadow: 0px 3px 6px #00000029;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.card > div > img {
  width: 22vw;
  height: 14vw;
  border-top-left-radius: 0.5625rem;
  border-top-right-radius: 0.5625rem;
}
.tag {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
  padding: 0.31vw 0.52vw;
  border: solid 2px #da392b;
  color: #da392b;
  border-radius: 1.4375rem;
  font-weight: 700;
  margin-left: 1.82vw;
  margin-top: 1.3vw;
}
.title {
  width: 18vw;
  font-size: 1.35vw;
  text-align: start;
  font-weight: 700;
  margin-left: 1.82vw;
  margin-top: 0.52vw;
  margin-bottom: 1.04vw;
  color: #191919;
}
.context {
  width: 18vw;
  font-size: 1.04vw;
  color: #646464;
  text-align: start;
  margin-left: 1.82vw;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media screen and (max-width: 1440px) {
  .background {
    height: 44.1vw;
    margin-left: 12.29vw;
    margin-right: 12.29vw;
  }
  .toptext > h1 {
    width: 40.83vw;
    font-size: 4.17vw;
    margin-bottom: 2.08vw;
    text-align: start;
  }

  .firstliterature,
  .secondliterature {
    font-size: 1.6vw;
    font-weight: 400;
    width: 40.83vw;
    text-align: start;
  }
  .circle1 {
    position: absolute;
    width: 17.29vw;
    height: 17.29vw;
    top: 30.28vw;
    left: -21vw;
  }
  .circle2 {
    position: absolute;
    width: 25.35vw;
    height: 25.35vw;

    top: -2vw;
    left: 7vw;
  }
  .circle3 {
    position: absolute;
    width: 45.07vw;
    height: 45.07vw;
    background-color: #d8e4ff;
    border-radius: 100rem;
    z-index: 0;
    top: 3.68vw;
    right: -5vw;
  }
  .topImg {
    position: absolute;
    width: 41.74vw;
    height: 32.29vw;
    top: 5.7vw;
    right: -1vw;
  }
  .contentscontainer {
    display: flex;
    margin-left: 12.29vw;
    margin-top: 7.78vw;
  }
  .sidebar {
    margin-right: 5vw;
  }
  .sidebar > span {
    font-size: 2.08vw;
    color: #333335;
    font-weight: 700;
    margin-bottom: 2.08vw;
  }
  .list {
    font-size: 1.25vw;
    margin-bottom: 1.04vw;
  }
  .cardcontainer {
    grid-template-columns: repeat(2, 30.21vw);
    grid-template-rows: repeat(auto, 38.47vw);
    column-gap: 2.08vw;
    row-gap: 2.78vw;
  }

  .card {
    width: 30.21vw;
    height: 38.47vw;
  }
  .card > div > img {
    width: 30.21vw;
    height: 19.24vw;
  }
  .tag {
    width: auto;
    height: auto;
    border-radius: 1.6vw;
    padding: 0.42vw 0.69vw;
    margin-left: 2.29vw;
    margin-top: 1.81vw;
    font-size: 1.04vw;
  }
  .title {
    width: 25.63vw;
    font-size: 1.53vw;
    text-align: start;
    margin-left: 2.29vw;
    margin-top: 1.18vw;
    margin-bottom: 2.78vw;
  }
  .context {
    width: 25.63vw;
    font-size: 1.25vw;
    color: #646464;
    margin-left: 2.29vw;
  }
}
@media screen and (max-width: 1280px) {
  .background {
    height: 41.56vw;
    margin-left: 13.67vw;
    margin-right: 13.67vw;
  }
  .toptext > h1 {
    font-size: 3.91vw;
    margin-bottom: 1.56vw;
  }

  .firstliterature,
  .secondliterature {
    font-size: 1.41vw;
  }
  .circle1 {
    position: absolute;
    width: 14.69vw;
    height: 14.69vw;
    top: 29.61vw;
    left: -21vw;
  }
  .circle2 {
    position: absolute;
    width: 21.41vw;
    height: 21.41vw;

    top: 1.64vw;
    left: 6.5vw;
  }
  .circle3 {
    position: absolute;
    width: 42.34vw;
    height: 42.34vw;
    background-color: #d8e4ff;
    border-radius: 100rem;
    z-index: 0;
    top: 6.09vw;
    right: -5vw;
  }
  .topImg {
    position: absolute;
    width: 41.17vw;
    height: 31.88vw;
    top: 6.33vw;
    right: -4vw;
  }
  .contentscontainer {
    display: flex;
    margin-left: 13.67vw;
    margin-top: 3.91vw;
  }
  .sidebar {
    width: 13.75vw;
    margin-right: 7.34vw;
  }
  .sidebar > span {
    font-size: 2.03vw;
    margin-bottom: 2.73vw;
  }
  .list {
    font-size: 1.25vw;
    margin-bottom: 1.17vw;
  }
  .cardcontainer {
    grid-template-columns: repeat(2, 23.44vw);
    grid-template-rows: repeat(auto, 29.45vw);
    column-gap: 2.42vw;
    row-gap: 2.42vw;
  }

  .card {
    width: 23.44vw;
    height: 29.45vw;
  }
  .card > div > img {
    width: 23.44vw;
    height: 14.3vw;
  }
  .tag {
    width: auto;
    height: auto;
    border-radius: 1.8vw;
    padding: 0.47vw 0.78vw;
    margin-left: 1.72vw;
    margin-top: 1.33vw;
    font-size: 1.02vw;
  }
  .title {
    width: 19.84vw;
    font-size: 1.41vw;
    margin-left: 1.72vw;
    margin-top: 0.86vw;
    margin-bottom: 1.33vw;
  }
  .context {
    width: 19.84vw;
    font-size: 1.17vw;
    margin-left: 1.72vw;
  }
}
@media screen and (max-width: 1024px) {
  .background {
    height: 51.27vw;
    margin-left: 11.52vw;
    margin-right: 11.52vw;
  }

  .toptext > h1 {
    width: 40vw;
    font-size: 5.08vw;
    margin-bottom: 2.93vw;
    line-height: 6.05vw;
  }

  .firstliterature,
  .secondliterature {
    width: 44.24vw;
    font-size: 1.95vw;
    margin-bottom: 1vw;
  }
  .circle1 {
    position: absolute;
    width: 12.21vw;
    height: 12.21vw;
    top: 36.82vw;
    left: -18vw;
  }
  .circle2 {
    position: absolute;
    width: 19.24vw;
    height: 19.24vw;

    top: 3.52vw;
    left: 6.5vw;
  }
  .circle3 {
    position: absolute;
    width: 40.53vw;
    height: 40.53vw;
    background-color: #d8e4ff;
    border-radius: 100rem;
    z-index: 0;
    top: 18.46vw;
    right: -5vw;
  }
  .topImg {
    position: absolute;
    width: 45.8vw;
    height: 35.55vw;
    top: 9.18vw;
    right: -6.5vw;
  }
  .contentscontainer {
    margin-left: 11.52vw;
    margin-top: 4.59vw;
  }
  .sidebar {
    margin-right: 4vw;
  }
  .sidebar > span {
    font-size: 2.54vw;
    margin-bottom: 3.42vw;
  }
  .list {
    font-size: 1.56vw;
    margin-bottom: 1.46vw;
  }
  .cardcontainer {
    grid-template-columns: repeat(2, 27.93vw);
    grid-template-rows: repeat(auto, 39.45vw);
    column-gap: 1.86vw;
    row-gap: 2.93vw;
  }

  .card {
    width: 27.93vw;
    height: 39.45vw;
  }
  .card > div > img {
    width: 27.93vw;
    height: 20.31vw;
  }
  .tag {
    width: auto;
    height: auto;
    padding: 0.59vw 0.98vw;
    border-radius: 2.25vw;

    margin-left: 2.44vw;
    margin-top: 1.95vw;
    font-size: 1.27vw;
  }
  .title {
    width: 22.95vw;
    font-size: 1.76vw;
    margin-left: 2.44vw;
    margin-top: 1.17vw;
    margin-bottom: 1.95vw;
  }
  .context {
    width: 22.95vw;
    font-size: 1.46vw;
    margin-left: 2.44vw;
  }
}

@media screen and (max-width: 768px) {
  .button {
    display: none;
  }

  .background {
    height: 86vw;
    width: 73.05vw;
    margin-left: 13.54vw;
    margin-right: 13.54vw;
  }
  .toptext {
    margin-left: 0vw;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 7.55vw;
  }
  .toptext > h1 {
    display: flex;
    font-size: 6.77vw;
    margin-bottom: 3.91vw;
    width: 73.05vw;
    justify-content: center;
  }

  .firstliterature,
  .secondliterature {
    display: flex;
    width: 65vw;
    font-size: 2.21vw;
    justify-content: center;
    text-align: center;
    line-height: 3.65vw;
  }
  .circle1 {
    display: none;
  }
  .circle2 {
    width: 25.65vw;
    height: 25.65vw;
    top: 4.69vw;
    left: 2vw;
  }
  .circle3 {
    width: 54.04vw;
    height: 54.04vw;
    top: 10.29vw;
    right: 10vw;
  }
  .topImg {
    width: 66.02vw;
    height: 51.04vw;
    top: 5.34vw;
    right: 5vw;
  }
  .contentscontainer {
    display: flex;
    margin-left: 13.54vw;
    margin-top: 14vw;
    flex-direction: column;
    position: relative;
  }
  .sidebar > span {
    font-size: 3.39vw;
    margin-bottom: 2.6vw;
  }
  .dropdown {
    display: inline;
    width: 21vw;
    height: 4.95vw;
    border: 1px solid #bfbfbf;
    border-radius: 0.78vw;
    font-size: 2.08vw;
    font-weight: 500;
    text-align: start;
    padding-left: 1.69vw;
    background-color: #ffffff;
    margin-bottom: 3.91vw;
    position: relative;
  }
  .arrow {
    position: absolute;
    top: 2.21vw;
    right: 1.82vw;
  }
  .listcontainer {
    display: none;
  }
  .listcontainer.active {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    top: 12.11vw;
    position: absolute;
    width: 26.95vw;
    height: 20vw;
    background-color: #ffffff;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 0.78vw;
    padding-top: 2.73vw;
    padding-bottom: 2.73vw;
    z-index: 2;
  }
  .list {
    font-size: 2.34vw;
    margin-left: 3.91vw;
    margin-bottom: 0;
    font-weight: 400;
  }
  .cardcontainer {
    grid-template-columns: repeat(2, 35.42vw);
    grid-template-rows: repeat(auto, 55.21vw);
    column-gap: 2.08vw;
    row-gap: 2.73vw;
  }
  .card {
    width: 35.42vw;
    height: 55.21vw;
  }
  .card > div > img {
    width: 35.42vw;
    height: 24vw;
  }
  .tag {
    width: auto;
    height: auto;
    padding: 0.78vw 1.3vw;
    border-radius: 2.99vw;
    margin-left: 2.86vw;
    margin-top: 2.73vw;
    font-size: 1.69vw;
  }
  .title {
    width: 29.69vw;
    font-size: 2.34vw;
    margin-left: 2.86vw;
    margin-top: 2.21vw;
    margin-bottom: 1.69vw;
  }
  .context {
    width: 29.69vw;
    font-size: 1.95vw;
    margin-left: 2.86vw;
  }
}
@media screen and (max-width: 480px) {
  .background {
    height: 140.21vw;
  }
  .toptext {
    margin-left: 0vw;
    align-items: center;
    padding-bottom: 3vw;
  }
  .toptext > h1 {
    font-size: 10.83vw;

    margin-bottom: 6.25vw;
    width: 83.13vw;
    text-align: center;
    line-height: 12vw;
  }

  .firstliterature,
  .secondliterature {
    font-size: 3.75vw;
    width: 83.13vw;
    margin-bottom: 3vw;
    line-height: 5.83vw;
  }
  .circle2 {
    width: 32.5vw;
    height: 32.5vw;
    top: 10.21vw;
    left: -6vw;
  }
  .circle3 {
    width: 68.33vw;
    height: 68.33vw;

    top: 17.29vw;
    right: 0vw;
  }
  .topImg {
    width: 83.13vw;
    height: 64.38vw;
    top: 11.04vw;
    right: -3vw;
  }
  .contentscontainer {
    display: flex;
    margin-left: 8.33vw;
    margin-top: 9.58vw;
    flex-direction: column;
    position: relative;
  }
  .sidebar > span {
    font-size: 5.42vw;
    margin-bottom: 4.17vw;
  }
  .dropdown {
    display: inline;
    width: 32.29vw;
    height: 7.92vw;
    border: 1px solid #bfbfbf;
    border-radius: 1.25vw;
    font-size: 3.33vw;
    font-weight: 500;
    text-align: start;
    padding-left: 1.69vw;
    background-color: #ffffff;
    margin-bottom: 3.91vw;
    position: relative;
  }
  .arrow {
    position: absolute;
    top: 3.54vw;
    right: 2.92vw;
  }
  .listcontainer {
    display: none;
  }
  .listcontainer.active {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    top: 19.38vw;
    position: absolute;
    width: 43.13vw;
    height: 35vw;
    background-color: #ffffff;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 1.25vw;
    padding-top: 4.38vw;
    padding-bottom: 4.38vw;
  }
  .list {
    font-size: 3.75vw;
    margin-left: 6.25vw;
    margin-bottom: 0;
    font-weight: 400;
  }
  .cardcontainer {
    grid-template-columns: repeat(1, 83.33vw);
    grid-template-rows: repeat(auto, 88.33vw);
    column-gap: 0vw;
    row-gap: 6.25vw;
  }
  .card {
    width: 83.33vw;
    height: 88.33vw;
  }
  .card > div > img {
    width: 83.33vw;
    height: 43.33vw;
  }
  .tag {
    width: auto;
    height: auto;
    padding: 1.25vw 2.08vw;
    border-radius: 4.79vw;
    margin-left: 7.29vw;
    margin-top: 4.38vw;
    font-size: 2.71vw;
  }
  .title {
    width: 68.75vw;
    font-size: 3.75vw;
    margin-left: 7.29vw;
    margin-top: 2.71vw;
    margin-bottom: 6.25vw;
  }
  .context {
    width: 68.75vw;
    font-size: 3.13vw;
    margin-left: 7.29vw;
  }
}
