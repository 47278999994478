.container {
  background-color: #141414;

  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}
.button {
  position: fixed;
  top: 80%;
  z-index: 10;
  background: white;
  width: 2.6vw;
  min-width: 50px;
  height: 50px;
  min-height: 2.6vw;

  right: 5%;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  font-weight: bold;
  box-shadow: 0px 10px 15px rgba(26, 26, 26, 0.2);
  color: rgba(219, 49, 49, 1);
}
.toparrow {
  margin-top: 3px;
}
.button:hover {
  cursor: pointer;
}
.header {
  display: flex;
  width: 81.56vw;
  height: 13.125rem;
  font-family: "Roboto";
  font-weight: 700;
  font-size: 4.25rem;
  color: #ffffff;

  border-bottom: 1px solid #707070;
  align-items: flex-end;
  margin-bottom: 4.8125rem;
  padding-bottom: 0.9375rem;
}
.gamecontainer {
  height: 100%;
  width: 81.56vw;
  display: grid;
  grid-template-columns: repeat(4, 18.91vw);
  grid-template-rows: repeat(auto-fill, 18.91vw);
  column-gap: 1.98vw;
  row-gap: 2.08vw;
  justify-content: center;
  flex: 1;

  padding-bottom: 10vw;
}
.gamecontainer > div {
  display: inline-block;
  vertical-align: top;
}
.gamecontainer > div > div {
  display: block;
  width: auto;
  text-decoration: none;
}
.game {
  position: relative;
  overflow: hidden;
}
.game:hover {
  cursor: pointer;
}
.game::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 0;
  opacity: 0;
  transition: all 0.3s;
  border-radius: 2.08vw;
}
.top {
  position: absolute;
  bottom: 150%;
  z-index: 1;
  color: white;
  font-size: 2.29vw;
  transition: all 0.3s;
  font-family: "Roboto";
  font-weight: 700;
  width: 100%;
}
.bottom {
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  top: 150%;
  left: 0;
  z-index: 1;
  color: white;
  font-size: 26px;
  transition: all 0.3s;
}
.img {
  width: 18.91vw;
  height: 18.91vw;
  border-radius: 2.08vw;
}

.appstore {
  width: 7.71vw;
  height: 2.29vw;
  margin-right: 0.55vw;
}
.googlestore {
  width: 7.71vw;
  height: 2.29vw;
}

.gamecontainer > div:hover .game::after {
  opacity: 1;
}

.gamecontainer > div > div:hover .top {
  bottom: 8.75vw;
}
.gamecontainer > div > div:hover .bottom {
  top: 13.96vw;
}

@media all and (max-width: 1440px) {
  .header {
    width: 75.42vw;
    height: 13.6875rem;
    font-size: 3.25rem;
    padding-bottom: 1.125rem;
    margin-bottom: 3.5625rem;
  }
  .gamecontainer {
    width: 75.42vw;
    column-gap: 1.81vw;
    row-gap: 2.08vw;
    grid-template-columns: repeat(4, 17.5vw);
    grid-template-rows: repeat(auto-fill, 17.5vw);
  }
  .top {
    font-size: 2.08vw;
  }
  .img {
    width: 17.5vw;
    height: 17.5vw;
    border-radius: 3.13vw;
  }
  .appstore {
    width: 7.15vw;
    height: 2.15vw;
    margin-right: 0.5vw;
  }
  .googlestore {
    width: 7.15vw;
    height: 2.15vw;
  }
  .game::after {
    border-radius: 3.13vw;
  }
  .gamecontainer > div > div:hover .top {
    bottom: 8.19vw;
  }
  .gamecontainer > div > div:hover .bottom {
    top: 12.85vw;
  }
}
@media all and (max-width: 1280px) {
  .container {
    min-height: 0;
  }
  .header {
    width: 72.34vw;

    height: 3.5rem;
    margin-top: 8.1875rem;
    padding-bottom: 0.625rem;
  }
  .gamecontainer {
    width: 75.42vw;
    grid-template-columns: repeat(4, 16.41vw);
    grid-template-rows: repeat(auto-fill, 16.41vw);
    column-gap: 1.81vw;
    row-gap: 2.08vw;
    column-gap: 2.34vw;
    row-gap: 2.34vw;
  }
  .top {
    font-size: 1.88vw;
  }
  .img {
    width: 16.41vw;
    height: 16.41vw;
    border-radius: 3.13vw;
  }
  .game::after {
    border-radius: 3.13vw;
  }
  .bottom {
    width: 8.59vw;
    position: absolute;
    flex-direction: column;
    top: 150%;
    left: 4vw;
    z-index: 1;
    color: white;
    transition: all 0.3s;
    margin-top: 1.33vw;
  }
  .appstore {
    width: 8.59vw;
    height: 2.58vw;
    margin-right: 0;
    margin-bottom: 0.39vw;
  }
  .googlestore {
    width: 8.59vw;
    height: 2.58vw;
  }
  .gamecontainer > div > div:hover .top {
    bottom: 10.08vw;
  }
  .gamecontainer > div > div:hover .bottom {
    top: 7vw;
  }
}
@media all and (max-width: 1024px) {
  .header {
    width: 76.95vw;
    height: 12.375rem;
    margin-top: 0rem;
    padding-bottom: 0.625rem;
    font-size: 3.0625rem;
  }
  .gamecontainer {
    width: 75.42vw;
    column-gap: 1.56vw;
    row-gap: 1.56vw;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(auto-fill, 18.07vw);
  }
  .top {
    font-size: 2.54vw;
  }
  .img {
    width: 18.07vw;
    height: 18.07vw;
    border-radius: 3.13vw;
  }
  .game::after {
    border-radius: 3.13vw;
  }
  .bottom {
    width: 11.23vw;
    position: absolute;
    flex-direction: column;

    top: 150%;
    left: 3.4vw;
    z-index: 1;
    color: white;
    font-size: 26px;
    transition: all 0.3s;
    margin-top: 0vw;
  }
  .appstore {
    width: 11.23vw;
    height: 3.32vw;
    margin-right: 0;
    margin-bottom: 0.3vw;
  }
  .googlestore {
    width: 11.23vw;
    height: 3.32vw;
  }
  .gamecontainer > div > div:hover .top {
    bottom: 12.01vw;
  }
  .gamecontainer > div > div:hover .bottom {
    top: 8.5vw;
  }
}
@media all and (max-width: 768px) {
  .container {
    min-height: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 0vw;
  }
  .button {
    display: none;
  }
  .header {
    width: 72.9vw;
    height: 7.1875rem;
    margin-top: 0rem;
    padding-bottom: 0.625rem;
    font-size: 3.0625rem;
    margin-bottom: 5.2vw;
  }
  .gamecontainer {
    width: 72.9vw;
    column-gap: 2.1vw;
    row-gap: 2.1vw;
    grid-template-columns: repeat(2, 35.4vw);
    grid-template-rows: repeat(auto-fill, 35.4vw);
    padding-bottom: 5vw;
  }
  .top {
    font-size: 4.9vw;
  }
  .img {
    width: 35.4vw;
    height: 35.4vw;
    border-radius: 3.13vw;
  }
  .game::after {
    border-radius: 3.13vw;
  }
  .bottom {
    width: 22.1vw;
    position: absolute;
    flex-direction: column;
    top: 150%;
    left: 6.6vw;
    z-index: 1;
    color: white;
    transition: all 0.3s;
    margin-top: 0vw;
  }
  .appstore {
    width: 22.1vw;
    height: 6.5vw;
    margin-right: 0;
    margin-bottom: 1vw;
  }
  .googlestore {
    width: 22.1vw;
    height: 6.5vw;
  }
  .gamecontainer > div > div:hover .top {
    bottom: 23.6vw;
  }
  .gamecontainer > div > div:hover .bottom {
    top: 16.3vw;
  }
}
@media all and (max-width: 480px) {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 0vw;
  }
  .header {
    width: 83.3vw;
    height: 24vw;
    margin-top: 0rem;
    padding-bottom: 2.9vw;
    font-size: 7.1vw;
    margin-bottom: 5.2vw;
  }
  .gamecontainer {
    width: 83.3vw;
    height: 100%;
    row-gap: 6.3vw;
    margin-left: 0vw;
    margin-right: 0;
    grid-template-columns: repeat(1, 83.3vw);
    grid-template-rows: repeat(auto-fill, 83.3vw);
  }
  .top {
    font-size: 11.5vw;
  }
  .img {
    width: 83.3vw;
    height: 83.3vw;
    border-radius: 8.3vw;
  }
  .game::after {
    border-radius: 8.3vw;
  }
  .bottom {
    width: 39vw;
    position: absolute;
    flex-direction: column;
    top: 150%;
    left: 22.3vw;
    z-index: 1;
    color: white;
    transition: all 0.3s;
    margin-top: 0vw;
  }
  .appstore {
    justify-self: center;
    width: 39vw;
    height: 11.5vw;
    margin-right: 0;
    margin-bottom: 4.6vw;
  }
  .googlestore {
    width: 39vw;
    height: 11.5vw;
  }
  .gamecontainer > div > div:hover .top {
    bottom: 48.1vw;
  }
  .gamecontainer > div > div:hover .bottom {
    top: 43.5vw;
  }
}
