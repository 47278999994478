//article 폰트
@mixin font($size, $weight, $color) {
  font-family: "Poppins";
  font-size: $size;
  font-weight: $weight;
  color: $color;
}
@mixin KR_font_bold($size, $weight, $color) {
  font-family: "Pretendard-700";
  font-size: $size;
  font-weight: $weight;
  color: $color;
}
@mixin KR_font_regular($size, $weight, $color) {
  font-family: "Pretendard-400";
  font-size: $size;
  font-weight: $weight;
  color: $color;
  @content;
}
//썸네일
@mixin thumbnail_1440px_over {
  width: 712px;
  height: 400px;
  margin-bottom: 80px;
  border-radius: 16px;
}
@mixin thumbnail_between_1440px_and_769px {
  width: 712px;
  height: 400px;
  margin-bottom: 80px;
  border-radius: 16px;
}
@mixin thumbnail_between_768px_and_481px {
  width: 92.71vw;
  height: 52.08vw;
  margin-bottom: 10.42vw;
  border-radius: 2.08vw;
}
@mixin thumbnail_480px_under {
  width: 100vw;
  height: 56.25vw;
  margin-bottom: 11.04vw;
  border-radius: 0vw;
}

//타이틀
@mixin title_1440px_over {
  @include font(36px, 700, #262626);
  width: 644px;
}
@mixin title_between_1440px_and_769px {
  @include font(36px, 700, #262626);
  width: 644px;
}
@mixin title_between_768px_and_481px {
  @include font(36px, 700, #262626);
  width: 83.85vw;
}
@mixin title_480px_under {
  @include font(32px, 700, #262626);
  width: 86.67vw;
  text-align: center;
}
@mixin KR_title_wideDesktop {
  @include KR_font_bold(40px, 700, #262626);
  width: 644px;
}
@mixin KR_title_desktop {
  @include KR_font_bold(40px, 700, #262626);
  width: 644px;
}
@mixin KR_title_tablet {
  @include KR_font_bold(40px, 700, #262626);
  width: 83.85vw;
}
@mixin KR_title_mobile {
  @include KR_font_bold(32px, 700, #262626);
  width: 86.67vw;
  text-align: center;
}
//콘텐츠 타이틀

@mixin sub_title_1440px_over {
  @include font(20px, 600, #3f3f3f);
  width: 644px;
}
@mixin sub_title_between_1440px_and_769px {
  @include font(20px, 600, #3f3f3f);
  margin-bottom: 24px;
  width: 644px;
}
@mixin sub_title_between_768px_and_481px {
  @include font(20px, 600, #3f3f3f);
  margin-bottom: 24px;
  word-break: keep-all;
  width: 83.85vw;
}
@mixin sub_title_480px_under {
  @include font(22px, 600, #3f3f3f);
  margin-bottom: 24px;
  width: 86.67vw;
}
@mixin KR_sub_title_wideDesktop {
  @include KR_font_bold(20px, 700, #262626);
  margin-bottom: 24px;
  width: 644px;
}
@mixin KR_sub_title_desktop {
  @include KR_font_bold(20px, 700, #262626);
  margin-bottom: 24px;
  width: 644px;
}
@mixin KR_sub_title_tablet {
  @include KR_font_bold(20px, 700, #262626);
  word-break: keep-all;
  width: 83.85vw;
}
@mixin KR_sub_title_moblie {
  @include KR_font_bold(22px, 700, #262626);
  margin-bottom: 24px;
  width: 86.67vw;
}
//콘텐츠
@mixin content_1440px_over {
  @include font(16px, 300, #4f4f4f);
  line-height: 29px;
  width: 644px;
}
@mixin content_between_1440px_and_769px {
  @include font(16px, 300, #4f4f4f);
  line-height: 29px;
  width: 644px;
}
@mixin content_between_768px_and_481px {
  @include font(16px, 300, #4f4f4f);
  line-height: 29px;
  width: 83.85vw;
  word-break: keep-all;
}
@mixin content_480px_under {
  @include font(20px, 300, #4f4f4f);
  line-height: 29px;
  width: 86.67vw;
}
@mixin KR_content_wideDesktop {
  @include KR_font_regular(18px, 400, #4f4f4f);
  width: 644px;
}
@mixin KR_content_desktop {
  @include KR_font_regular(18px, 400, #4f4f4f);
  width: 644px;
}
@mixin KR_content_tablet {
  @include KR_font_regular(18px, 400, #4f4f4f);
  width: 83.85vw;
  word-break: keep-all;
}
@mixin KR_content_mobile {
  @include KR_font_regular(18px, 400, #4f4f4f);
  width: 86.67vw;
}
//Footer
@mixin footer_1440px_over {
  @include font(12px, 400, #4f4f4f);
}
@mixin footer_between_1440px_and_769px {
  @include font(12px, 400, #4f4f4f);
}
@mixin footer_between_768px_and_481px {
  @include font(1.56vw, 400, #4f4f4f);
}
@mixin footer_480px_under {
  @include font(2.92vw, 400, #4f4f4f);
}

//img Size(높이가 길때)
@mixin img_longer_height_four_and_two_1440px_over {
  width: 348px;
  min-height: 100%;
  border-radius: 8px;
}
@mixin img_longer_height_three_1440px_over {
  width: 226px;
  min-height: 100%;
  border-radius: 8px;
}
@mixin img_longer_height_one_1440px_over {
  width: 397.49px;
  min-height: 100%;
  border-radius: 8px;
}
@mixin img_longer_width_one_1440px_over {
  width: 712px;
  min-height: 100%;
  border-radius: 8px;
}

@mixin img_longer_height_four_and_two_between_1440px_and_769px {
  width: 348px;
  min-height: 100%;
  border-radius: 8px;
}
@mixin img_longer_height_three_between_1440px_and_769px {
  width: 226px;
  min-height: 100%;
  border-radius: 8px;
}
@mixin img_longer_height_one_between_1440px_and_769px {
  width: 397.49px;
  min-height: 100%;
  border-radius: 8px;
}
@mixin img_longer_width_one_between_1440px_and_769px {
  width: 712px;
  min-height: 100%;
  border-radius: 8px;
}

@mixin img_longer_height_four_and_two_between_768px_and_481px {
  width: 44.79vw;
  min-height: 100%;
  border-radius: 1.04vw;
}
@mixin img_longer_height_three_between_768px_and_481px {
  width: 29.17vw;
  min-height: 100%;
  border-radius: 0.56vw;
}
@mixin img_longer_height_one_between_768px_and_481px {
  width: 44.79vw;
  min-height: 100%;
  border-radius: 1.04vw;
}
@mixin img_longer_width_one_between_768px_and_481px {
  width: 91.67vw;
  min-height: 100%;
  border-radius: 1.04vw;
}
@mixin img_longer_height_two_480px_under {
  width: 41.67vw;
  height: 100%;
  border-radius: 1.67vw;
}
@mixin img_longer_height_one_480px_under {
  width: 86.67vw;
  min-height: 100%;
  border-radius: 1.67vw;
}
@mixin img_longer_width_one_480px_under {
  width: 86.67vw;
  min-height: 100%;
  border-radius: 1.67vw;
}

//정사각형일때

@mixin img_square_four_and_two_1440px_over {
  width: 348px;
  min-height: 100%;
  border-radius: 8px;
}
@mixin img_square_three_1440px_over {
  width: 226px;
  min-height: 100%;
  border-radius: 8px;
}

@mixin img_square_one_1440px_over {
  width: 397.49px;
  min-height: 100%;
  border-radius: 8px;
}

@mixin img_square_four_and_two_between_1440px_and_769px {
  width: 348px;
  min-height: 100%;
  border-radius: 8px;
}
@mixin img_square_three_between_1440px_and_769px {
  width: 226px;
  min-height: 100%;
  border-radius: 8px;
}
@mixin img_square_one_between_1440px_and_769px {
  width: 397.49px;
  min-height: 100%;
  border-radius: 8px;
}

@mixin img_square_four_and_two_between_768px_and_481px {
  width: 44.79vw;
  min-height: 100%;
  border-radius: 1.04vw;
}
@mixin img_square_three_between__768px_and_481px {
  width: 29.43vw;
  min-height: 100%;
  border-radius: 8px;
}
@mixin img_square_one_between_768px_and_481px {
  width: 44.79vw;
  min-height: 100%;
  border-radius: 1.04vw;
}

@mixin img_square_one_480px_under {
  width: 86.67vw;
  min-height: 100%;
  border-radius: 1.67vw;
}
//Icon 사이즈

@mixin img_game_icon_square_one_1440px_over {
  width: 88px;
  height: 88px;
  border-radius: 16px;
}
@mixin img_game_icon_square_one_480px_under {
  width: 18.33vw;
  height: 18.33vw;
  border-radius: 3.33vw;
}

//프로필
@mixin profile_1440px_over {
  display: grid;
  grid-template-columns: 56px 1fr;
  grid-template-rows: 56px;
  width: 644px;
  margin-bottom: 64px;
  gap: 16px;
  img[content="profile"] {
    width: 100%;
    height: 100%;
    border-radius: 100px;
  }
  div[content="info"] {
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 4px;
    span[content="name"] {
      @include font(16px, 400, #484848);
      display: flex;
      column-gap: 6px;
    }
    span[content="date"] {
      @include font(14px, 400, #9b9b9b);
    }
  }
}
@mixin profile_between_1440px_and_769px {
  display: grid;
  grid-template-columns: 56px 1fr;
  grid-template-rows: 56px;
  width: 644px;
  margin-bottom: 64px;
  gap: 16px;
  img[content="profile"] {
    width: 100%;
    height: 100%;
    border-radius: 100px;
  }
  div[content="info"] {
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 4px;
    span[content="name"] {
      @include font(16px, 400, #484848);
      display: flex;
      column-gap: 6px;
    }
    span[content="date"] {
      @include font(14px, 400, #9b9b9b);
    }
  }
}
@mixin profile_between_768px_and_481px {
  display: grid;
  grid-template-columns: 7.29vw 1fr;
  grid-template-rows: 7.29vw;
  width: 86.46vw;
  margin-bottom: 64px;
  gap: 2.08vw;

  img[content="profile"] {
    width: 100%;
    height: 100%;
    border-radius: 100px;
  }
  div[content="info"] {
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 4px;
    span[content="name"] {
      @include font(16px, 400, #484848);
      display: flex;
      column-gap: 0.78vw;
    }
    span[content="date"] {
      @include font(14px, 400, #9b9b9b);
    }
  }
}
@mixin profile_480px_under {
  display: grid;
  grid-template-rows: 11.67vw 1fr;
  grid-template-rows: 12.89vw;
  justify-content: center;
  width: 86.46vw;
  margin-bottom: 13.33vw;
  gap: 1.67vw;
  place-items: center;
  img[content="profile"] {
    width: 11.67vw;
    height: 11.67vw;
    border-radius: 100px;
  }
  div[content="info"] {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 0.83vw;
    span[content="name"] {
      @include font(3.75vw, 400, #484848);
      display: flex;
      column-gap: 0.78vw;
    }
    span[content="date"] {
      @include font(3.75vw, 400, #9b9b9b);
    }
  }
}

//대시보드 링크 섹션
@mixin dashboard_link_section_1440px_over {
  width: 1080px;
  height: 360px;
  background: #ffe7e7;
  border-radius: 32px;
  margin-top: 194px;
}
@mixin dashboard_link_section_between_1440px_and_769px {
  width: 75vw;
  height: 25vw;
  background: #ffe7e7;
  border-radius: 2.22vw;
  margin-top: 13.47vw;
}
@mixin dashboard_link_section_between_768px_and_481px {
  width: 100%;
  height: 37.5vw;
  background: #ffe7e7;
  border-radius: 0vw;
  margin-top: 8.72vw;
}
@mixin dashboard_link_section_480px_under {
  width: 100%;
  height: 52.29vw;
  background: #ffe7e7;
  border-radius: 0vw;
  margin-top: 18.75vw;
}

//store Icon Size
@mixin img_game_store_1440px_over {
  width: 160px;
  height: 100%;
  @content;
}
@mixin img_game_store_between_1440px_and_769px {
  width: 160px;
  height: 100%;
  @content;
}
@mixin img_game_store_between_768px_and_481px {
  width: 20.83vw;
  height: 100%;
  @content;
}
@mixin img_game_store_between_480px_under {
  width: 33.33vw;
  height: 100%;
  @content;
}
