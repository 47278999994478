@use "../../sass/article/article.scss";
@use "../../sass/base/base.scss";
.link {
  width: 100%;
  color: #2260ff;
}
@media screen and (min-width: 1441px) {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 88px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    div {
      width: 1440px;
      img {
        width: 124px;
        height: 31px;
      }
    }
  }
  .thumbnail {
    @include article.thumbnail_1440px_over;
    margin-top: 64px;
  }
  .title[data-content="KR"] {
    @include article.KR_title_wideDesktop;
    margin: 0;
    margin-bottom: 24px;
  }
  .title {
    @include article.title_1440px_over;
    margin: 0;
    margin-bottom: 24px;
  }
  .profile {
    @include article.profile_1440px_over;
  }
  .article_contents[data-content="KR"] {
    display: flex;
    flex-direction: column;
    align-items: center;
    h2 {
      @include article.KR_sub_title_wideDesktop;
      margin-bottom: 24px;
    }
    p {
      @include article.KR_content_wideDesktop;
      margin: 0;
    }
    P:not(:last-of-type) {
      margin-bottom: 24px;
    }
    ul {
      @include article.KR_content_wideDesktop;
      margin-top: 0;
      margin-bottom: 24px;
      padding-left: 27px;
      > li {
        list-style: inside;
      }
    }
    div[data-content="4"] {
      display: grid;
      grid-template-columns: repeat(2, 348px);
      grid-template-rows: repeat(2, max-content);
      gap: 16px;
      margin-top: 40px;
      margin-bottom: 40px;
    }
    div[data-content="3"] {
      display: grid;
      grid-template-columns: repeat(3, 226px);
      grid-template-rows: max-content;
      gap: 16px;
      margin-top: 40px;
      margin-bottom: 40px;
    }
    div[data-content="2"] {
      display: flex;
      align-items: stretch;
      flex-wrap: wrap;
      gap: 16px;
      margin-top: 20px;
      div {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
          margin-bottom: 8px;
        }
        p {
          margin: 0;
          text-align: center;
          width: fit-content;
          @include article.KR_font_regular(15px, 400, #919191) {
            line-height: 150%;
          }
        }
      }
    }
    div[data-content="2"] > {
      p {
        text-align: center;
        margin-top: 8px;
      }
    }
    div[data-content="1"] {
      display: flex;
      justify-content: center;
      margin-top: 40px;
      margin-bottom: 40px;
    }
    div[data-content="gameLinkBox"] {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 64px;
      margin-bottom: 40px;
      row-gap: 16px;
      width: 440px;
      height: 258px;
      background: #f3f3f3;
      border-radius: 16px;
      p {
        @include base.font("Poppins", 24px, 600, #262626) {
          line-height: 140%;
          text-align: center;
        }
      }
    }
    .game_link {
      display: flex;
      column-gap: 8px;
      height: 40px;
      a {
        img {
          @include article.img_game_store_1440px_over;
        }
      }
    }

    @if img {
      img[content="height_4"] {
        @include article.img_longer_height_four_and_two_1440px_over;
      }
      img[content="height_3"] {
        @include article.img_longer_height_three_1440px_over;
      }
      img[content="height_2"] {
        @include article.img_longer_height_four_and_two_1440px_over;
      }
      img[content="height_1"] {
        @include article.img_longer_height_one_1440px_over;
      }
      img[content="width_1"] {
        @include article.img_longer_width_one_1440px_over;
      }
      img[content="square_1"] {
        @include article.img_square_one_1440px_over;
      }
      img[content="square_2"] {
        @include article.img_square_four_and_two_1440px_over;
      }
      img[content="square_3"] {
        @include article.img_square_three_1440px_over;
      }
      img[content="game_square_1"] {
        @include article.img_game_icon_square_one_1440px_over;
      }
    }
  }

  .article_contents {
    display: flex;
    flex-direction: column;
    align-items: center;
    h2 {
      @include article.sub_title_1440px_over;
      margin-bottom: 24px;
    }
    p {
      @include article.content_1440px_over;
      margin: 0;
    }
    P:not(:last-of-type) {
      margin-bottom: 24px;
    }
    ul {
      @include article.content_1440px_over;
      margin-top: 0;
      margin-bottom: 24px;
      padding-left: 27px;
      > li {
        list-style: inside;
      }
    }
    div[data-content="4"] {
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      justify-content: center;
      width: 800px;
      gap: 16px;
      margin-top: 40px;
      margin-bottom: 40px;
    }
    div[data-content="3"] {
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      justify-content: center;
      width: 710px;
      gap: 16px;
      margin-top: 40px;
      margin-bottom: 40px;
    }
    div[data-content="2"] {
      display: flex;
      flex-wrap: wrap;
      flex: 1 1;
      width: 720px;
      align-items: stretch;
      justify-content: center;
      gap: 16px;
      margin-top: 40px;
      margin-bottom: 40px;
      div {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        p {
          text-align: center;
          width: fit-content;
          @include base.font("Poppins", 15px, 400, #919191) {
            line-height: 150%;
          }
        }
      }
    }
    div[data-content="2"] > {
      p {
        text-align: center;
        margin-top: 8px;
      }
    }
    div[data-content="1"] {
      display: flex;
      justify-content: center;
      margin-top: 40px;
      margin-bottom: 40px;
    }
    div[data-content="gameLinkBox"] {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 64px;
      margin-bottom: 40px;
      row-gap: 16px;
      width: 440px;
      height: 258px;
      background: #f3f3f3;
      border-radius: 16px;
      p {
        @include base.font("Poppins", 24px, 600, #262626) {
          line-height: 140%;
          text-align: center;
        }
      }
    }
    .game_link {
      display: flex;
      column-gap: 8px;
      height: 40px;
      a {
        img {
          @include article.img_game_store_1440px_over;
        }
      }
    }

    @if img {
      img[content="height_4"] {
        @include article.img_longer_height_four_and_two_1440px_over;
      }
      img[content="height_3"] {
        @include article.img_longer_height_three_1440px_over;
      }
      img[content="height_2"] {
        @include article.img_longer_height_four_and_two_1440px_over;
      }
      img[content="height_1"] {
        @include article.img_longer_height_one_1440px_over;
      }
      img[content="width_1"] {
        @include article.img_longer_width_one_1440px_over;
      }
      img[content="square_1"] {
        @include article.img_square_one_1440px_over;
      }
      img[content="square_2"] {
        @include article.img_square_four_and_two_1440px_over;
      }
      img[content="square_3"] {
        @include article.img_square_three_1440px_over;
      }
      img[content="game_square_1"] {
        @include article.img_game_icon_square_one_1440px_over;
      }
    }
  }
  .dashboard_link_contents {
    @include article.dashboard_link_section_1440px_over;
    display: flex;
    flex-direction: column;
    justify-content: center;
    p {
      margin: 0;
      margin-left: 92px;
      &:first-child {
        @include base.font("Poppins", 48px, 700, #ec1d1d);
        line-height: 130%;
      }
      &:nth-child(2) {
        @include base.font("Poppins", 36px, 500, #ec1d1d);
        line-height: 130%;
      }
    }
    a {
      @include base.font("Poppins", 20px, 600, #ffffff);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 288px;
      height: 74px;
      background: #eb2328;
      border-radius: 8px;
      text-decoration: none;
      margin-top: 64px;
      margin-left: 92px;
    }
  }
  .footer {
    width: 768px;
    height: 175px;
    margin-top: 145px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    div {
      width: 140px;
      height: 35px;
      background-image: url("../../image/articles/logo.png");
      background-size: cover;
      margin-bottom: 10px;
    }
    p {
      @include article.footer_1440px_over;
      margin: 0;
    }
  }
}
@include base.desktop {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 6.11vw;
    border-bottom: 0.07vw solid rgba(0, 0, 0, 0.1);
    div {
      width: 100%;
      img {
        width: 8.61vw;
        height: 2.15vw;
        margin-left: 12.5vw;
      }
    }
  }
  .thumbnail {
    @include article.thumbnail_between_1440px_and_769px;
    margin-top: 4.44vw;
  }
  .title[data-content="KR"] {
    @include article.KR_title_desktop;
    margin: 0;
    margin-bottom: 24px;
  }
  .title {
    @include article.title_between_1440px_and_769px;
    margin: 0;
    margin-bottom: 24px;
  }
  .profile {
    @include article.profile_between_1440px_and_769px;
  }
  .article_contents[data-content="KR"] {
    display: flex;
    flex-direction: column;
    align-items: center;
    h2 {
      @include article.KR_sub_title_desktop;
      margin-bottom: 24px;
    }
    p {
      @include article.KR_content_desktop;
      margin: 0;
    }
    P:not(:last-of-type) {
      margin-bottom: 24px;
    }
    ul {
      @include article.KR_content_desktop;
      margin-top: 0;
      margin-bottom: 24px;
      padding-left: 27px;
    }
    div[data-content="4"] {
      display: grid;
      grid-template-columns: repeat(2, 348px);
      grid-template-rows: repeat(2, max-content);
      gap: 16px;
      margin-top: 40px;
      margin-bottom: 40px;
    }
    div[data-content="3"] {
      display: grid;
      grid-template-columns: repeat(3, 226px);
      grid-template-rows: max-content;
      gap: 16px;
      margin-top: 40px;
      margin-bottom: 40px;
    }
    div[data-content="2"] {
      display: flex;
      flex-wrap: wrap;
      flex: 1 1;
      width: 720px;
      align-items: stretch;
      justify-content: center;
      gap: 16px;
      margin-top: 20px;
      margin-bottom: 40px;
      div {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          margin-bottom: 8px;
        }
        p {
          margin: 0;
          text-align: center;
          width: fit-content;
          @include article.KR_font_regular(15px, 400, #919191) {
            line-height: 150%;
          }
        }
      }
    }
    div[data-content="2"] > {
      p {
        text-align: center;
        margin-top: 8px;
      }
    }
    div[data-content="1"] {
      display: flex;
      justify-content: center;
      margin-top: 40px;
      margin-bottom: 40px;
    }
    div[data-content="gameLinkBox"] {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 64px;
      margin-bottom: 40px;
      row-gap: 16px;
      width: 440px;
      height: 258px;
      background: #f3f3f3;
      border-radius: 16px;
      p {
        @include base.font("Poppins", 24px, 600, #262626) {
          line-height: 140%;
          text-align: center;
        }
      }
    }
    .game_link {
      display: flex;
      column-gap: 8px;
      height: 40px;
      a {
        img {
          @include article.img_game_store_1440px_over;
        }
      }
    }

    @if img {
      img[content="height_4"] {
        @include article.img_longer_height_four_and_two_1440px_over;
      }
      img[content="height_3"] {
        @include article.img_longer_height_three_1440px_over;
      }
      img[content="height_2"] {
        @include article.img_longer_height_four_and_two_1440px_over;
      }
      img[content="height_1"] {
        @include article.img_longer_height_one_1440px_over;
      }
      img[content="width_1"] {
        @include article.img_longer_width_one_1440px_over;
      }
      img[content="square_1"] {
        @include article.img_square_one_1440px_over;
      }
      img[content="square_2"] {
        @include article.img_square_four_and_two_1440px_over;
      }
      img[content="square_3"] {
        @include article.img_square_three_1440px_over;
      }
      img[content="game_square_1"] {
        @include article.img_game_icon_square_one_1440px_over;
      }
    }
  }

  .article_contents {
    display: flex;
    flex-direction: column;
    align-items: center;
    h2 {
      @include article.sub_title_between_1440px_and_769px;
    }
    p {
      @include article.content_between_1440px_and_769px;
      margin: 0;
    }
    P:not(:last-of-type) {
      margin-bottom: 24px;
    }
    ul {
      @include article.content_between_1440px_and_769px;
      margin-top: 0;
      margin-bottom: 24px;
      > li {
        list-style: inside;
      }
    }
    div[data-content="4"] {
      display: flex;
      align-items: stretch;
      justify-content: center;
      width: 750px;
      flex-wrap: wrap;
      gap: 16px;
      margin-top: 40px;
      margin-bottom: 40px;
    }
    div[data-content="3"] {
      display: flex;
      align-items: stretch;
      justify-content: center;
      width: 700px;
      gap: 16px;
      margin-top: 40px;
      margin-bottom: 40px;
    }
    div[data-content="2"] {
      display: flex;
      align-items: stretch;
      justify-content: center;
      flex-wrap: wrap;
      flex: 1 1;
      width: 720px;
      gap: 16px;
      margin-top: 40px;
      margin-bottom: 40px;
      div {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        p {
          text-align: center;
          width: fit-content;
          @include base.font("Poppins", 15px, 400, #919191) {
            line-height: 150%;
          }
        }
      }
    }
    div[data-content="2"] > {
      p {
        text-align: center;
        margin-top: 8px;
      }
    }
    div[data-content="1"] {
      display: flex;
      justify-content: center;
      margin-top: 40px;
      margin-bottom: 40px;
    }
    div[data-content="gameLinkBox"] {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 64px;
      margin-bottom: 40px;
      row-gap: 16px;
      width: 440px;
      height: 258px;
      background: #f3f3f3;
      border-radius: 16px;
      p {
        @include base.font("Poppins", 24px, 600, #262626) {
          line-height: 140%;
          text-align: center;
        }
      }
    }
    .game_link {
      display: flex;
      column-gap: 8px;
      height: 40px;
      a {
        img {
          @include article.img_game_store_between_1440px_and_769px;
        }
      }
    }
    @if img {
      img[content="height_4"] {
        @include article.img_longer_height_four_and_two_between_1440px_and_769px;
      }
      img[content="height_3"] {
        @include article.img_longer_height_three_between_1440px_and_769px;
      }
      img[content="height_2"] {
        @include article.img_longer_height_four_and_two_between_1440px_and_769px;
      }
      img[content="height_1"] {
        @include article.img_longer_height_one_between_1440px_and_769px;
      }
      img[content="width_1"] {
        @include article.img_longer_width_one_between_1440px_and_769px;
      }
      img[content="square_1"] {
        @include article.img_square_one_between_1440px_and_769px;
      }
      img[content="square_2"] {
        @include article.img_square_four_and_two_between_1440px_and_769px;
      }
      img[content="square_3"] {
        @include article.img_square_three_between_1440px_and_769px;
      }
      img[content="game_square_1"] {
        @include article.img_game_icon_square_one_1440px_over;
      }
    }
  }
  .dashboard_link_contents {
    @include article.dashboard_link_section_between_1440px_and_769px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    p {
      margin: 0;
      margin-left: 6.39vw;
      &:first-child {
        @include base.font("Poppins", 3.33vw, 700, #ec1d1d);
        line-height: 130%;
      }
      &:nth-child(2) {
        @include base.font("Poppins", 2.5vw, 500, #ec1d1d);
        line-height: 130%;
      }
    }
    a {
      @include base.font("Poppins", 1.39vw, 600, #ffffff);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20vw;
      height: 5.14vw;
      background: #eb2328;
      border-radius: 0.56vw;
      text-decoration: none;
      margin-top: 4.44vw;
      margin-left: 6.39vw;
    }
  }
  .footer {
    width: 53.33vw;
    height: 12.15vw;
    margin-top: 10.07vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    div {
      width: 9.72vw;
      height: 2.43vw;
      background-image: url("../../image/articles/logo.png");
      background-size: cover;
      margin-bottom: 0.69vw;
    }
    p {
      @include article.footer_between_1440px_and_769px;
      margin: 0;
    }
  }
}
@include base.tablet {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 10.42vw;
    border-bottom: 0.13vw solid rgba(0, 0, 0, 0.1);
    div {
      width: 100%;
      img {
        width: 16.15vw;
        height: 4.43vw;
        margin-left: 4.17vw;
      }
    }
  }
  .thumbnail {
    @include article.thumbnail_between_768px_and_481px;
    margin-top: 9.38vw;
  }
  .title[data-content="KR"] {
    @include article.KR_title_tablet;
    margin: 0;
    margin-bottom: 24px;
  }
  .title {
    @include article.title_between_768px_and_481px;
    margin: 0;
    margin-bottom: 24px;
  }
  .profile {
    @include article.profile_between_768px_and_481px;
  }

  .article_contents[data-content="KR"] {
    display: flex;
    flex-direction: column;
    align-items: center;
    h2 {
      @include article.KR_sub_title_tablet;
    }
    p {
      @include article.KR_content_tablet;
      margin: 0;
    }
    p:not(:last-of-type) {
      margin-bottom: 24px;
    }
    ul {
      @include article.KR_content_tablet;
      margin-top: 0;
      padding-left: 3.5vw;
    }
    div[data-content="4"] {
      display: grid;
      grid-template-columns: repeat(2, 44.79vw);
      grid-template-rows: repeat(2, max-content);
      gap: 2.08vw;
      margin-top: 40px;
      margin-bottom: 40px;
    }
    div[data-content="3"] {
      display: grid;
      grid-template-columns: repeat(3, 29.17vw);
      grid-template-rows: max-content;
      gap: 2.08vw;
      margin-top: 40px;
      margin-bottom: 40px;
    }
    div[data-content="2"] {
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      gap: 2.08vw;
      margin-top: 20px;
      margin-bottom: 40px;
      div {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          margin-bottom: 8px;
        }
        p {
          margin: 0;
          text-align: center;
          width: fit-content;
          @include article.KR_font_regular(15px, 400, #919191) {
            line-height: 150%;
          }
        }
      }
    }
    div[data-content="2"] > {
      p {
        text-align: center;
        margin-top: 8px;
      }
    }
    div[data-content="1"] {
      display: flex;
      justify-content: center;
      margin-top: 40px;
      margin-bottom: 40px;
    }
    div[data-content="gameLinkBox"] {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 64px;
      margin-bottom: 40px;
      row-gap: 16px;
      width: 440px;
      height: 258px;
      background: #f3f3f3;
      border-radius: 16px;
      p {
        @include base.font("Poppins", 24px, 600, #262626) {
          line-height: 140%;
          text-align: center;
        }
      }
    }
    .game_link {
      display: flex;
      column-gap: 8px;
      height: 40px;
      a {
        img {
          @include article.img_game_store_between_1440px_and_769px;
        }
      }
    }
    @if img {
      img[content="height_4"] {
        @include article.img_longer_height_four_and_two_between_768px_and_481px;
      }
      img[content="height_3"] {
        @include article.img_longer_height_three_between_768px_and_481px;
      }
      img[content="height_2"] {
        @include article.img_longer_height_four_and_two_between_768px_and_481px;
      }
      img[content="height_1"] {
        @include article.img_longer_height_one_between_768px_and_481px;
      }
      img[content="width_1"] {
        @include article.img_longer_width_one_between_768px_and_481px;
      }
      img[content="square_1"] {
        @include article.img_square_one_between_768px_and_481px;
      }
      img[content="square_2"] {
        @include article.img_square_four_and_two_between_768px_and_481px;
      }
      img[content="square_3"] {
        @include article.img_square_three_between__768px_and_481px;
      }
      img[content="game_square_1"] {
        @include article.img_game_icon_square_one_1440px_over;
      }
    }
  }

  .article_contents {
    display: flex;
    flex-direction: column;
    align-items: center;
    h2 {
      @include article.sub_title_between_768px_and_481px;
    }
    p {
      @include article.content_between_768px_and_481px;
      margin: 0;
    }
    p:not(:last-of-type) {
      margin-bottom: 24px;
    }
    ul {
      @include article.content_between_768px_and_481px;
      margin-top: 0;
      margin-bottom: 24px;
      padding-left: 3.5vw;
      > li {
        list-style: inside;
      }
    }
    div[data-content="4"] {
      display: flex;
      align-items: stretch;
      justify-content: center;
      flex-wrap: wrap;
      gap: 2.08vw;
      margin-top: 40px;
      margin-bottom: 40px;
    }
    div[data-content="3"] {
      display: flex;
      align-items: stretch;
      justify-content: center;
      flex-wrap: wrap;
      gap: 2.08vw;
      margin-top: 40px;
      margin-bottom: 40px;
    }
    div[data-content="2"] {
      display: flex;
      align-items: stretch;
      justify-content: center;
      flex-wrap: wrap;
      gap: 2.08vw;
      margin-top: 40px;
      margin-bottom: 40px;
      div {
        display: flex;
        flex-direction: column;
        align-items: center;
        p {
          text-align: center;
          width: fit-content;
          @include base.font("Poppins", 15px, 400, #919191) {
            line-height: 150%;
          }
        }
      }
    }
    div[data-content="2"] > {
      p {
        text-align: center;
        margin-top: 8px;
      }
    }
    div[data-content="1"] {
      display: flex;
      justify-content: center;
      margin-top: 40px;
      margin-bottom: 40px;
    }
    div[data-content="gameLinkBox"] {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 64px;
      margin-bottom: 40px;
      row-gap: 16px;
      width: 440px;
      height: 258px;
      background: #f3f3f3;
      border-radius: 16px;
      p {
        @include base.font("Poppins", 24px, 600, #262626) {
          line-height: 140%;
          text-align: center;
        }
      }
    }
    .game_link {
      display: flex;
      column-gap: 8px;
      height: 40px;
      a {
        img {
          @include article.img_game_store_between_1440px_and_769px;
        }
      }
    }
    @if img {
      img[content="height_4"] {
        @include article.img_longer_height_four_and_two_between_768px_and_481px;
      }
      img[content="height_3"] {
        @include article.img_longer_height_three_between_768px_and_481px;
      }
      img[content="height_2"] {
        @include article.img_longer_height_four_and_two_between_768px_and_481px;
      }
      img[content="height_1"] {
        @include article.img_longer_height_one_between_768px_and_481px;
      }
      img[content="width_1"] {
        @include article.img_longer_width_one_between_768px_and_481px;
      }
      img[content="square_1"] {
        @include article.img_square_one_between_768px_and_481px;
      }
      img[content="square_2"] {
        @include article.img_square_four_and_two_between_768px_and_481px;
      }
      img[content="square_3"] {
        @include article.img_square_three_between__768px_and_481px;
      }
      img[content="game_square_1"] {
        @include article.img_game_icon_square_one_1440px_over;
      }
    }
  }
  .dashboard_link_contents {
    @include article.dashboard_link_section_between_768px_and_481px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
      margin: 0;
      margin-left: 0vw;
      &:first-child {
        @include base.font("Poppins", 4.69vw, 700, #ec1d1d);
        line-height: 130%;
      }
      &:nth-child(2) {
        @include base.font("Poppins", 3.13vw, 500, #ec1d1d);
        line-height: 130%;
      }
    }
    a {
      @include base.font("Poppins", 2.08vw, 600, #ffffff);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 28.39vw;
      height: 6.9vw;
      background: #eb2328;
      border-radius: 1.04vw;
      text-decoration: none;
      margin-top: 5.21vw;
      margin-left: 0vw;
    }
  }
  .footer {
    width: 100%;
    height: 22.79vw;
    margin-top: 12.63vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    div {
      width: 18.23vw;
      height: 4.56vw;
      background-image: url("../../image/articles/logo.png");
      background-size: cover;
      margin-bottom: 1.3vw;
    }
    p {
      @include article.footer_between_768px_and_481px;
      margin: 0;
    }
  }
}

@include base.mobile {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 16.67vw;
    border-bottom: 0.21vw solid rgba(0, 0, 0, 0.1);
    div {
      width: 100%;
      img {
        width: 25.83vw;
        height: 6.46vw;
        margin-left: 6.67vw;
      }
    }
  }
  .thumbnail {
    @include article.thumbnail_480px_under;
    margin-top: 5vw;
  }
  .title[data-content="KR"] {
    @include article.KR_title_mobile;
    margin: 0;
    margin-bottom: 24px;
  }
  .title {
    @include article.title_480px_under;
    margin: 0;
    margin-bottom: 24px;
  }
  .profile {
    @include article.profile_480px_under;
  }
  .article_contents[data-content="KR"] {
    display: flex;
    flex-direction: column;
    align-items: center;
    h2 {
      @include article.KR_sub_title_moblie;
    }
    p {
      @include article.KR_content_mobile;
      margin: 0;
    }
    P:not(:last-of-type) {
      margin-bottom: 24px;
    }
    ul {
      @include article.KR_content_mobile;
      margin-top: 0;
      margin-bottom: 24px;
      line-height: 160%;
      li:not(:last-of-type) {
        margin-bottom: 24px;
      }
      > li {
        list-style: inside;
      }
    }
    div[data-content="4"] {
      display: grid;
      grid-template-columns: repeat(1, 86.67vw);
      grid-template-rows: repeat(4, max-content);
      gap: 13.33vw;
      margin-top: 8.33vw;
      margin-bottom: 8.33vw;
    }
    div[data-content="3"] {
      display: grid;
      grid-template-columns: repeat(1, 86.67vw);
      grid-template-rows: repeat(3, max-content);
      gap: 13.33vw;
      margin-top: 8.33vw;
      margin-bottom: 8.33vw;
    }
    div[data-content="2"] {
      display: flex;
      align-items: stretch;
      gap: 13.33vw;
      margin-top: 4.33vw;
      margin-bottom: 8.33vw;
      div {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          margin-bottom: 8px;
        }
        p {
          text-align: center;
          width: fit-content;
          margin: 0;
          @include base.font("Poppins", 15px, 400, #919191) {
            line-height: 150%;
          }
        }
      }
    }
    div[data-content="1"] {
      display: flex;
      justify-content: center;
      margin-top: 8.33vw;
      margin-bottom: 8.33vw;
    }
    div[data-content="2"] > {
      p {
        text-align: center;
        margin-top: 8px;
      }
    }
    div[data-content="gameLinkBox"] {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 13.33vw;
      margin-bottom: 8.33vw;
      row-gap: 3.33vw;
      width: 91.67vw;
      height: 53.75vw;
      background: #f3f3f3;
      border-radius: 3.33vw;
      p {
        @include article.KR_font_regular(5vw, 400, #919191) {
          line-height: 150%;
        }
      }
    }
    .game_link {
      display: flex;
      column-gap: 1.67vw;
      height: 8.33vw;
      a {
        img {
          @include article.img_game_store_between_480px_under;
        }
      }
    }
    @if img {
      img[content="height_4"] {
        @include article.img_longer_height_one_480px_under;
      }
      img[content="height_3"] {
        @include article.img_longer_height_one_480px_under;
      }
      img[content="height_2"] {
        @include article.img_longer_height_one_480px_under;
      }
      img[content="height_1"] {
        @include article.img_longer_height_one_480px_under;
        padding-left: 0;
      }
      img[content="width_1"] {
        @include article.img_longer_width_one_480px_under;
      }
      img[content="square_1"] {
        @include article.img_square_one_480px_under;
      }
      img[content="square_2"] {
        @include article.img_square_one_480px_under;
      }
      img[content="square_3"] {
        @include article.img_square_one_480px_under;
      }
      img[content="game_square_1"] {
        @include article.img_game_icon_square_one_480px_under;
      }
    }
  }

  .article_contents {
    display: flex;
    flex-direction: column;
    align-items: center;
    h2 {
      @include article.sub_title_480px_under;
    }
    p {
      @include article.content_480px_under;
      margin: 0;
    }
    P:not(:last-of-type) {
      margin-bottom: 24px;
    }
    ul {
      @include article.content_480px_under;
      margin-top: 0;
      margin-bottom: 24px;
      line-height: 160%;
      li:not(:last-of-type) {
        margin-bottom: 24px;
      }
    }
    div[data-content="4"] {
      display: flex;
      align-items: stretch;
      justify-content: center;
      flex-wrap: wrap;
      gap: 13.33vw;
      margin-top: 8.33vw;
      margin-bottom: 8.33vw;
    }
    div[data-content="3"] {
      display: flex;
      align-items: stretch;
      justify-content: center;
      flex-wrap: wrap;
      gap: 13.33vw;
      margin-top: 8.33vw;
      margin-bottom: 8.33vw;
    }
    div[data-content="2"] {
      display: flex;
      align-items: stretch;
      justify-content: center;
      flex-wrap: wrap;
      margin-top: 8.33vw;
      margin-bottom: 8.33vw;
      gap: 4.17vw;
      div {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1.67vw;
        p {
          text-align: center;
          width: fit-content;
          @include base.font("Poppins", 15px, 400, #919191) {
            line-height: 150%;
          }
        }
      }
    }
    div[data-content="1"] {
      display: flex;
      justify-content: center;
      margin-top: 8.33vw;
      margin-bottom: 8.33vw;
    }
    div[data-content="2"] > {
      p {
        text-align: center;
        margin-top: 8px;
      }
    }
    div[data-content="gameLinkBox"] {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 13.33vw;
      margin-bottom: 8.33vw;
      row-gap: 3.33vw;
      width: 91.67vw;
      height: 53.75vw;
      background: #f3f3f3;
      border-radius: 3.33vw;
      p {
        @include base.font("Poppins", 5vw, 600, #262626) {
          line-height: 140%;
          text-align: center;
        }
      }
    }
    .game_link {
      display: flex;
      column-gap: 1.67vw;
      height: 8.33vw;
      a {
        img {
          @include article.img_game_store_between_480px_under;
        }
      }
    }
    @if img {
      img[content="height_4"] {
        @include article.img_longer_height_one_480px_under;
      }
      img[content="height_3"] {
        @include article.img_longer_height_one_480px_under;
      }
      img[content="height_2"] {
        @include article.img_longer_height_two_480px_under;
      }
      img[content="height_1"] {
        @include article.img_longer_height_one_480px_under;
        padding-left: 0;
      }
      img[content="width_1"] {
        @include article.img_longer_width_one_480px_under;
      }
      img[content="square_1"] {
        @include article.img_square_one_480px_under;
      }
      img[content="square_2"] {
        @include article.img_square_one_480px_under;
      }
      img[content="square_3"] {
        @include article.img_square_one_480px_under;
      }
      img[content="game_square_1"] {
        @include article.img_game_icon_square_one_480px_under;
      }
    }
  }
  .dashboard_link_contents {
    @include article.dashboard_link_section_480px_under;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
      margin: 0;
      margin-left: 0vw;
      &:first-child {
        @include base.font("Poppins", 5.83vw, 700, #ec1d1d);
        line-height: 130%;
      }
      &:nth-child(2) {
        @include base.font("Poppins", 4.17vw, 500, #ec1d1d);
        line-height: 130%;
      }
    }
    a {
      @include base.font("Poppins", 3.33vw, 600, #ffffff);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 45.42vw;
      height: 11.04vw;
      background: #eb2328;
      border-radius: 1.67vw;
      text-decoration: none;
      margin-top: 8.33vw;
      margin-left: 0vw;
    }
  }
  .footer {
    width: 100%;
    height: 37.71vw;
    margin-top: 20vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    div {
      width: 29.17vw;
      height: 7.29vw;
      background-image: url("../../image/articles/logo.png");
      background-size: cover;
      margin-bottom: 2.08vw;
    }
    p {
      @include article.footer_480px_under;
      margin: 0;
    }
  }
}
