@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@500;700&display=swap");

.container {
  display: none;
}
.nav {
  top: 0;
  background: #da392b;
  border-bottom-width: 1px;
  border-bottom-color: #f4f1ef;
  border-bottom-style: solid;
  position: sticky;
  display: flex;
  justify-content: space-between;
  height: 4rem;
  z-index: 2;
}
.logo {
  width: 7.0625rem;
  height: 1.8125rem;
  margin-left: 9.22vw;

  align-self: center;
  margin-top: 1.2rem;
}
.companyicon {
  display: none;
}
.menulist {
  display: flex;
  list-style: none;
  align-items: center;
  margin-right: 9.22vw;
}
.articlemenu {
  width: 100%;
  height: 95%;
  text-align: center;
  align-items: center;
  display: flex;
}
.articletext,
.publishtext,
.partnershiptext,
.gametext {
  text-decoration: none;
  color: #ffffff;
  font-family: "Roboto";
  font-weight: 500;
  height: 100%;
  display: flex;
  align-items: center;
}
.articlemenu:hover {
  border-bottom: 5px solid #ffffff;
}
.articlemenu a:hover {
  color: #ffffff;
  font-weight: 700;
}

.select {
  border-bottom: 5px solid #ffffff;
}

.publishmenu {
  margin-right: 1.5rem;
  width: 100%;
  height: 95%;
  text-align: center;
  align-items: center;
  display: flex;
}
.publishmenu:hover {
  border-bottom: 5px solid #ffffff;
}
.publishmenu a:hover {
  color: #ffffff;
  font-weight: 700;
}

.partnershipmenu {
  margin-right: 1.5rem;
  width: 100%;
  height: 95%;
  text-align: center;
  align-items: center;
  display: flex;
}
.partnershipmenu:hover {
  border-bottom: 5px solid #ffffff;
}
.partnershipmenu a:hover {
  color: #ffffff;
  font-weight: 700;
}
.gamemenu {
  margin-right: 1.5rem;
  width: 100%;
  height: 95%;
  text-align: center;
  align-items: center;
  display: flex;
}
.gamemenu:hover {
  border-bottom: 5px solid #ffffff;
}
.gamemenu a:hover {
  color: #ffffff;
  font-weight: 700;
}

/*17in*/
@media all and (max-width: 1440px) {
  .logo {
    margin-left: 12.29vw;
  }
  .menulist {
    margin-right: 12.29vw;
  }
}
@media all and (max-width: 1280px) {
  .logo {
    margin-left: 13.91vw;
  }
  .menulist {
    margin-right: 14.06vw;
  }
}
@media all and (max-width: 1024px) {
  .nav {
    height: 4.625rem;
  }
  .logo {
    margin-left: 11.43vw;
    margin-top: 1.5625rem;
    width: 6.1875rem;
    height: 1.5625rem;
  }
  .menulist {
    margin-right: 11.43vw;
  }
  .gamemenu,
  .publishmenu {
    margin-right: 1.875rem;
  }
}
/*mobile*/
@media screen and (max-width: 768px) {
  @keyframes fadein {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .nav {
    flex-direction: column;
    height: 4.625rem;
    align-items: flex-start;
    z-index: 6;
  }
  .logo {
    margin-left: 13.54vw;
    padding-top: 1.375rem;
    padding-bottom: 1.375rem;
    padding-left: 0rem;
    align-self: flex-start;
    margin-top: 0px;
    width: 7.4375rem;
    height: 1.875rem;
    /* transition: ease 0.5s all; */
  }
  .logo.active {
    margin-left: 42.32vw;
    position: relative;
    width: 15.49vw;
    height: 1.875rem;
  }
  .container {
    display: inline;
    position: absolute;
    right: 14.58vw;
    top: 2.3125rem;
  }
  .menulist {
    margin-right: 0rem;
    flex-direction: column;
    display: none;
    border-bottom-left-radius: 5rem;
    border-bottom-right-radius: 5rem;
    background-color: #da392b;
  }
  .menulist.active {
    display: flex;
    width: 100%;
    box-shadow: 0px 3px 25px #00000029;
    margin-top: -0.1rem;
  }
  .companyicon {
    display: flex;
    width: 13rem;
    height: 3rem;
    place-self: center;
    opacity: 0.15;
    margin-bottom: 3rem;
  }
  .aboutmenu {
    margin-right: 0;

    padding: 10px 0;
  }
  .abouttext {
    font-size: 1.5rem;
  }
  .publishmenu {
    margin-right: 0;
    justify-content: center;
    padding: 0 0 0rem 0;
    margin-bottom: 1.625rem;
  }
  .publishmenu:hover {
    border-bottom: 0px;
  }
  .publishtext {
    font-size: 3.91vw;
    color: #ec9b94;
  }
  .partnershipmenu {
    margin-right: 0;
    justify-content: center;
    margin: 0 0 0 0;
    padding: 0 0 0rem 0;

    margin-bottom: 1.625rem;
  }
  .partnershipmenu:hover {
    border-bottom: 0px;
  }
  .partnershiptext {
    font-size: 3.91vw;
    color: #ec9b94;
  }

  .gamemenu {
    margin-right: 0;
    justify-content: center;
    padding: 5.4375rem 0 0rem 0;
    margin-bottom: 1.625rem;
  }
  .gamemenu:hover {
    border-bottom: 0px;
  }
  .gametext {
    font-size: 3.91vw;
    color: #ec9b94;
  }
  .articlemenu {
    margin-right: 0;
    justify-content: start;
    padding: 0rem 0 6.8125rem 0;
    margin-bottom: 1.625rem;
    justify-content: center;
  }
  .articletext {
    font-size: 3.91vw;
    color: #ec9b94;
  }
  .articlemenu:hover {
    border-bottom: 0px;
  }
  .icon .sticks {
    background: white;
    display: block;
    height: 5px;
    position: relative;
    transition: background 0.2s ease-out;
    width: 1.9375rem;
    right: 0;
  }

  .icon .sticks:before,
  .icon .sticks:after {
    background: white;
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    transition: all 0.2s ease-out;
    width: 100%;
  }

  .icon .sticks:before {
    top: 10px;
  }

  .icon .sticks:after {
    top: -10px;
  }
  .check {
    display: none;
  }

  /* .check:checked ~ body {
    opacity: 0;
  }

  .check:checked ~ .icon .sticks {
    background: transparent;
  }

  .check:checked ~ .icon .sticks:before {
    transform: rotate(-45deg);
  }

  .check:checked ~ .icon .sticks:after {
    transform: rotate(45deg);
  }

  .check:checked ~ .icon:not(.steps) .sticks:before,
  .check:checked ~ .icon:not(.steps) .sticks:after {
    top: 0;
  } */
}
@media all and (max-width: 480px) {
  .logo {
    margin-left: 8.3vw;
    padding-top: 1.5625rem;
    width: 5.875rem;
    height: 1.5rem;
  }
  .logo.active {
    width: 25vw;
    height: 1.875rem;
    margin-left: 37.5vw;
  }
  .container {
    right: 8.3vw;
    top: 2.3125rem;
  }
  .menulist {
    margin-right: 0rem;
    flex-direction: column;
    display: none;
    border-bottom-left-radius: 2.5rem;
    border-bottom-right-radius: 2.5rem;
    background-color: #da392b;
  }
  .menulist.active {
    display: flex;
    width: 100%;
    margin-top: -0.3rem;
    box-shadow: 0px 3px 25px #00000029;
    align-items: flex-start;
  }
  .companyicon {
    display: flex;
    width: 9rem;
    height: 2rem;
    place-self: center;
    opacity: 0.15;
    margin-bottom: 2.0625rem;
  }
  .aboutmenu {
    margin-right: 0;
    justify-content: center;
    padding: 10px 0;
  }
  .abouttext {
    font-size: 1.5rem;
  }
  .publishmenu {
    margin-right: 0;
    padding: 0 0 0rem 0;
    margin-bottom: 1.875rem;
  }
  .publishtext {
    font-size: 1.625rem;
    color: #ec9b94;
  }
  .partnershipmenu {
    margin-right: 0;
    padding: 0 0 0rem 0;
    margin: 0 0 0rem 0;
    margin-bottom: 1.875rem;
  }
  .partnershiptext {
    font-size: 1.625rem;
    color: #ec9b94;
  }
  .gamemenu {
    margin-right: 0;
    padding: 5.1875rem 0 0rem 0;
    margin-bottom: 1.875rem;
  }
  .articlemenu {
    margin-right: 0;
    padding: 0 0 0rem 0;
    margin: 0 0 5.9375rem 0;
  }
  .articletext {
    font-size: 1.625rem;
    color: #ec9b94;
  }
  .gametext {
    font-size: 1.625rem;
    color: #ec9b94;
  }

  .icon .sticks {
    background: white;
    display: block;
    height: 5px;
    position: relative;
    transition: background 0.2s ease-out;
    width: 31px;
    right: 0;
  }

  .icon .sticks:before,
  .icon .sticks:after {
    background: white;
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    transition: all 0.2s ease-out;
    width: 100%;
  }

  .icon .sticks:before {
    top: 10px;
  }

  .icon .sticks:after {
    top: -10px;
  }
  .check {
    display: none;
  }
  /* 
  .check:checked ~ body {
    opacity: 0;
  }

  .check:checked ~ .icon .sticks {
    background: transparent;
  }

  .check:checked ~ .icon .sticks:before {
    transform: rotate(-45deg);
  }

  .check:checked ~ .icon .sticks:after {
    transform: rotate(45deg);
  }

  .check:checked ~ .icon:not(.steps) .sticks:before,
  .check:checked ~ .icon:not(.steps) .sticks:after {
    top: 0;
  } */
}
