.container {
  width: 100%;
  padding-bottom: 0;
  transition: all 0.2s ease-out;
}
.sectionlist {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.button {
  position: fixed;
  top: 80%;
  z-index: 10;
  background: white;
  width: 2.6vw;
  min-width: 50px;
  height: 50px;
  min-height: 2.6vw;

  right: 5%;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  font-weight: bold;
  box-shadow: 0px 10px 15px rgba(26, 26, 26, 0.2);
  color: rgba(219, 49, 49, 1);
}
.toparrow {
  margin-top: 3px;
}
.button:hover {
  cursor: pointer;
}
@media all and (max-width: 769px) {
  .button {
    display: none;
  }
  .button {
    top: 70%;
  }
}
@media all and (max-width: 480px) {
  .button {
    display: none;
  }
}
