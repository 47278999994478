@use "../../../../sass/base/base.scss";

.button {
  position: fixed;
  top: 80%;
  z-index: 10;
  background: white;
  width: 2.6vw;
  min-width: 50px;
  height: 50px;
  min-height: 2.6vw;

  right: 5%;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  font-weight: bold;
  box-shadow: 0px 10px 15px rgba(26, 26, 26, 0.2);
  color: rgba(219, 49, 49, 1);
}
.toparrow {
  margin-top: 3px;
}
.button:hover {
  cursor: pointer;
}

.container {
  background-color: #ffffff;
  flex: 1;
  margin-bottom: 10vw;
}

.topcontainer {
  margin-top: 4.95vw;
  margin-left: 9.22vw;
  margin-bottom: 3.23vw;
}

.scriptcontainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.tag {
  width: auto;
  height: auto;
  padding: 0.31vw 0.52vw;
  background-color: #da392b;
  border-radius: 1.4375rem;
  color: #ffffff;
  font-size: 1.3125rem;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.1875rem;
}

.contentsContainer {
  width: 54.84vw;
  text-align: start;
}

.contentsblock {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  text-align: start;
  width: 100%;
}
.contentslist {
  display: grid;
  grid-template-columns: repeat(3, 344px);
  place-items: start;
  gap: 24px;
}
.contentslist {
  a {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    gap: 16px;
  }
}
.listblock {
  margin-top: 100px;
}
.listblock > p {
  width: fit-content;
  @include base.font("Poppins", 24px, 600, #262626) {
    line-height: 140%;
  }
}
.listimg {
  width: 344px;
  height: 220px;
  border-radius: 8px;
}
.list_title {
  @include base.font("Poppins", 24px, 600, #262626);
  margin-bottom: 24px;
}
.item_title {
  @include base.font("Poppins", 18px, 600, #262626);
  line-height: 140%;
  margin-bottom: 8px;
}
.date {
  @include base.font("Poppins", 14px, 400, #9d9d9d);
}

@media screen and (max-width: 1440px) {
  .container {
    height: 170vw;
  }

  .topcontainer {
    margin-top: 6.6vw;
    margin-left: 12.29vw;
    margin-bottom: 1.46vw;
  }

  .scriptcontainer {
    width: 100%;
  }

  .parag {
    margin-bottom: 3.47vw;
  }

  .contentsblock {
    margin-bottom: 5.76vw;
  }
  .contentslist {
    grid-template-columns: repeat(3, 16.39vw);
  }
  .listblock > p {
    width: fit-content;
    @include base.font("Poppins", 24px, 600, #262626) {
      line-height: 140%;
    }
  }
  .listimg {
    width: 14.1vw;
    height: 9vw;
    border-radius: 0.63vw;
    margin-bottom: 1.39vw;
  }
  .listtitle {
    width: 14.03vw;
    font-size: 1.39vw;
    margin-bottom: 2.08vw;
  }
}
@media screen and (max-width: 1280px) {
  .container {
    height: 230vw;
  }
  .topcontainer {
    margin-top: 7.34vw;
    margin-left: 13.83vw;
    margin-bottom: 1.64vw;
  }
  .backpage {
    width: 5.86vw;
    height: 2.5vw;
  }
  .backpage > p {
    font-size: 2.03vw;
  }
  .backpage > img {
    width: 2.5vw;
    height: 2.5vw;
  }
  .scriptcontainer {
    width: 100%;
  }
  .tag {
    width: auto;
    height: auto;
    padding: 0.47vw 0.78vw;
    font-size: 1.56vw;
    margin-bottom: 3.44vw;
    border-radius: 1.8vw;
  }
  .title {
    font-size: 2.97vw;
    margin-bottom: 0.94vw;
    width: 74vw;
  }
  .profile {
    grid-template-columns: 3.05vw 15vw 11vw;
    font-size: 1.56vw;
    margin-bottom: 7.03vw;
  }
  .profileImg {
    width: 3.05vw;
    height: 3.05vw;
  }

  .contentsImg {
    width: 72.66vw;
    height: 39.61vw;
  }
  .contentsblock {
    flex-direction: column;
    align-items: center;
    margin-bottom: 11.64vw;
  }

  .topcontents {
    width: 100%;
    font-size: 1.8vw;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 2.5vw;
  }
  .topcontents > p {
    width: 60.23vw;
  }
  .parag {
    margin-bottom: 3.47vw;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .contentsContainer {
    width: 60.23vw;
    font-size: 1.8vw;
  }

  .contentslist {
    grid-template-columns: repeat(3, 22.66vw);
    place-items: start;
    column-gap: 2.34vw;
    row-gap: 2vw;
  }
  .listblock > p {
    width: fit-content;
    @include base.font("Poppins", 24px, 600, #262626) {
      line-height: 140%;
    }
  }
  .listimg {
    width: 22.66vw;
    height: 14.61vw;
    border-radius: 0.7vw;
    margin-bottom: 1.39vw;
  }

  .listtitle {
    width: 22.66vw;
    font-size: 1.41vw;
    margin-bottom: 0vw;
  }
}
@media screen and (max-width: 1024px) {
  .container {
    height: 300vw;
  }
  .topcontainer {
    margin-left: 11.52vw;
    margin-bottom: 2.05vw;
  }
  .backpage {
    width: 7.42vw;
    height: 3.13vw;
  }
  .backpage > p {
    font-size: 2.54vw;
  }
  .backpage > img {
    width: 3.13vw;
    height: 3.13vw;
  }
  .scriptcontainer {
    width: 100%;
  }
  .tag {
    width: auto;
    height: auto;
    padding: 0.59vw 0.98vw;
    font-size: 1.56vw;
    margin-bottom: 3.42vw;
    border-radius: 2.25vw;
  }
  .title {
    font-size: 3.4vw;
    margin-bottom: 1.17vw;
    width: 100%;
  }
  .profile {
    grid-template-columns: 3.81vw 17vw 14vw;
    font-size: 1.95vw;
    margin-bottom: 3.42vw;
  }
  .profileImg {
    width: 3.81vw;
    height: 3.81vw;
  }

  .contentsblock {
    margin-bottom: 14.55vw;
  }
  .contentsImg {
    width: 76.95vw;
    height: 44vw;
  }
  .topcontents {
    font-size: 1.95vw;
    line-height: 3.2vw;
  }
  .topcontents > p {
    width: 63.87vw;
  }
  .parag {
    margin-bottom: 3.47vw;
  }

  .keyword {
    margin-bottom: 9.08vw;
  }
  .keyword > img {
    width: 2.05vw;
    height: 2.05vw;
    margin-right: 1.17vw;
  }
  .keyword > p {
    font-size: 2.34vw;
  }
  .contentslist {
    grid-template-columns: repeat(3, 24.61vw);
    row-gap: 1.56vw;
    column-gap: 1.56vw;
  }
  .listblock > p {
    width: fit-content;
    @include base.font("Poppins", 24px, 600, #262626) {
      line-height: 140%;
    }
  }
  .listimg {
    width: 24.61vw;
    height: 15.43vw;
    border-radius: 0.88vw;
    margin-bottom: 1.46vw;
  }
  .listtitle {
    width: 24.02vw;
    font-size: 1.76vw;
    margin-bottom: 0vw;
    font-weight: 700;
  }
}
@media screen and (max-width: 768px) {
  .button {
    display: none;
  }
  .container {
    height: 450vw;
  }
  .topcontainer {
    margin-left: 13.54vw;
    margin-bottom: 3.13vw;
  }
  .backpage {
    width: 9.77vw;
    height: 4.17vw;
  }
  .backpage > p {
    font-size: 3.39vw;
  }
  .backpage > img {
    width: 4.17vw;
    height: 4.17vw;
  }
  .scriptcontainer {
    width: 100%;
  }
  .tag {
    width: auto;
    height: auto;
    padding: 0.78vw 1.3vw;
    font-size: 2.34vw;
    margin-bottom: 4.95vw;
    border-radius: 2.47vw;
  }
  .title {
    font-size: 3.2vw;
    margin-bottom: 2.34vw;
  }
  .profile {
    grid-template-columns: 5.08vw 25vw 19vw;
    font-size: 2.6vw;
    margin-bottom: 4.82vw;
  }
  .profileImg {
    width: 5.08vw;
    height: 5.08vw;
  }

  .contentsblock {
    margin-bottom: 15.49vw;
  }
  .contentsImg {
    width: 72.92vw;
    height: 43vw;
  }
  .topcontents {
    font-size: 2.6vw;
    line-height: 4.56vw;
  }
  .topcontents > p {
    width: 72.92vw;
  }
  .parag {
    margin-bottom: 3.47vw;
  }

  .keyword {
    margin-bottom: 6.51vw;
  }
  .keyword > img {
    width: 2.73vw;
    height: 2.73vw;
    margin-right: 1.56vw;
  }
  .keyword > p {
    font-size: 2.6vw;
  }
  .contentslist {
    grid-template-columns: repeat(2, 35.29vw);

    column-gap: 2.34vw;
    row-gap: 7.81vw;
  }
  .listblock > p {
    width: fit-content;
    @include base.font("Poppins", 24px, 600, #262626) {
      line-height: 140%;
    }
  }
  .listimg {
    width: 35.29vw;
    height: 22.14vw;
    border-radius: 1.17vw;
    margin-bottom: 2.08vw;
  }

  .listtitle {
    width: 34.51vw;
    font-size: 2.34vw;
    margin-bottom: 0vw;
  }
}
@media screen and (max-width: 480px) {
  .container {
    height: 910vw;
  }
  .topcontainer {
    margin-left: 7.08vw;
    margin-bottom: 12.08vw;
  }
  .scriptcontainer {
    width: 100%;
  }

  .title {
    font-size: 5vw;
    margin-bottom: 5.42vw;
    width: 81vw;
  }

  .contentsblock {
    margin-bottom: 16.46vw;
  }
  .contentsImg {
    width: 83.33vw;
    height: 48vw;
  }
  .topcontents {
    font-size: 4.17vw;
    line-height: 7vw;
  }
  .topcontents > p {
    width: 83.33vw;
  }
  .parag {
    margin-bottom: 3.47vw;
  }

  .contentslist {
    grid-template-columns: repeat(1, 83.33vw);
    place-items: center;
    column-gap: 0vw;
    row-gap: 8.33vw;
  }
  .listblock {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .listblock > p {
    width: fit-content;
    padding-bottom: 1.88vw;
    margin-bottom: 6.67vw;
    @include base.font("Poppins", 24px, 600, #262626) {
      line-height: 140%;
    }
  }
  .listimg {
    width: 83.33vw;
    height: 55vw;
    border-radius: 1.88vw;
    margin-bottom: 4.38vw;
  }
}
