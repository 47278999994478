@keyframes move {
  from {
    margin-top: 0px;
  }
  to {
    margin-top: 20px;
  }
}
.topmovebutton {
  position: fixed;
  top: 80%;
  z-index: 1;
  background: white;
  width: 50px;
  height: 50px;
  right: 4.4vw;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  font-weight: bold;
  box-shadow: 0px 10px 15px rgba(26, 26, 26, 0.2);
  color: rgba(219, 49, 49, 1);
}
.topmovebutton:hover {
  cursor: pointer;
}
.toparrow {
  margin-top: 3px;
}
.container {
  background: #f4f1ef;
  width: 100%;
}
.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  background: #f4f1ef;
  padding-bottom: 2.625rem;
  z-index: 4;
}
.title {
  font-family: "Roboto";
  font-weight: 700;
  color: #da392b;
  font-size: 1.5625rem;
  margin-left: 9.22vw;
  padding-top: 2.6rem;
}

.button {
  font-family: "Roboto";
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  width: 12.25rem;
  height: 2.9375rem;
  background-color: #da392b;
  border-radius: 2.5625rem;
  color: white;
  box-shadow: 0px 3px 6px #da392b78;
  margin-right: 9.22vw;
  margin-top: 2.125rem;
}
.gridcontainer {
  display: grid;
  grid-template-areas:
    "a b c d e"
    "f main main main g"
    "h i j k .";
  margin-top: 0rem;
  height: 71.4vw;
  grid-template-rows: repeat(3, auto);
  width: 100%;
  overflow: hidden;
}
.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-area: main;
}
.center > h1 {
  font-family: "Roboto";
  font-weight: 700;
  font-size: 3.125vw;
  margin-bottom: 4.375rem;
}
.centerbutton {
  font-family: "Roboto";
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  width: 14vw;
  height: 3.23vw;
  background-color: #da392b;
  border-radius: 2.1vw;
  color: white;
  box-shadow: 0px 3px 6px #da392b78;
  font-size: 1.17vw;
  z-index: 3;
}
.gridcontainer > img {
  box-shadow: 0px 7px 6px #00000034;
}
.moveimg {
  transform: matrix(1, 0, 0, 1, 0, 0);
}

.imgarea1 {
  width: 6.2vw;
  max-width: 127px;
  height: 6.51vw;
  max-height: 127px;
  border-radius: 1.46vw;
  place-self: center center;
}

.imgarea2 {
  width: 8.7vw;
  max-width: 178px;
  height: 9.22vw;
  max-height: 178px;
  border-radius: 1.35vw;
  place-self: start center;
}
.imgarea3 {
  width: 5.1vw;
  max-width: 106px;
  height: 5.42vw;
  max-height: 106px;
  border-radius: 1.2vw;
  place-self: center start;
}
.imgarea4 {
  width: 7.2vw;
  max-width: 149px;
  height: 7.75vw;
  max-height: 149px;
  border-radius: 1.46vw;
  place-self: end start;
  margin-top: 52.05px;
}
.imgarea5 {
  width: 19vw;
  max-width: 266px;
  height: 19vw;
  max-height: 266px;
  border-radius: 2.6vw;
  place-self: center start;
  margin-left: -10vw;
}
.imgarea6 {
  width: 12.42vw;
  max-width: 239px;
  height: 12.42vw;
  max-height: 239px;
  border-radius: 2.6vw;
  place-self: start end;
  margin-right: -5vw;
}
.imgarea7 {
  width: 10.3vw;
  max-width: 198px;
  height: 10.3vw;
  max-height: 198px;
  border-radius: 2.34vw;
  place-self: center center;
}
.imgarea8 {
  width: 4.2vw;
  max-width: 87px;
  height: 4.5vw;
  border-radius: 0.78vw;
  place-self: start center;
}
.imgarea9 {
  width: 11vw;
  max-width: 226px;
  height: 11vw;
  max-height: 226px;
  border-radius: 3.02vw;
  place-self: start center;
  margin-top: 20px;
}
.imgarea10 {
  width: 5.4vw;
  max-width: 112px;
  height: 5.4vw;
  max-height: 112px;
  border-radius: 1.46vw;
  place-self: start center;
  margin-top: 104.1px;
}
.imgarea11 {
  width: 16.86vw;
  max-width: 324px;
  height: 16.86vw;
  max-height: 324px;
  border-radius: 3.28vw;
  place-self: start center;
}
.scriptcontainer {
  display: grid;
  grid-template-columns: repeat(1, 70vw);
  grid-template-rows: repeat(3, 19vw);
  background: #ffffff;
  height: 88.3vw;
  justify-content: center;
  align-content: center;
  row-gap: 10.5vw;
  overflow: hidden;
}

.script1,
.script2,
.script3 {
  display: flex;
}
.scripttext1,
.scripttext2,
.scripttext3 {
  width: 40vw;
  height: 19vw;
  display: flex;
  flex-direction: column;
  word-break: keep-all;
  justify-content: center;
}
.scripttext1 {
  margin-left: 2.8vw;
}
.scripttext3 {
  margin-left: 2.3vw;
}
.scripttitle {
  font-family: "Roboto";
  font-weight: 700;
  font-size: 2.55vw;
  text-align: start;
  margin-bottom: 2.5rem;
  width: 40vw;
}
.scriptcontext {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 1.15vw;
  color: #6c6c6c;
  text-align: start;
}
.scriptimg1,
.scriptimg2,
.scriptimg3 {
  width: 24.74vw;
  height: 18.96vw;
  border-radius: 1.3vw;
}
.scriptimg2 {
  margin-left: 4.1vw;
}

.footercontainer {
  height: 18.4vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-image: url("../../image/img_banner1 2.png");
  margin-bottom: -1%;
  position: relative;
}

.footercontainer > h1 {
  font-family: "Roboto";
  font-weight: 700;
  font-size: 2.6vw;
  margin-bottom: 1vw;
  color: white;
  background-color: #0fb48e;
  padding-left: 0vw;
  padding-right: 0vw;
  padding-top: 0.73vw;
  padding-bottom: 0.36vw;
  z-index: 2;
}

.footercontext {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
.footercontext > p {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 1.24vw;
  color: white;
  z-index: 2;
  width: 48.8vw;
  word-break: keep-all;
}

.star1 {
  position: absolute;
  right: 25vw;
  top: 8.7vw;
  width: 7.17vw;
  max-width: 147px;
  height: 7.66vw;
  max-height: 146.9892px;
}
.star2 {
  position: absolute;
  top: 10.8vw;
  left: 28vw;
  width: 4.44vw;
  max-width: 91px;
  height: 6.98vw;
  max-height: 133.9767px;
}
.star3 {
  position: absolute;
  left: 16vw;
  top: 1vw;
  width: 9.4vw;
  max-width: 193px;
  height: 10.05vw;
  max-height: 192.8973px;
}
@media all and (min-width: 1921px) {
  .title {
    margin-left: 9.22vw;
  }
  .button {
    margin-right: 9.22vw;
  }
}
/*17in*/
@media all and (max-width: 1440px) {
  .title {
    margin-left: 12.2917vw;
  }

  .button {
    margin-right: 12.2917vw;
  }
  .gridcontainer {
    height: 100.3vw;
    padding-bottom: 0rem;
  }
  .center {
    margin-top: 2.99vw;
    justify-content: start;
  }
  .center > h1 {
    font-size: 4.44vw;
    margin-bottom: 4.9vw;
  }
  .centerbutton {
    width: 17.85vw;
    height: 4.31vw;
    font-size: 1.67vw;
    /* margin-bottom: 126px; */
  }
  .imgarea1 {
    width: 8.82vw;
    height: 8.82vw;
  }

  .imgarea2 {
    width: 12.36vw;
    height: 12.36vw;
  }
  .imgarea3 {
    width: 7.36vw;
    height: 7.36vw;
  }
  .imgarea4 {
    width: 10.35vw;
    height: 10.35vw;
    margin-top: 0;
  }
  .imgarea5 {
    width: 18.47vw;
    height: 18.473vw;
    place-self: end start;
  }
  .imgarea6 {
    width: 16.6vw;
    height: 16.6vw;
  }
  .imgarea7 {
    width: 13.75vw;
    height: 13.75vw;
    place-self: center start;
  }
  .imgarea8 {
    width: 6.04vw;
    height: 6.04vw;
  }
  .imgarea9 {
    width: 15.69vw;
    height: 15.69vw;
  }
  .imgarea10 {
    width: 7.78vw;
    height: 7.78vw;
  }
  .imgarea11 {
    width: 22.5vw;
    height: 22.5vw;
  }
  .scriptcontainer {
    height: 99.9vw;
    grid-template-rows: repeat(3, 17.6vw);
    grid-template-columns: repeat(1, 69.9vw);

    row-gap: 13.3vw;
  }
  .scripttext1,
  .scripttext3 {
    width: 100vw;
  }
  .scripttext1 > p {
    align-self: center;
  }
  .scripttitle {
    font-size: 2.9vw;
    width: 43vw;
  }
  .scriptcontext {
    width: 39.9vw;
    font-size: 1.25vw;
  }
  .scriptimg1 {
    width: 22.92vw;
    height: 17.57vw;
  }
  .scriptimg2 {
    width: 21.46vw;
    height: 14.86vw;
  }
  .scriptimg3 {
    width: 21.6vw;
    height: 16.53vw;
  }
  .footercontainer {
    height: 25vw;
  }
  .footercontainer > h1 {
    font-size: 2.92vw;
    margin-bottom: 1.4vw;
  }
  .footercontext > p {
    width: 65vw;
    font-size: 1.67vw;
  }
  .star1 {
    top: 11.6vw;
    right: 20.8vw;
    width: 10.21vw;
    height: 10.21vw;
  }
  .star2 {
    width: 6.3vw;
    height: 9.3vw;
    left: 25.3vw;
    top: 13.2vw;
  }
  .star3 {
    left: 8.5vw;
    top: 2.5vw;
    width: 13.4vw;
    height: 13.4vw;
  }
}
/*15in*/
@media all and (max-width: 1280px) {
  .gridcontainer {
    height: 105.4vw;
    padding-bottom: 0px;
  }
  .title {
    margin-left: 13.83vw;
  }

  .button {
    margin-right: 13.83vw;
  }

  .center > h1 {
    margin-top: 6.7vw;
    font-size: 3.28vw;
    margin-bottom: 5.5vw;
  }
  .centerbutton {
    width: 20.08vw;
    height: 4.84vw;
    font-size: 1.88vw;
    margin-bottom: 9.8vw;
  }

  .imgarea1 {
    width: 9.92vw;
    height: 9.92vw;
  }

  .imgarea2 {
    width: 13.91vw;
    height: 13.91vw;
  }
  .imgarea3 {
    width: 8.28vw;
    height: 8.28vw;
    place-self: end center;
  }
  .imgarea4 {
    width: 11.64vw;
    height: 11.64vw;
    margin-top: 0;
    place-self: start start;
  }
  .imgarea5 {
    width: 20.78vw;
    height: 20.78vw;
    place-self: end start;
  }
  .imgarea6 {
    width: 18.67vw;
    height: 18.67vw;
  }
  .imgarea7 {
    width: 15.47vw;
    height: 15.47vw;
    place-self: center start;
  }
  .imgarea8 {
    width: 6.8vw;
    height: 6.8vw;
  }
  .imgarea9 {
    width: 17.66vw;
    height: 17.66vw;
  }
  .imgarea10 {
    width: 8.75vw;
    height: 8.75vw;
  }
  .imgarea11 {
    width: 25.31vw;
    height: 25.31vw;
  }
  .scriptcontainer {
    grid-template-rows: repeat(3, 22.1vw);
    grid-template-columns: repeat(1, 69.9vw);
    row-gap: 11.6vw;

    height: 112.3vw;
  }
  .scripttitle {
    font-size: 3.28vw;
    width: 28.91vw;
    line-height: 4.1vw;
    margin-bottom: 1.6vw;
  }
  .scriptcontext {
    font-size: 1.41vw;
    width: 28.91vw;
    line-height: 2vw;
  }
  .script1,
  .script2,
  .script3 {
    justify-content: center;
  }

  .scripttext1,
  .scripttext3 {
    width: 28.91vw;
    margin-left: 8.59vw;
    justify-content: flex-start;
  }
  .scripttext2 {
    width: 28.91vw;
    justify-content: flex-start;
  }

  .scriptimg1 {
    width: 28.9vw;
    height: 22.1vw;
    place-items: center;
  }
  .scriptimg2 {
    width: 28.9vw;
    height: 22.1vw;
    margin-left: 8.59vw;
  }
  .scriptimg3 {
    width: 28.91vw;
    height: 22.1vw;
  }
  .footercontainer {
    height: 28.13vw;
  }
  .footercontainer > h1 {
    font-size: 3.28vw;
    padding: 1.13vw 1.13vw 0.37vw;
  }
  .footercontext > p {
    font-size: 1.88vw;
    line-height: 2.4vw;
  }
  .star1 {
    right: 8.36vw;
    top: 14.06vw;
    width: 11.48vw;
    height: 11.48vw;
  }
  .star2 {
    top: 15.08vw;
    left: 19.92vw;
    width: 7.03vw;
    height: 10.47vw;
  }
  .star3 {
    left: 2vw;
    top: 2.5vw;
    width: 15vw;
    height: 15vw;
  }
}
/*13in*/
@media all and (max-width: 1024px) {
  .title {
    font-size: 1.5625rem;
    margin-left: 11.43vw;
  }

  .button {
    width: 12rem;
    height: 2.9375rem;
    border-radius: 2.5625rem;
    font-size: 1.125rem;
    margin-right: 11.43vw;
  }
  .container {
    width: 100vw;
  }
  .gridcontainer {
    height: 131.7vw;
    padding-bottom: 0px;
    overflow-x: hidden;
    margin-top: 2.44vw;
  }
  .center {
    width: 48.8vw;
    place-items: center;
    place-self: start center;
    margin-top: 0;
  }
  .center > h1 {
    width: 100vw;
    font-size: 4.1vw;
    margin-bottom: 2.93vw;
    margin-top: 0;
  }
  .centerbutton {
    width: 25.1vw;
    height: 6.05vw;
    font-size: 2.44vw;
    border-radius: 4vw;
  }

  .imgarea1 {
    width: 12.4vw;
    height: 12.4vw;
    border-radius: 2.73vw;
  }

  .imgarea2 {
    width: 17.38vw;
    height: 17.38vw;
    border-radius: 2.54vw;
  }
  .imgarea3 {
    width: 10.35vw;
    height: 10.35vw;
    place-self: end center;
    top: -18.95vw;
    position: relative;
    border-radius: 2.25vw;
  }
  .imgarea4 {
    width: 14.55vw;
    height: 14.55vw;
    margin-top: 0;
    place-self: end start;
    margin-left: 4.59vw;
    position: relative;
    top: -20vw;
    border-radius: 2.73vw;
  }
  .imgarea5 {
    width: 25.98vw;
    height: 25.98vw;
    place-self: end start;
    position: relative;
    top: -10%;
    border-radius: 4.88vw;
  }
  .imgarea6 {
    width: 23.34vw;
    height: 23.34vw;
    position: relative;
    left: -98px;
    border-radius: 4.88vw;
    margin-right: -7vw;
  }
  .imgarea7 {
    width: 19.34vw;
    height: 19.34vw;
    place-self: start start;
    position: relative;
    left: 6.84vw;
    top: 8.79vw;
    border-radius: 4.39vw;
  }
  .imgarea8 {
    width: 8.5vw;
    height: 8.5vw;
    border-radius: 1.46vw;
    transform: none;
    position: relative;
    place-self: start start;
    left: -3.91vw;
  }
  .imgarea9 {
    width: 22.07vw;
    height: 22.07vw;
    position: relative;
    place-self: start start;
    left: -8vw;
    border-radius: 4.39vw;
    margin-top: 0;
    top: -6vw;
  }
  .imgarea10 {
    width: 10.94vw;
    height: 10.94vw;
    position: relative;
    place-self: start start;
    margin-top: 0;
    border-radius: 2.73vw;
  }
  .imgarea11 {
    width: 31.64vw;
    height: 31.64vw;
    position: relative;
    place-self: start start;
    left: 7.32vw;
    top: -7vw;
    border-radius: 6.15vw;
  }
  .scriptcontainer {
    height: 140.4vw;
    padding-top: 0;
    padding-bottom: 0;
    grid-template-rows: repeat(3, 28vw);
    grid-template-columns: repeat(1, 75.4vw);

    min-height: 0;
    row-gap: 15.53vw;
  }
  .script1,
  .script2,
  .script3 {
    width: 47.5rem;

    justify-content: flex-start;
  }
  .scripttext1,
  .scripttext3 {
    margin-left: 3.1vw;
    width: 36.1vw;
  }
  .scripttext2 {
    width: 36.1vw;
  }
  .scripttitle {
    font-size: 4.1vw;
    width: 36.13vw;
    line-height: 5.08vw;
    margin-bottom: 1.95vw;
  }
  .scriptcontext {
    font-size: 1.76vw;
    width: 36.13vw;
    line-height: 2.54vw;
  }
  .scripttext1 > p {
    align-self: auto;
  }
  .scriptimg1 {
    width: 36.13vw;

    height: 27.73vw;
  }
  .scriptimg2 {
    width: 36.13vw;
    height: 25.1vw;
    margin-left: 4.7vw;
  }
  .scriptimg3 {
    width: 36.13vw;

    height: 27.64vw;
  }
  .footercontainer {
    height: 28.1vw;
    justify-content: flex-start;
  }
  .footercontainer > h1 {
    font-size: 3.2vw;
    margin-bottom: 2vw;
    margin-top: 7.2vw;
  }
  .footercontext > p {
    font-size: 1.9vw;
    line-height: 2.3vw;
    width: 72.5vw;
  }
  .star1 {
    width: 11.4vw;
    height: 11.4vw;
    right: 8.4vw;
    top: 14.1vw;
  }
  .star2 {
    width: 7vw;
    height: 10.4vw;
    top: 15vw;
    left: 19.9vw;
  }
  .star3 {
    width: 15vw;
    height: 15vw;

    top: 2.5vw;
    left: 3vw;
  }
}
/*tablet*/
@media all and (max-width: 768px) {
  .topmovebutton {
    display: none;
  }
  .header {
    position: static;
    padding-bottom: 0px;
    background-color: transparent;
  }
  .title {
    font-size: 1.5625rem;
    margin-left: 13.54vw;
    padding-top: 1.8125rem;
  }
  .container {
    width: 100%;
  }
  .button {
    visibility: hidden;
    width: 12rem;
    height: 2.9375rem;

    border-radius: 2.5625rem;

    margin-right: 13.54vw;
    margin-top: 1.25rem;
  }
  .gridcontainer {
    width: 100vw;
    height: 170vw;
    padding-bottom: 0px;
    position: relative;
    overflow-x: hidden;
    margin-top: 3.26vw;
  }
  .center {
    width: 100%;
    place-items: center;
    place-self: start center;
    margin-top: 0;
    position: relative;
    top: 0;
    right: 17vw;
  }
  .center > h1 {
    font-size: 5.5vw;
    margin-bottom: 5.2vw;
    margin-top: 0;
  }
  .centerbutton {
    width: 33.5vw;
    height: 8.1vw;
    font-size: 2.9vw;
    border-radius: 5.3vw;
  }

  .imgarea1 {
    width: 16.54vw;
    height: 16.54vw;
    border-radius: 2.73vw;
    position: relative;
    left: -20vw;
  }

  .imgarea2 {
    width: 23.2vw;
    height: 23.2vw;
    border-radius: 3.4vw;
    position: relative;
    top: 0px;
    left: -20vw;
  }
  .imgarea3 {
    width: 13.8vw;
    height: 13.8vw;
    place-self: end center;
    top: -18.95vw;
    position: relative;
    border-radius: 3vw;
    left: -17vw;
  }
  .imgarea4 {
    width: 19.4vw;
    height: 19.4vw;
    margin-top: 0;
    place-self: start start;
    margin-left: 0vw;
    position: relative;
    top: 15vw;
    border-radius: 3.6vw;
    right: 7vw;
  }
  .imgarea5 {
    width: 34.6vw;
    height: 34.6vw;
    place-self: end start;
    position: relative;
    top: 0;
    border-radius: 6.5vw;
    right: 15vw;
  }
  .imgarea6 {
    width: 31.1vw;
    height: 31.1vw;
    position: relative;
    margin-left: -25vw;
    top: -10vw;
    border-radius: 4.88vw;
  }

  .imgarea7 {
    width: 19.34vw;
    height: 19.34vw;
    place-self: start start;
    position: relative;
    left: -22vw;
    top: 8.79vw;
    border-radius: 4.39vw;
  }
  .imgarea8 {
    visibility: hidden;
    width: 8.5vw;
    height: 8.5vw;
    border-radius: 1.46vw;
    transform: none;
    position: relative;
    place-self: start start;
    left: -0vw;
  }
  .imgarea9 {
    width: 29.4vw;
    height: 29.4vw;
    position: relative;
    place-self: start start;

    left: -27vw;
    border-radius: 5.9vw;
    margin-top: 0;
    top: -6vw;
  }
  .imgarea10 {
    width: 14.6vw;
    height: 14.6vw;
    position: relative;
    place-self: start start;
    margin-top: 0;
    border-radius: 3.6vw;
    right: 20vw;
  }
  .imgarea11 {
    width: 42.2vw;
    height: 42.2vw;
    position: relative;
    place-self: start start;
    left: -10vw;
    top: -7vw;
    border-radius: 8.2vw;
  }
  .scriptcontainer {
    width: 100vw;
    height: 397.5vw;
    grid-template-columns: repeat(1, 48rem);
    grid-template-rows: repeat(3, 104.8vw);
    place-items: start;
    min-height: 0;
    row-gap: 18.2vw;
  }
  .script1,
  .script2,
  .script3 {
    width: 100vw;
    margin-left: 0vw;
    margin-right: 0vw;

    height: 50.3125rem;
    align-items: center;
  }
  .script1,
  .script3 {
    flex-direction: column;
    width: 48rem;
  }
  .script2 {
    justify-content: flex-end;
    flex-direction: column-reverse;
    width: 48rem;
  }
  .scripttext1,
  .scripttext2,
  .scripttext3 {
    justify-content: flex-start;
    align-items: center;
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    height: 41.4vw;
    display: flex;
    flex-direction: column;
  }
  .scripttext1 {
    align-self: center;
  }
  .scripttext2 > h1,
  .scripttext3 > h1 {
    width: 100%;
  }
  .scripttitle {
    font-size: 5.5vw;
    width: 50%;
    line-height: 6.8vw;
    margin-bottom: 6.3vw;
    text-align: center;
    display: flex;
    justify-content: center;
    place-self: center;
  }
  .scriptcontext {
    font-size: 3.1vw;
    text-align: center;
    line-height: 4.4vw;
    place-self: center;
    width: 72.9vw;
  }
  .scriptimg1,
  .scriptimg2,
  .scriptimg3 {
    margin-bottom: 5.2vw;
    width: 72.9vw;
    height: 55.9vw;
    margin-left: 0vw;
  }

  .footercontainer {
    height: 45.6vw;
    justify-content: flex-start;
  }
  .footercontainer > h1 {
    font-size: 3.9vw;
    margin-bottom: 2.6vw;
    margin-top: 9.8vw;
  }
  .footercontext > p {
    font-size: 2.6vw;
    line-height: 3.1vw;
    width: 61.2vw;
  }
  .star1 {
    width: 15.2vw;
    height: 15.2vw;
    right: 5.6vw;
    top: 27.5vw;
  }
  .star2 {
    top: 20.2vw;
    width: 9.4vw;
    height: 13.9vw;
    left: 12.1vw;
  }
  .star3 {
    width: 20.1vw;
    height: 20.1vw;

    top: 1.7vw;
    left: 2.5vw;
  }
}
/*mobile*/
@media all and (max-width: 480px) {
  .topmovebutton {
    display: none;
  }
  .header {
    position: static;
    padding-bottom: 0px;
    background-color: transparent;
  }
  .title {
    margin-left: 8.3vw;
    padding-top: 6vw;
    font-size: 1.5625rem;
  }

  .button {
    width: 32vw;
    height: 8vw;
    margin-right: 2.5rem;
    margin-top: 10px;
    font-size: 3.2vw;
    visibility: hidden;
  }

  .gridcontainer {
    padding-top: 0px;
    height: 230vw;
    padding-bottom: 0px;
    overflow: hidden;
    margin-top: 0;
    width: 100vw;
  }
  .center {
    margin-left: -30vw;
  }
  .center > h1 {
    font-size: 5.8vw;
    margin-bottom: 10.4vw;
  }
  .centerbutton {
    width: 53.5vw;
    height: 12.9vw;
    border-radius: 8.5vw;
    font-size: 4.6vw;
  }
  .imgarea1 {
    visibility: hidden;
  }

  .imgarea2 {
    width: 37.1vw;
    height: 37.1vw;
    border-radius: 5.4vw;
    left: -45vw;
    margin-right: 5vw;
  }
  .imgarea3 {
    width: 22.1vw;
    height: 22.1vw;
    left: -45vw;
    border-radius: 4.8vw;
    margin-right: 15.4vw;
  }
  .imgarea4 {
    width: 31vw;
    height: 31vw;
    left: -45vw;
    border-radius: 5.8vw;
  }
  .imgarea5 {
    width: 55.4vw;
    height: 55.4vw;
    left: -30vw;
    top: 40vw;
    border-radius: 5.8vw;
  }
  .imgarea6 {
    width: 49.8vw;
    height: 49.8vw;
    border-radius: 10.4vw;
    margin-left: -18vw;
    left: -25vw;
  }
  .imgarea7 {
    visibility: hidden;
  }

  .imgarea8 {
    visibility: hidden;
  }
  .imgarea9 {
    width: 47.1vw;
    height: 47.1vw;
    border-radius: 9.4vw;
    left: 0;
    margin-left: -60vw;
  }
  .imgarea10 {
    width: 23.3vw;
    height: 23.3vw;
    left: 0;
    margin-left: -40vw;
  }
  .imgarea11 {
    width: 67.5vw;
    height: 67.5vw;
    left: 0;
    margin-left: -40vw;
  }
  .scriptcontainer {
    height: 551.7vw;
    width: 100vw;
    grid-template-columns: repeat(1, 30rem);
    grid-template-rows: repeat(3, 148.5vw);

    overflow: hidden;
    row-gap: 27.1vw;
  }

  .script1,
  .script2,
  .script3 {
    width: 100%;
  }
  .scripttext1,
  .scripttext2,
  .scripttext3 {
    margin-left: 0;
  }
  .scripttext2 > h1 {
    width: 83.3vw;
  }
  .scripttitle {
    font-size: 7.7vw;
    margin-bottom: 6.3vw;
    width: 83.3vw;
    line-height: 10.8vw;
  }
  .scriptcontext {
    width: 83.3vw;
    font-size: 4.6vw;
    margin-right: 0;
    align-self: center;
    line-height: 7.1vw;
  }
  .scriptimg1,
  .scriptimg2,
  .scriptimg3 {
    width: 83.3vw;
    height: 63.8vw;
    margin-top: 0px;
    margin-bottom: 6.5vw;
  }
  .footercontainer {
    width: 100vw;
    height: 99.2vw;
    justify-content: start;
  }
  .footercontainer > h1 {
    font-size: 6.3vw;
    width: 80vw;
    padding: 0;
    line-height: 8.5vw;
    margin-bottom: 6.3vw;
    margin-top: 16.7vw;
  }

  .footercontext > p {
    width: 83.3vw;
    font-size: 4.8vw;
    line-height: 6vw;
  }
  .star1 {
    top: 63.3vw;
    right: 3.5vw;
    width: 24.4vw;
    height: 24.4vw;
  }
  .star2 {
    top: 30.4vw;
    left: 12.3vw;
    width: 15.2vw;
    height: 22.3vw;
  }
  .star3 {
    top: 1.3vw;
    left: -6.7vw;
    width: 25.4vw;
    height: 32.1vw;
  }
}
