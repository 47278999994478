.container {
  background: #f4f1ef;
  height: 2100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.formcontainer {
  width: 68.75rem;
  height: 100rem;
  background: white;
  margin-top: 14.125rem;
  margin-bottom: 16.6875rem;
  border-radius: 5.75rem;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 3px 27px #00000029;
}
.bottomextension {
  height: 110rem;
}
.title {
  margin-top: 6.1875rem;
  margin-bottom: 4rem;
}
.title > h1 {
  font-family: "Roboto";
  font-weight: 700;
  font-size: 3rem;
}

.subtitle {
  margin-left: 5.4375rem;
  margin-bottom: 1.6875rem;
}
.subtitle > h1 {
  font-family: "Roboto";
  font-weight: 700;
  font-size: 1.6875rem;
  text-align: start;
}
.gametitlealertmessage,
.mailalertmessage {
  color: #e31414;
  font-family: "Roboto";
  font-weight: 700;
  font-size: 1.125rem;
}
.gametitleinput {
  display: inline;
}

.inputcontainer {
  margin: 0 5.4375rem;
}
.gametitlecontainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2.0625rem;
}
.gametitle {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.gametitle > h1 {
  font-family: "Roboto";
  font-weight: 700;
  font-size: 1.1875rem;
  color: #767676;
  margin-bottom: 0.9375rem;
}

.gametitle > h1::after {
  content: "*";
  color: #e31414;
}
.gametitle > input {
  width: 26.9375rem;
  height: 3.6875rem;
  border-radius: 0.625rem;
  border: solid 1px #707070;
  font-size: 1.125rem;
  color: #484848;
  font-family: "Roboto";
  font-weight: 700;
  margin-bottom: 0.3125rem;
  padding-left: 15px;
}
.gametitle > input:focus {
  border: white;
  outline: none;
  box-shadow: 0 0 0 3px #f7931e;
}

.gamelink {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.gamelink > h1 {
  font-family: "Roboto";
  font-weight: 700;
  font-size: 1.1875rem;
  color: #767676;
  margin-bottom: 0.9375rem;
}
.gamelink > input {
  width: 26.9375rem;
  height: 3.6875rem;
  border-radius: 0.625rem;
  border: solid 1px #707070;
  font-size: 1.125rem;
  color: #484848;
  font-family: "Roboto";
  font-weight: 700;
  padding-left: 15px;
}
.gamelink > input:focus {
  border: white;
  outline: none;
  box-shadow: 0 0 0 3px #f7931e;
}
.aboutgame {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 2.8125rem;
}
.aboutgame > h1 {
  font-family: "Roboto";
  font-weight: 700;
  font-size: 1.1875rem;
  color: #767676;
  margin-bottom: 0.9375rem;
}
.aboutgame > textarea {
  width: 57rem;
  height: 12.4375rem;
  border-radius: 0.625rem;
  border: solid 1px #707070;
  font-size: 1.5rem;
  color: #767676;
  font-family: "Roboto";
  font-weight: 700;
  padding-left: 15px;
  padding-top: 15px;
}
.aboutgame > textarea:focus {
  border: white;
  outline: none;
  box-shadow: 0 0 0 3px #f7931e;
}
.experiencecontainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 3.40625rem;
}
.experience {
  display: flex;
}
.experience > h1 {
  font-size: 1.1875rem;
  color: #767676;
  align-self: center;
}
.button {
  display: flex;
  margin-right: 3.65vw;
  margin-bottom: 0rem;
}
.yesbutton {
  display: flex;
  font-family: "Roboto";
  font-weight: 700;
  width: 8.625rem;
  height: 3.1875rem;
  color: #767676;
  border-radius: 2.125rem;
  border: 1px solid #707070;
  font-size: 1.3125rem;
  outline: none;
  background: white;
  justify-content: center;
  align-items: center;
}
.yesbutton:hover {
  background-color: #f7931e;
  color: white;
  cursor: pointer;
  border-color: #f7931e;
}
.nobutton {
  display: flex;
  font-family: "Roboto";
  font-weight: 700;
  width: 8.625rem;
  height: 3.1875rem;
  color: #767676;
  border-radius: 2.125rem;
  border: 1px solid #707070;
  font-size: 1.3125rem;
  outline: none;
  background: white;
  justify-content: center;
  align-items: center;
  margin-left: 1.0625rem;
}
.nobutton:hover {
  background-color: #f7931e;
  color: white;
  cursor: pointer;
  border-color: #f7931e;
}
.attachlink {
  display: none;
  /* display: flex; */
  justify-content: space-between;
  margin-bottom: 5rem;
}
.attachtext {
  color: #767676;
  font-size: 1.1875rem;
  align-self: center;
}
.attachlink > input {
  width: 26.9375rem;
  height: 3.6875rem;
  border-radius: 0.625rem;
  border: solid 1px #707070;
  font-size: 1.125rem;
  color: #484848;
  font-family: "Roboto";
  font-weight: 700;
  padding-left: 15px;
}
.attachlink > input:focus {
  border: white;
  outline: none;
  box-shadow: 0 0 0 3px #f7931e;
}

.teamcontainer > h1 {
  font-family: "Roboto";
  font-weight: 700;
  font-size: 1.6875rem;
  text-align: start;
  margin-bottom: 1.6875rem;
  border-top-style: dashed;
  border-top-color: #909090;
  border-top-width: 1px;
  padding-top: 3.84375rem;
}
.nameandportfoliolink {
  display: flex;
  justify-content: space-between;

  margin-bottom: 2.125rem;
}
.fullname {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.fullname > h1 {
  font-family: "Roboto";
  font-weight: 700;
  font-size: 1.1875rem;
  color: #767676;
  margin-bottom: 0.9375rem;
}
.fullname > input {
  width: 26.9375rem;
  height: 3.6875rem;
  border-radius: 0.625rem;
  border: solid 1px #707070;
  font-size: 1.125rem;
  color: #484848;
  font-family: "Roboto";
  font-weight: 700;
  margin-bottom: 0.3125rem;
  padding-left: 15px;
}
.fullname > input:focus {
  border: white;
  outline: none;
  box-shadow: 0 0 0 3px #f7931e;
}
.portfoliolink {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.portfoliolink > h1 {
  font-family: "Roboto";
  font-weight: 700;
  font-size: 1.1875rem;
  color: #767676;
  margin-bottom: 0.9375rem;
}
.portfoliolink > input {
  width: 26.9375rem;
  height: 3.6875rem;
  border-radius: 0.625rem;
  border: solid 1px #707070;
  font-size: 1.125rem;
  color: #484848;
  font-family: "Roboto";
  font-weight: 700;
  margin-bottom: 0.3125rem;
  padding-left: 15px;
}
.portfoliolink > input:focus {
  border: white;
  outline: none;
  box-shadow: 0 0 0 3px #f7931e;
}
.studionameandteamsize {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2.125rem;
}
.studioname {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.studioname > h1 {
  font-family: "Roboto";
  font-weight: 700;
  font-size: 1.1875rem;
  color: #767676;
  margin-bottom: 0.9375rem;
}
.studioname > input {
  width: 26.9375rem;
  height: 3.6875rem;
  border-radius: 0.625rem;
  border: solid 1px #707070;
  font-size: 1.125rem;
  color: #484848;
  font-family: "Roboto";
  font-weight: 700;
  margin-bottom: 0.3125rem;
  padding-left: 15px;
}
.studioname > input:focus {
  border: white;
  outline: none;
  box-shadow: 0 0 0 3px #f7931e;
}
.teamsize {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.teamsize > h1 {
  font-family: "Roboto";
  font-weight: 700;
  font-size: 1.1875rem;
  color: #767676;
  margin-bottom: 0.9375rem;
}
.teamsize > input {
  width: 26.9375rem;
  height: 3.6875rem;
  border-radius: 0.625rem;
  border: solid 1px #707070;
  font-size: 1.125rem;
  color: #484848;
  font-family: "Roboto";
  font-weight: 700;
  margin-bottom: 0.3125rem;
  padding-left: 15px;
}
.teamsize > input:focus {
  border: white;
  outline: none;
  box-shadow: 0 0 0 3px #f7931e;
}

.email {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 4.9375rem;
}
.email > h1 {
  font-family: "Roboto";
  font-weight: 700;
  font-size: 1.1875rem;
  color: #767676;
  margin-bottom: 0.9375rem;
}
.email > h1::after {
  content: "*";
  color: #e31414;
}
.email > input {
  width: 26.9375rem;
  height: 3.6875rem;
  border-radius: 0.625rem;
  border: solid 1px #707070;
  font-size: 1.125rem;
  color: #484848;
  font-family: "Roboto";
  font-weight: 700;
  margin-bottom: 0.3125rem;
  padding-left: 15px;
}
.emailinput {
}
.email > input:focus {
  border: white;
  outline: none;
  box-shadow: 0 0 0 3px #f7931e;
}
.submitbutton {
  font-family: "SpoqaHanSans";
  font-weight: bold;
  width: 15.625rem;
  height: 4.125rem;
  font-size: 1.375rem;
  background: #da392b;
  box-shadow: 0px 3px 6px #da392b79;
  border-radius: 66px;
  outline: none;
  color: white;
  border: none;
  align-self: center;
}
.submitbutton:hover {
  cursor: pointer;
}
/*17in*/
@media screen and (max-width: 1440px) {
  .container {
    height: 1920px;
  }
  .formcontainer {
    width: 56.25rem;
    height: 85rem;
    margin-top: 9.875rem;
    margin-bottom: 20rem;
  }
  .bottomextension {
    height: 90rem;
  }
  .title {
    margin-top: 5.125rem;
    margin-bottom: 3.25rem;
  }
  .title > h1 {
    font-family: "Roboto";
    font-weight: 700;
    font-size: 2.4375rem;
  }

  .subtitle {
    margin-left: 4.4375rem;
    margin-bottom: 1.5625rem;
  }
  .subtitle > h1 {
    font-size: 1.3125rem;
  }
  .gametitlealertmessage,
  .mailalertmessage {
    font-size: 1rem;
  }
  .inputcontainer {
    margin: 0 4.4375rem;
  }
  .gametitlecontainer {
    margin-bottom: 1.6875rem;
  }

  .gametitle > h1 {
    font-size: 0.9375rem;
    margin-bottom: 0.8125rem;
  }

  .gametitle > input {
    width: 22.0625rem;
    height: 3rem;
    font-size: 0.875rem;
    padding-left: 15px;
  }

  .gamelink {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .gamelink > h1 {
    font-size: 0.9375rem;
    margin-bottom: 0.8125rem;
  }
  .gamelink > input {
    width: 22.0625rem;
    height: 3rem;
    font-size: 0.875rem;
    padding-left: 15px;
  }

  .aboutgame {
    margin-bottom: 2.25rem;
  }
  .aboutgame > h1 {
    font-family: "Roboto";
    font-weight: 700;
    font-size: 0.9375rem;
    color: #767676;
    margin-bottom: 0.8125rem;
  }
  .aboutgame > textarea {
    width: 46.5rem;
    height: 10.1875rem;
    border-radius: 0.625rem;
    border: solid 1px #707070;
    font-size: 1.5rem;
    color: #767676;
    font-family: "Roboto";
    font-weight: 700;
    padding-left: 15px;
    padding-top: 15px;
  }

  .experiencecontainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2.75rem;
  }
  .experience {
    display: flex;
  }
  .experience > h1 {
    font-size: 0.9375rem;
    color: #767676;
    align-self: center;
  }
  .button {
    display: flex;
    margin-right: 3.65vw;
  }
  .yesbutton {
    width: 7.0625rem;
    height: 2.6875rem;
    font-size: 1.0625rem;
  }

  .nobutton {
    width: 7.0625rem;
    height: 2.6875rem;
    font-size: 1.0625rem;
    margin-left: 0.875rem;
  }
  .nobutton:hover {
    background-color: #f7931e;
    color: white;
    cursor: pointer;
    border-color: #f7931e;
  }
  .attachlink {
    margin-bottom: 2.75rem;
  }
  .attachtext {
    color: #767676;
    font-size: 0.9375rem;
    align-self: center;
  }
  .attachlink > input {
    width: 22.0625rem;
    height: 3rem;
    font-size: 0.875rem;
    padding-left: 15px;
  }

  .teamcontainer > h1 {
    font-size: 1.3125rem;
    margin-bottom: 1.5625rem;
    padding-top: 3.0625rem;
  }
  .nameandportfoliolink {
    margin-bottom: 2.125rem;
  }

  .fullname > h1 {
    font-size: 0.9375rem;
    margin-bottom: 0.8125rem;
  }
  .fullname > input {
    width: 22.0625rem;
    height: 3rem;
    font-size: 0.875rem;
    padding-left: 15px;
  }

  .portfoliolink > h1 {
    font-size: 0.9375rem;
    margin-bottom: 0.8125rem;
  }
  .portfoliolink > input {
    width: 22.0625rem;
    height: 3rem;
    font-size: 0.875rem;
    padding-left: 15px;
  }

  .studionameandteamsize {
    margin-bottom: 1.6875rem;
  }

  .studioname > h1 {
    font-size: 0.9375rem;
    margin-bottom: 0.8125rem;
  }
  .studioname > input {
    width: 22.0625rem;
    height: 3rem;
    font-size: 0.875rem;
    padding-left: 15px;
  }

  .teamsize > h1 {
    font-size: 0.9375rem;
    margin-bottom: 0.8125rem;
  }
  .teamsize > input {
    width: 22.0625rem;
    height: 3rem;
    font-size: 0.875rem;
    padding-left: 15px;
  }

  .email {
    margin-bottom: 4rem;
  }
  .email > h1 {
    font-size: 0.9375rem;
    margin-bottom: 0.8125rem;
  }

  .email > input {
    width: 22.0625rem;
    height: 3rem;
    font-size: 0.875rem;
    padding-left: 15px;
  }

  .submitbutton {
    font-family: "SpoqaHanSans";
    font-weight: bold;
    width: 12.75rem;
    height: 3.375rem;
    font-size: 1.0625rem;
  }
  .submitbutton:hover {
    cursor: pointer;
  }
}
/*15in*/
@media screen and (max-width: 1280px) {
  .container {
    height: 1650px;
  }
  .formcontainer {
    width: 50rem;
    height: 80rem;
    margin-top: 10rem;
    margin-bottom: 10.5625rem;
    padding-bottom: 3.3125rem;
  }
  .bottomextension {
    height: 85rem;
  }
  .title {
    margin-top: 5.3125rem;
    margin-bottom: 3.625rem;
  }
  .title > h1 {
    font-family: "Roboto";
    font-weight: 700;
    font-size: 2.125rem;
  }

  .subtitle {
    margin-left: 5rem;
    margin-bottom: 1.5rem;
  }
  .subtitle > h1 {
    font-size: 1.3125rem;
  }
  .gametitlealertmessage,
  .mailalertmessage {
    font-size: 0.8rem;
  }
  .inputcontainer {
    margin: 0 5rem;
  }
  .gametitlecontainer {
    margin-bottom: 1.875rem;
  }

  .gametitle > h1 {
    font-size: 0.9375rem;
    margin-bottom: 0.625rem;
  }

  .gametitle > input {
    width: 18.125rem;
    height: 3rem;
    font-size: 0.875rem;
    padding-left: 15px;
  }

  .gamelink > h1 {
    font-size: 0.9375rem;
    margin-bottom: 0.625rem;
  }
  .gamelink > input {
    width: 18.125rem;
    height: 3rem;
    font-size: 0.875rem;
    padding-left: 15px;
  }

  .aboutgame {
    margin-bottom: 2.5625rem;
  }
  .aboutgame > h1 {
    font-size: 0.9375rem;
    margin-bottom: 0.625rem;
  }
  .aboutgame > textarea {
    width: 39rem;
    height: 10.5625rem;
    font-size: 0.875rem;
    padding-left: 15px;
    padding-top: 15px;
  }

  .experiencecontainer {
    margin-bottom: 2.75rem;
  }
  .experience {
    width: 100%;
  }
  .experience > h1 {
    text-align: start;
    font-size: 0.9375rem;
    width: 23rem;
  }
  .button {
    margin-right: 0vw;
  }
  .yesbutton {
    width: 6.0625rem;
    height: 2.3125rem;
    border-radius: 2.125rem;
    font-size: 1.0625rem;
  }

  .nobutton {
    width: 6.0625rem;
    height: 2.3125rem;
    border-radius: 2.125rem;
    font-size: 1.0625rem;
  }
  .nobutton:hover {
    background-color: #f7931e;
    color: white;
    cursor: pointer;
    border-color: #f7931e;
  }
  .attachlink {
    margin-bottom: 2.75rem;
  }
  .attachtext {
    font-size: 0.9375rem;
  }
  .attachlink > input {
    width: 18.125rem;
    height: 3rem;
    font-size: 0.875rem;
    padding-left: 15px;
  }

  .teamcontainer > h1 {
    font-size: 1.3125rem;
    margin-bottom: 1.5rem;
    padding-top: 3.375rem;
  }
  .nameandportfoliolink {
    margin-bottom: 1.875rem;
  }

  .fullname > h1 {
    font-size: 0.9375rem;
    margin-bottom: 0.625rem;
  }
  .fullname > input {
    width: 18.125rem;
    height: 3rem;
    font-size: 0.875rem;
    padding-left: 15px;
  }

  .portfoliolink > h1 {
    font-size: 0.9375rem;
    margin-bottom: 0.625rem;
  }
  .portfoliolink > input {
    width: 18.125rem;
    height: 3rem;
    font-size: 0.875rem;
    padding-left: 15px;
  }

  .studionameandteamsize {
    margin-bottom: 1.875rem;
  }

  .studioname > h1 {
    font-size: 0.9375rem;
    margin-bottom: 0.625rem;
  }
  .studioname > input {
    width: 18.125rem;
    height: 3rem;
    font-size: 0.875rem;
    padding-left: 15px;
  }

  .teamsize > h1 {
    font-size: 0.9375rem;
    margin-bottom: 0.625rem;
  }
  .teamsize > input {
    width: 18.125rem;
    height: 3rem;
    font-size: 0.875rem;
    padding-left: 15px;
  }

  .email {
    margin-bottom: 3.625rem;
  }
  .email > h1 {
    font-size: 0.9375rem;
    margin-bottom: 0.625rem;
  }

  .email > input {
    width: 18.125rem;
    height: 3rem;
    font-size: 0.875rem;
    padding-left: 15px;
  }

  .submitbutton {
    font-family: "SpoqaHanSans";
    font-weight: bold;
    width: 11.375rem;
    height: 3.375rem;
    font-size: 0.9375rem;
    border-radius: 4.125rem;
  }
  .submitbutton:hover {
    cursor: pointer;
  }
}
/*13in*/
@media screen and (max-width: 1024px) {
  .container {
    height: 1720px;
  }
  .formcontainer {
    width: 49.25rem;
    height: 80rem;
    margin-top: 8.25rem;
    margin-bottom: 8.25rem;
    border-radius: 4rem;
    padding-bottom: 4.375rem;
  }
  .bottomextension {
    height: 86.25rem;
  }
  .gametitlealertmessage,
  .mailalertmessage {
    font-size: 0.8rem;
  }
  .title {
    margin-top: 5.3125rem;
    margin-bottom: 1.5rem;
  }
  .title > h1 {
    font-size: 2.125rem;
  }
  .subtitle {
    margin-left: 4.1875rem;
    margin-bottom: 1.5625rem;
  }
  .subtitle > h1 {
    font-size: 1.375rem;
  }
  .inputcontainer {
    margin-left: 4.1875rem;
  }
  .gametitlecontainer {
    margin-bottom: 2rem;
  }
  .gametitle > h1 {
    font-size: 1rem;
    margin-bottom: 0.75rem;
  }
  .gametitle > input {
    width: 18.5rem;
    height: 3.1875rem;
    font-size: 0.875rem;
  }
  .gamelink > h1 {
    font-size: 1rem;
    margin-bottom: 0.75rem;
  }
  .gamelink > input {
    width: 18rem;
    height: 3.1875rem;
    font-size: 0.875rem;
  }
  .aboutgame {
    margin-bottom: 2.75rem;
  }
  .aboutgame > h1 {
    font-size: 1rem;
    margin-bottom: 0.75rem;
  }
  .aboutgame > textarea {
    width: 39rem;
    height: 10.9375rem;
  }
  .experience > h1 {
    font-size: 1rem;
    width: 100%;
    text-align: start;
  }
  .yesbutton {
    width: 6.5rem;
    height: 2.5rem;
    font-size: 1.125rem;
  }
  .nobutton {
    width: 6.5rem;
    height: 2.5rem;
    font-size: 1.125rem;
  }
  .attachlink {
    margin-bottom: 3.625rem;
  }
  .attachlink > h1 {
    font-size: 1rem;
  }
  .attachlink > input {
    width: 18rem;
    height: 3.1875rem;
    font-size: 0.875rem;
  }
  .teamcontainer > h1 {
    font-size: 1.5rem;
    padding-top: 3.625rem;
    margin-bottom: 1.5625rem;
    font-size: 1.375rem;
  }
  .nameandportfoliolink {
    margin-bottom: 1.9375rem;
  }
  .fullname > h1 {
    font-size: 1rem;
    margin-bottom: 0.75rem;
  }
  .fullname > input {
    width: 18.5rem;
    height: 3.1875rem;
    font-size: 0.875rem;
  }
  .portfoliolink > h1 {
    font-size: 1rem;
    margin-bottom: 0.75rem;
  }
  .portfoliolink > input {
    width: 18.5rem;
    height: 3.1875rem;
    font-size: 0.875rem;
  }
  .studionameandteamsize {
    margin-bottom: 1.9375rem;
  }
  .studioname > h1 {
    font-size: 1rem;
    margin-bottom: 0.75rem;
  }
  .studioname > input {
    width: 18.5rem;
    height: 3.1875rem;
    font-size: 0.875rem;
  }
  .teamsize > h1 {
    font-size: 1rem;
    margin-bottom: 0.75rem;
  }
  .teamsize > input {
    width: 18.5rem;
    height: 3.1875rem;
    font-size: 0.875rem;
  }
  .email {
    margin-bottom: 0;
  }
  .email > h1 {
    font-size: 1rem;
    margin-bottom: 0.75rem;
  }
  .email > input {
    width: 18.5rem;
    height: 3.1875rem;
    font-size: 0.875rem;
  }
  .submitbutton {
    width: 13.625rem;
    height: 3.625rem;
    border-radius: 4.125rem;
    font-size: 1.125rem;
    margin-top: 3.75rem;
    margin-bottom: 0;
  }
}
/*tablet*/
@media all and (max-width: 768px) {
  .container {
    height: 270vw;
  }
  .formcontainer {
    width: 87vw;
    height: 215vw;
    margin-bottom: 17.1vw;
    margin-top: 17.1vw;
    padding-bottom: 7.8vw;
    border-radius: 10vw;
  }
  .bottomextension {
    height: 230vw;
  }
  .subtitle {
    margin-left: 7vw;
  }
  .inputcontainer {
    margin: 0 7vw 0 7vw;
  }
  .gametitlealertmessage,
  .mailalertmessage {
    font-size: 1.5vw;
  }
  .title {
    margin-top: 8.3vw;
    margin-bottom: 7.3vw;
  }
  .title > h1 {
    font-size: 4.4vw;
  }
  .subtitle > h1 {
    font-size: 2.9vw;
  }
  .gametitlecontainer {
    margin-bottom: 2.9vw;
    flex-direction: column;
  }
  .gametitle > h1 {
    font-size: 2.1vw;
    margin-bottom: 1.6vw;
  }
  .gametitle > input {
    width: 71.5vw;
    height: 6.6vw;
    font-size: 1.8vw;
  }
  .gamelink > h1 {
    font-size: 2.1vw;
    margin-bottom: 1.6vw;
  }
  .gamelink > input {
    width: 71.5vw;
    height: 6.6vw;
    font-size: 1.8vw;
  }
  .aboutgame {
    margin-bottom: 2.9vw;
  }
  .aboutgame > h1 {
    font-size: 2.1vw;
    margin-bottom: 1.6vw;
  }
  .aboutgame > textarea {
    width: 71.5vw;
    height: 28vw;
    font-size: 1.8vw;
  }
  .experiencecontainer {
    flex-direction: column;
    margin-bottom: 4.7vw;
  }
  .experience > h1 {
    font-size: 2.1vw;
    margin-bottom: 2.1vw;
    width: 100%;
    text-align: start;
  }
  .yesbutton {
    width: 13.5vw;
    height: 5.2vw;
    font-size: 2.3vw;
  }
  .nobutton {
    width: 13.5vw;
    height: 5.2vw;
    font-size: 2.3vw;
  }
  .attachlink {
    flex-direction: column;
    margin-bottom: 4.7vw;
  }
  .attachlink > h1 {
    font-size: 2.1vw;
    align-self: flex-start;
    margin-bottom: 2.2vw;
  }
  .attachlink > input {
    width: 71.5vw;
    height: 6.6vw;
    font-size: 1.8vw;
  }

  .teamcontainer > h1 {
    font-size: 2.9vw;
    padding-top: 4.7vw;
  }
  .nameandportfoliolink {
    flex-direction: column;
    margin-bottom: 2.6vw;
  }
  .fullname > h1 {
    font-size: 2.1vw;
    margin-bottom: 1.6vw;
  }
  .fullname > input {
    width: 71.5vw;
    height: 6.6vw;
    font-size: 1.8vw;
    margin-bottom: 2.3vw;
  }
  .portfoliolink > h1 {
    font-size: 2.1vw;
    margin-bottom: 1.6vw;
  }
  .portfoliolink > input {
    width: 71.5vw;
    height: 6.6vw;
    font-size: 1.8vw;
  }
  .studionameandteamsize {
    flex-direction: column;
    margin-bottom: 2.6vw;
  }
  .studioname > h1 {
    font-size: 2.1vw;
    margin-bottom: 1.6vw;
  }
  .studioname > input {
    width: 71.5vw;
    height: 6.6vw;
    font-size: 1.8vw;
    margin-bottom: 2.3vw;
  }

  .teamsize > h1 {
    font-size: 2.1vw;
    margin-bottom: 1.6vw;
  }
  .teamsize > input {
    width: 71.5vw;
    height: 6.6vw;
    font-size: 1.8vw;
  }
  .email {
    margin-bottom: 7.9vw;
  }
  .email > h1 {
    font-size: 2.1vw;
    margin-bottom: 1.6vw;
  }
  .email > input {
    width: 71.5vw;
    height: 6.6vw;
    font-size: 1.8vw;
  }
  .submitbutton {
    margin-top: 0;
    width: 28.4vw;
    height: 7.6vw;
    font-size: 2.3vw;
    justify-content: center;
  }
}
/*mobile*/
@media all and (max-width: 480px) {
  .container {
    width: 100vw;
    height: 404.2vw;
    align-items: center;
  }
  .formcontainer {
    width: 91.7vw;
    height: 350vw;
    margin-bottom: 0vw;
    margin-top: 0vw;
    padding-bottom: 0vw;
    border-radius: 3.3vw;
  }
  .bottomextension {
    height: 370vw;
  }
  .gametitlealertmessage,
  .mailalertmessage {
    font-size: 3vw;
  }
  .title {
    margin-top: 13.3vw;
    margin-bottom: 13.8vw;
  }
  .title > h1 {
    font-size: 5.8vw;
  }
  .subtitle {
    margin-left: 9.2vw;
    margin-bottom: 4.6vw;
  }
  .subtitle > h1 {
    font-size: 4.6vw;
  }
  .inputcontainer {
    margin: 0 9.2vw 0 9.2vw;
  }
  .gametitlecontainer {
    margin-bottom: 6.3vw;
    flex-direction: column;
  }
  .gametitle > h1 {
    font-size: 3.3vw;
    margin-bottom: 1.7vw;
  }
  .gametitle > input {
    width: 71vw;
    height: 10.4vw;
    font-size: 3.3vw;
  }
  .gamelink > h1 {
    font-size: 3.3vw;
    margin-bottom: 1.7vw;
    margin-top: 6.3vw;
  }
  .gamelink > input {
    width: 71vw;
    height: 10.4vw;
    font-size: 3.3vw;
  }
  .aboutgame {
    margin-bottom: 6.3vw;
  }
  .aboutgame > h1 {
    font-size: 3.3vw;
    margin-bottom: 1.7vw;
  }
  .aboutgame > textarea {
    width: 71vw;
    height: 44.8vw;
    font-size: 3.3vw;
  }
  .experiencecontainer {
    flex-direction: column;
    margin-bottom: 3.8vw;
  }
  .experience > h1 {
    font-size: 3vw;
    margin-bottom: 2.9vw;
    width: 100%;
    text-align: start;
  }
  .yesbutton {
    width: 18.3vw;
    height: 7.1vw;
    border-radius: 7.1vw;
    font-size: 3.1vw;
  }
  .nobutton {
    width: 18.3vw;
    height: 7.1vw;
    border-radius: 7.1vw;
    font-size: 3.1vw;
  }
  .attachlink {
    flex-direction: column;
    margin-bottom: 6vw;
  }
  .attachlink > h1 {
    font-size: 3.3vw;
    align-self: flex-start;
    margin-bottom: 1.7vw;
  }
  .attachlink > input {
    width: 71vw;
    height: 10.4vw;
    font-size: 3.3vw;
  }

  .teamcontainer > h1 {
    width: 76vw;
    font-size: 4.6vw;
    padding-top: 6vw;
  }
  .nameandportfoliolink {
    flex-direction: column;
    margin-bottom: 6.3vw;
  }
  .fullname > h1 {
    font-size: 3.3vw;
    margin-bottom: 1.7vw;
  }
  .fullname > input {
    width: 71vw;
    height: 10.4vw;
    font-size: 3.3vw;
    margin-bottom: 6.3vw;
  }
  .portfoliolink > h1 {
    font-size: 3.3vw;
    margin-bottom: 1.7vw;
  }
  .portfoliolink > input {
    width: 71vw;
    height: 10.4vw;
    font-size: 3.3vw;
  }
  .studionameandteamsize {
    flex-direction: column;
    margin-bottom: 6.3vw;
  }
  .studioname > h1 {
    font-size: 3.3vw;
    margin-bottom: 1.7vw;
  }
  .studioname > input {
    width: 71vw;
    height: 10.4vw;
    font-size: 3.3vw;
    margin-bottom: 6.3vw;
  }

  .teamsize > h1 {
    font-size: 3.3vw;
    margin-bottom: 1.7vw;
  }
  .teamsize > input {
    width: 71vw;
    height: 10.4vw;
    font-size: 3.3vw;
  }
  .email {
    margin-bottom: 11.5vw;
  }
  .email > h1 {
    font-size: 3.3vw;
    margin-bottom: 1.7vw;
  }
  .email > input {
    width: 71vw;
    height: 10.4vw;
    font-size: 3.3vw;
  }
  .submitbutton {
    margin-top: 0;
    width: 45.4vw;
    height: 12.1vw;
    font-size: 3.8vw;
  }
}
