@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@500;700&display=swap");

.container {
  display: flex;
  background-color: #da392b;
  height: 44.46vw;
  flex-direction: column;
  align-items: center;

  padding-bottom: 0px;
}

.title {
  margin: 0;
  padding-top: 0rem;
  font-size: 2.83vw;
  color: white;
  font-family: "Roboto";
  font-weight: 700;
  align-self: center;
}
.gamelist {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(5, 14.9vw);
  column-gap: 1.77vw;
  justify-content: center;
  padding-top: 5.5625rem;
}

.game {
  display: flex;
  flex-direction: column;
}
.game_icon {
  width: 14.9vw;
  height: 14.9vw;
  border-radius: 2.6vw;
  align-self: center;
}
.gametitle {
  color: #ffffff;
  font-family: "Roboto";
  font-weight: 700;
  font-size: 1.22vw;
  margin-top: 24.984px;
  margin-bottom: 6.246px;
}
.game_link {
  margin-top: 21.861px;
}
.game_link a {
  margin: 5px;
}
.apple {
  width: 6.4vw;
  max-width: 123px;
  height: 2.11vw;
  max-height: 40.599px;
}
.google {
  width: 6.4vw;
  max-width: 123px;
  height: 2.11vw;
  max-height: 40.599px;
}

.download {
  font-family: "Roboto";
  font-weight: 500;
  margin-bottom: 0;
  color: #ffcbcb;
  font-size: 1.02vw;
}
.hideview {
  display: none;
}

.buttonarea {
  background-color: #da392b;
  display: flex;
  justify-content: center;
  padding-bottom: 170.724px;
  margin-top: -0.1vh;
}
.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 14.4vw;
  height: 4.01vw;
  background-color: #ffffff;
  border-radius: 4.125rem;
  font-size: 1.4vw;
  font-weight: bold;
  color: #da392b;
  text-decoration: none;
  font-family: "Roboto";
  font-weight: 700;
  border: none;
}

.button:hover {
  background: #ffffff4e 0% 0% no-repeat padding-box;
  border: 3px solid #ffffff;
  color: #ffffff;
  cursor: pointer;
  margin-bottom: -6px;
}
.button::after {
  margin-left: 5%;
}
.button:hover::after {
  margin-left: 5%;
}
.zigzag {
  position: relative;
  top: -52.05px;
  width: 100%;
  transform: scaleY(-1);
}
.zigzag img {
  width: 100%;
}

/*17in*/
@media all and (max-width: 1440px) {
  .container {
    height: 43vw;
    padding-bottom: 7.92vw;
  }
  .gamelist {
    grid-template-columns: repeat(5, 16vw);
    column-gap: 0vw;
  }
  .game {
    padding: 10px;
  }
  .game_icon {
    width: 12.92vw;
    height: 12.92vw;
    border-radius: 2.5vw;
  }
  .gametitle {
    font-size: 1.74vw;
  }
  .gamelist {
    padding-top: 4.5rem;
  }
  .game_link {
    margin-top: 21.861px;
    display: grid;
    grid-template-columns: repeat(1, 8.89vw);
    grid-template-rows: repeat(2, 2.71vw);
    row-gap: 9px;
    justify-self: center;
    place-content: center;
  }
  .game_link > a {
    margin: 0;
  }
  .download {
    font-size: 1.46vw;
  }
  .buttonarea {
    margin-top: -2%;
    padding-bottom: 9.1vw;
  }

  .apple {
    width: 8.89vw;
    height: 2.71vw;
    margin-bottom: 0.2rem;
  }
  .google {
    width: 8.89vw;
    height: 2.71vw;
  }
  .button {
    width: 18.75vw;
    height: 4.93vw;
    font-size: 1.74vw;
  }
}

/*15in*/
@media all and (max-width: 1280px) {
  .container {
    height: 48.73vw;
  }
  .gamelist {
    grid-template-columns: repeat(5, 16vw);
    column-gap: 2.34vw;
  }
  .game_icon {
    width: 16.17vw;
    height: 16.17vw;
  }
  .gametitle {
    font-size: 1.95vw;
  }
  .game_link {
    grid-template-columns: repeat(1, 10vw);
    grid-template-rows: repeat(2, 3.05vw);
    row-gap: 7px;
  }
  .download {
    font-size: 1.64vw;
  }
  .apple {
    width: 10vw;
    height: 3.05vw;
  }
  .google {
    width: 10vw;
    height: 3.05vw;
  }
  .button {
    width: 21.09vw;
    height: 5.55vw;
    font-size: 1.95vw;
  }
}
/*13in*/
@media all and (max-width: 1024px) {
  .container {
    height: 55.7vw;
  }
  .title {
    margin-top: 0;
    margin-bottom: 0px;
    font-size: 4.69vw;
  }
  .gamelist {
    grid-template-columns: repeat(5, 18.5vw);
    column-gap: 1.56vw;
    margin-top: 5.86vw;
    padding-top: 0;
  }
  .game_icon {
    width: 18.07vw;
    height: 18.07vw;
  }
  .gametitle {
    font-size: 2.44vw;
  }
  .download {
    font-size: 1.76vw;
  }
  .game_link {
    grid-template-columns: repeat(1, 10.74vw);
    grid-template-rows: repeat(2, 3.22vw);
    row-gap: 5px;
  }
  .apple {
    width: 10.74vw;
    height: 3.22vw;
  }
  .google {
    width: 10.74vw;
    height: 3.22vw;
  }
  .button {
    width: 24.61vw;
    height: 6.54vw;
    font-size: 2.25vw;
    margin-bottom: 10.74vw;
  }
  .button:hover {
    margin-bottom: 10.1vw;
  }
  .zigzag {
    top: -5.08vw;
  }
}
/*tablet*/
@media all and (max-width: 768px) {
  .container {
    padding-bottom: 34.5rem;
  }
  .zigzag {
    top: -4.56vw;
  }

  .title {
    padding-top: 3rem;
    font-size: 6.25vw;
  }
  .game_icon {
    width: 22.92vw;
    height: 22.92vw;
    border-radius: 4.69vw;
  }
  .gametitle {
    width: 23.18vw;
    font-size: 3.26vw;
    place-self: center;
  }
  .game_link {
    grid-template-columns: repeat(1, 14.32vw);
    grid-template-rows: repeat(2, 4.3vw);
    row-gap: 5px;
  }
  .download {
    font-size: 2.34vw;
    width: 23.18vw;
    place-self: center;
  }
  .gamelist {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(3, 22.92vw);
    justify-content: center;
    margin-top: 8.2vw;
    column-gap: 2.08vw;
  }

  .apple {
    width: 14.32vw;
    height: 4.3vw;
  }
  .google {
    width: 14.32vw;
    height: 4.3vw;
  }
  .buttonarea {
    padding-top: 11.98vw;
    padding-bottom: 0px;
  }
  .button {
    width: 32.81vw;
    height: 8.72vw;
    font-size: 2.99vw;
    margin-bottom: 11.98vw;
  }
  .button:hover {
    margin-bottom: 11.2vw;
  }
}
/*mobile*/
@media all and (max-width: 480px) {
  .title {
    font-size: 10vw;
  }
  .zigzag {
    top: -4vw;
  }
  .container {
    height: 660.4vw;
    padding-bottom: 26.9vw;
  }
  .title {
    font-size: 7.5vw;
    padding-top: 10.4vw;
  }
  .game {
    padding: 0;
  }
  .gamelist {
    height: 500vw;
    display: grid;
    width: 100%;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(5, 1fr);
    justify-content: center;
    row-gap: 10.4vw;
  }
  .game_icon {
    width: 83.3vw;
    height: 83.3vw;
    border-radius: 7.5vw;
  }
  .gametitle {
    font-size: 5.4vw;
    width: 83.3vw;
    margin: 6.3vw 0 2.1vw 0;
  }
  .download {
    width: 83.3vw;
    font-size: 4.6vw;
    margin: 0 0 4.2vw 0;
  }
  .game_link {
    margin: 0;
    width: 100vw;
  }
  .game_link > a {
    margin: 0;
  }
  .game_link {
    grid-template-columns: repeat(2, 30vw);
    grid-template-rows: repeat(1, 9vw);
    column-gap: 16px;
  }
  .apple {
    width: 30vw;
    height: 9vw;

    margin-bottom: 0;
  }
  .google {
    width: 30vw;
    height: 9vw;
  }

  .button {
    width: 52.5vw;
    height: 14vw;
    font-size: 4.8vw;
    margin-left: 0;
  }
  .buttonarea {
    padding-top: 0;
    padding-bottom: 0px;
    padding-bottom: 22.1vw;
  }
}
