.App {
  text-align: center;
  /* display: grid;
  grid-template-columns: repeat(1, 1fr); */
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
html {
  scroll-behavior: smooth;
}
.slide input {
  display: none;
}

* {
  margin: 0;
  padding: 0;
}
.button {
  position: fixed;
  top: 80%;
  z-index: 1;
  background: white;
  width: 50px;
  height: 50px;
  right: 5%;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  font-weight: bold;
  box-shadow: 0px 10px 15px rgba(26, 26, 26, 0.2);
  color: rgba(219, 49, 49, 1);
}
.button:hover {
  cursor: pointer;
}
/*
scroll-behavior: smooth;
*/
